import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {db, auth_} from './../../../firebase';
import { useEffect, useState } from "react";
import Popup from "../../../components/Popup/Popup";
import {v4 as uuidv4} from 'uuid';


const BookClubSubDiscussionSections = () =>{

    const navigate = useNavigate();
    const location = useLocation();
    const {clubNameId} = useParams();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const firestore = getFirestore();

    const [DiscussionBoardQuestionResponses, showDiscussionBoardSectionResponses] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isPressedCreateNewSection, setNewSectionFromPressed] = useState(false);
    const [discussionSectionName, setDiscussionSectionName] = useState("");

    const [DiscussionSubSections, showDiscussionSubSections] = useState([]);

    const [BookClubReplies, showBookClubReplies] = useState([]);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");


    const [responder, getResponderInfo] = useState([]);   
    const responseArray = [];
    const responderArray = [];

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
       return `${window.location.origin}/discussionboard/${clubNameId}`;
    }


    // location.state.id
    // const getDiscussionBoardQuestion = async() =>{
    //     const question= await getDoc(doc(firestore, "DiscussionBoards",location.state.club_name, "Questions",location.state.user_question));
    //     showDiscussionBoardQuestion(question.data());
    // }


    const getDiscussionSubSections = async() =>{
        await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections", location.state.section,"SubSection")).then((querySnapshot)=>{
            const discussion_sub_sections = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
            showDiscussionSubSections(discussion_sub_sections);
        });   
    }

    // const getDiscussionBoardSectionResponses = async() =>{
    //    await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections","Responses")).then((querySnapshot)=>{
    //         const responses = querySnapshot.docs.map((doc)=>({
    //             ...doc.data()
    //         }));

    //         showDiscussionBoardSectionResponses(responses);

    //     });  
        
    //    }
   

        

    const onSubmitReply = async (event) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        // console.log(location.state.club_name);
        const firestore = getFirestore();
        const bookClubSubSectionResponesUniqueID = uuidv4();



        try{

            if(event?.target?.db_reply.value===null|| event?.target?.db_reply?.value.trim()===""){
                emptyNotification();
            }else{

            setDoc(doc(firestore,"BookClubs",location?.state?.club_name, "BookClubDiscussionSections", 
            location.state.section, "SubSections", location?.state?.sub_section, "Discussions", date),{
                dateReplied: date,
                replier: user?.displayName,
                replier_uid: user?.uid,
                reply: event?.target?.db_reply?.value,
            });
            
               setDoc(doc(firestore,"Users",location?.state?.club_name_creator_uid,"Notifications", location?.state?.club_name_creator_uid+ bookClubSubSectionResponesUniqueID+user.uid),{
                DateandTime: date,
                bookclubname: location?.state?.club_name,
                bookclubsubsectionname: location?.state?.sub_section,
                responderDisplayName: user?.displayName,
                responderBookClubID: bookClubSubSectionResponesUniqueID,
                responderUID: user?.uid,
                type: "bookclub_subsection_reply",
                isRead: false
            });

            setDoc(doc(firestore,"mail", location?.state?.club_name_creator_uid+ bookClubSubSectionResponesUniqueID+user.uid),{
                to: location?.state?.club_name_creator_email,
                message: {
                    subject: "You have a new message!",
                    html:  user?.displayName + " added a new comment in your book club "+ location?.state?.club_name + " in sub section " + location?.state?.sub_section +" <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            successNotification();
        }
      

        }catch(event){
            console.log("Error Adding Response: ",event );
            warningNotification();

        }


    }

    const getBookClubResponses = async() =>{

        const bookClubResponses = await getDocs(collection(db, "BookClubs",location?.state?.club_name, "BookClubDiscussionSections", 
        location.state.section, "SubSections", location?.state?.sub_section,"Discussions" ));
        const responses = bookClubResponses.docs;

        responses.forEach( async(response) => {
            const responseAuthor = await getDoc(doc(db, "Users", response.data().replier_uid));

            responseArray.push(response.data());
            showBookClubReplies(responseArray);

            responderArray.push(responseAuthor.data());
            getResponderInfo(responderArray);
          });
         
        } 

        const onViewReaderProfileButton  = (reader) =>{
     
            navigate('/profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
            state: {
                id: reader.uid,
                username: reader.displayName,
                email: reader.email
            }
        });
        }

    useEffect(()=>{
        getBookClubResponses();
        getDiscussionSubSections();
        // getDiscussionBoardSectionResponses();
    },[])    

    return (

        <div>
            <h1>{location.state.sub_section} discussions</h1>

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your response has been submitted!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue submitting your response!"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter a response!"
            />} 


 


                    <div className="horizontal-line"></div>

                    <h2>General Discussion</h2>

                    <div >
                        <form className="response-format" onSubmit={onSubmitReply}>
                        <textarea className="textarea-format" name="db_reply" placeholder="Enter response..."/>
                        <button type="submit">Submit Reply</button>
                        </form>
                    </div>

                    <div>
                           {
                        BookClubReplies?.map((responses, idx)=>(
                            <div className="conversations-card">
                            <div className="user-info-on-conversation-card" onClick={()=>onViewReaderProfileButton(responder[idx])}>
                                <img src={responder[idx].photoURL? responder[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>   
                                <h2 >by {responder[idx].displayName}</h2>
                                <h2 key={idx}>Replied on {responses?.dateReplied}</h2>
                            
                            </div>
                            <div className="info-on-conversation-card">
                                <h2 key={idx}>{responses?.reply}</h2>
                            </div>
                            {/* <img src={photoURL} className="profilePic"/>
                            <h2 >by {responses?.replier}</h2> */}
                            </div>
                        ))
                    }

                    </div>                    

        </div>
    );

}

export default BookClubSubDiscussionSections;