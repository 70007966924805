import React, {useEffect, useState} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    query,
    collection, 
    orderBy,
    onSnapshot,
    limit,
    QuerySnapshot,
    Timestamp,
    runTransaction,
    serverTimestamp,
    doc,
    getFirestore,
    getDoc,
    setDoc,
    updateDoc

} from 'firebase/firestore';

import {db, auth_} from '../../firebase';

const AcceptInvite = () =>{
    const {clubNameId, inviteId} = useParams();
    const history = useNavigate();
    const user =auth_.currentUser;
    const [message, setMessage] = useState("Processing Invite..");


    useEffect(()=>{
        const verifyAndAcceptInvite = async()=>{
            const firestore = getFirestore();

            try{
            const invite = await getDoc(doc(firestore,"BookClubs",clubNameId,"Invites",inviteId));
            // const invite = await inviteRef.get();

            if(!invite.exists){
                setMessage("Invalid invite");
                return;
            }

            const inviteData = invite.data();
            if(inviteData.expiresAt.toDate()< new Date()){
                setMessage("This invite has expired!");
                return;
            }

            if(inviteData.usedBy){
                setMessage("This invite has been already used!");
                return;
            }


       
                setDoc(doc(firestore, "BookClubs",clubNameId,"Members", user.uid),{
                    DateandTime: serverTimestamp(),
                    followerDisplayName: user.displayName,
                    followerUID: user.uid

                });

                updateDoc(doc(firestore,"BookClubs",clubNameId,"Invites",inviteId),{
                    usedBy: user.uid
                });

            setMessage("Sucessfully joined the group!");
            setTimeout(()=>history.push(`/bookclub/${clubNameId}`, 2000));
            }catch(error){
                console.log("Error processing invite", error)
                setMessage("An error occured while processing your invite!");

            }
            // history.push(``)

        }
        if(user){
            verifyAndAcceptInvite();
        }else{
            setMessage("Please log in to accept the invite.")
        }
        
    },[inviteId, user, history]);

    return(
        <div>{message}</div>
    );




}

export default AcceptInvite;
