import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate} from 'react-router-dom';

import Papa from "papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMagnifyingGlass, faPencil, faPeopleGroup, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const BookRecommendations = () =>{

    const [csvData, parsedCsvData] = useState([]);
    const navigate = useNavigate();

    const onWRateNowButton  = (book_title) =>{
 
        navigate('/rate_book/'+book_title);

    }

    var file = require("./../books/BookRecommendationList.csv")
    const parseFile = () =>{
        Papa.parse(file, {
            download: true,
            header: false,
            complete: results =>{
                // console.log(results.data)
                parsedCsvData(results.data)
            },
        });

    }

    useEffect(()=>{
        parseFile();
    },[])

    return (
        <section className="bg-gray-900">
            <div className="">

            <div class="border-b border-gray-300 flex flex-col  items-center px-6 py-8 mx-auto lg:py-0">
                    <h1 class="mb-6 text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Book Recommendations</h1>
                    <p>Rate more books you have read to get better personalized recommendations</p>
                    <button
                className="mt-4 mb-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
                onClick={()=>navigate('/finishedreading')}>Rate Books You Have Read Now!</button>
                </div>

                

                
                {/* <div onClick ={onWRateNowButton} className="card-container"> */}
                        {/* {
                            csvData?.slice(0,6).map((data,idx)=>(
                                <div className="card">
                                <br/>
                                {data[2]? <img src={data[2]} height={100} width = {50}/> :
                                <p><FontAwesomeIcon icon={faBook} size ="2xl"/></p>}
                                <p>{data[0]}</p>
                                {data[1]? "by " + data[1] : ""}
                                {/* <button>View</button> */}
                                {/* </div> */}
    
                            {/* )) */}
                            
                        {/* }  */}
                    {/* </div> */}


                    
                    <ul class="overflow-auto-y w-full divide-y divide-white dark:divide-white">

                        {
                            csvData?.slice(0,6).map((data,idx)=>(
                                <div 
                                    onClick ={()=>onWRateNowButton(data[0])} 
                                    class="hover:bg-gray-800">
                                 {/* <img src={data[2]? data[2]:""} className="img-book"/> */}


                                 <li class="pb-3 sm:pb-4">
                                        <div class="flex items-center space-x-4 p-4 rtl:space-x-reverse">
                                            <div class="flex-shrink-0">
                                                <img class="shadow shadow-white h-46 w-24" src={data[2]? data[2]:""}/>
                                            </div>
                                            <div class="flex-1 min-w-0">
                                                <p class="text-sm font-bold text-gray-900 dark:text-white">
                                                    {data[0]} 
                                                </p>
                                                <p class="text-sm text-gray-500  dark:text-gray-400">
                                                    By {data[1]} 
                                                </p>
                                            </div>
                                            <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                <span>Rate Book </span>
                                                <FontAwesomeIcon icon={faArrowRight}/>
                                                
                                            </div>
                            
                                        </div>
                                    </li>
                                 </div>


    
                            ))
                            
                        }
                    </ul>
                    <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 items-end">Refresh</button>

            </div>
        </section>
    );
};

export default BookRecommendations;