import React, { useRef, useState, useEffect} from 'react'
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBookBookmark, faMagnifyingGlass, faPencil, faPeopleGroup, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import {doc, collection, DocumentSnapshot, getDoc, getDocs, QuerySnapshot, getFirestore, setDoc} from 'firebase/firestore';
import {v4 as uuidv4} from 'uuid';
import {db, auth_} from './../../firebase';
import {Helmet} from "react-helmet";
import {EmailShareButton, EmailIcon, FacebookShareButton, FacebookIcon,  TwitterShareButton,TwitterIcon,  WhatsappShareButton, WhatsappIcon} from "react-share";
import AdsComponent from './../../components/AdComponents';

// import {
//   EpubViewer,
//   ReactEpubViewer
// } from 'react-epub-viewer'

import {ReactReader} from 'react-reader';
import {Contents, Rendition} from 'epubjs';

const ReadTool = () => {
  // const viewerRef = useRef(null);
  const {title} = useParams();

  

    const analytics = getAnalytics();
    const firestore = getFirestore();
    const navigate = useNavigate();

    const [largeText, setLargeText] =  useState(false);
    // const rendition = useRef(Rendition);


    logEvent(analytics, "Ebook Reader Page");

    // const {title} = useParams();

    // var blogID = title.substring(title.lastIndexOf("-")+1);

    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;

    const [BlogInfo, showBlogInfo] = useState([]);
    // const [location, setLocation]  = useState<string | number >(0)

    const [location, setLocation] = useState( String | Number==0)
    // const [rendition, setRendition] = useState<Rendition | undefined>(undefined);

    const getBlogInfo= async() =>{

        // console.log(blogID);
        // console.log(await (await getDoc(doc(db, "Blogs",blogID.trim()))).get());

        showBlogInfo( (await getDoc(doc(db, "Books",title))).data());

    //    const author = (await getDoc(doc(db, "Blogs",blogID))).data()["creator_uid"];

    //     getAuthorInfo((await getDoc(doc(db, "Users",author))).data())

    }

    useEffect(()=>{
        getBlogInfo();
        // rendition.current?.themes.fontSize(largeText?'140%':'100%')
        // logEvent(analytics, "Blog Post: "+ BlogInfo.title);

    },[])

 
  return (
    <div >
      {/* <button onClick={()=>setLargeText(!largeText)}>Toggle Font Size</button> */}
    <div   style={{ height: '100vh' }}>
    {/* <div style={{ position: "absolute", height: "100%" }}> */}
      <ReactReader 
      title={BlogInfo.title}
        url={BlogInfo.book}
        // url = "https://react-reader.metabits.no/files/alice.epub"
        // ref={viewerRef}
        location={location}
        locationChanged={(epubcfi= String) => setLocation(epubcfi)}
        // getRendition={(_rendition = Rendition)=>{
        //   rendition.current = _rendition
        //   rendition.current.themes.fontSize(largeText?'140%':'100%')



        // }}
      />
    {/* </div> */}
    </div>
    </div>
  );
}

export default ReadTool;