import React, { useState,useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { auth_, db } from "../firebase";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot, updateDoc,} from 'firebase/firestore';
import { Bell, Book, MessageCircle, User2, Users, X } from "lucide-react";



const Notifications = () =>{

    let location = useLocation();
    let [notifications, setNotifications] = useState([]);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [userInform, getUserInfo] = useState([]);

    const user = auth_.currentUser;

    const notificationArray = [];
    const userArray = [];


    const fetchNotifications = async () =>{
        const getNotifications = await getDocs(collection(db, "Users", user?.uid, "Notifications"));
        const notifications = getNotifications?.docs;


        notifications?.forEach( async(notification) => {
            // console.log("notifications");
            // console.log(notification.data());
            // console.log(notification.data().commentCreatorUID ||
            // notification?.data()?.sectionCreatorUID||
            // notification?.data()?.responderUID||
            // notification?.data()?.followerUID);
            // const userInfo = null;
            // const userInfo = ([]);
            const userInfo = await getDoc(doc(db, "Users", notification?.data()?.commentCreatorUID ||
            notification?.data()?.sectionCreatorUID||
            notification?.data()?.responderUID||
            notification?.data()?.followerUID  ||
            notification?.data()?.newMemberrUID));


            // if(notification.data().commentCreatorUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().commentCreatorUID));
            
            // }else if(notification.data().responderBookClubID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().responderBookClubID));
            // }else if(notification.data().sectionCreatorUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().sectionCreatorUID));
            // }else if(notification.data().responderUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().responderUID));
            // }else if(notification.data().followerUID){
            //      userInfo = await getDoc(doc(db, "Users", notification.data().followerUID));
            // }

            // console.log(userInfo.data());
            // console.log("-------------");

            // console.log(notification.data());


            // updateDoc(notification.ref,{
            //     isRead: true
            // });

        
            notificationArray?.push(notification?.data());
            setNotifications(notificationArray);

            userArray?.push(userInfo?.data());
            getUserInfo(userArray);
          });

    //     await getDocs(collection(db, "Users", user.uid, "Notifications")).then((querySnapshot)=>{
    //       let messages = querySnapshot.docs.map((doc)=>({
    //           ...doc.data()
    //       }));
    //         setNotifications(messages); 
    //         console.log(messages);

    // });  
}

const markNotificationRead=async(id)=>{
    // const getNotification = await getDoc(doc(db, "Users", user?.uid, "Notifications",id ));

    // updateDoc(getNotification.ref,{
    //             isRead: true
    //         });

    await updateDoc(doc(db, "Users", user?.uid, "Notifications",id ),{
        isRead: true
    })
}


const markAllNotificationRead=async()=>{
    const getNotifications = await getDocs(collection(db, "Users", user?.uid, "Notifications" ));

    const notifications = getNotifications?.docs;


        notifications?.forEach( async(notification) => {

            updateDoc(notification.ref,{
                isRead: true
            });

        });
}

useEffect(()=>{
    fetchNotifications();

},[user]);

    // if(!location?.state?.notificationsRead){
    //     console.log("hey")
    //     console.log(location?.state?.notificationsRead);
    //     return <h1>No notifications yet..</h1>

    // }else{
        return (
            <div className="min-h-screen bg-gray-900 p-4 md:p-6">
                
                <div className="max-w-3xl mx-auto">

                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                        <div className="flex items-center gap-2">
                            <Bell className="w-6 h-6 text-blue-600"/>
                            <h1 className="text-2xl font-bold text-gray-300">Notifications</h1>
                        </div>
                        <button onClick={()=>markAllNotificationRead()} className="text-sm text-blue-600 hover:text-blue-700">
                            Mark all as read
                        </button>

                    </div>
                     {/* {notifications?.filter(notification => notification?.isRead===false).map( */}
                     <div className="space-y-4">
                     {notifications?.map(
                        (notification, idx) => 
                        (<div
                            key={idx} 
                        className={`bg-white rounded-lg shadow-sm p-4 transition-all ${!notification.isRead?'border-l-4 border-blue-500':''}`}>
                            
                             {notification?.type==="follow"? (
                                <div className="flex items-start gap-4">
                                    <div className="mt-1">
                                        <User2 className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                    </div>
                                    <div className="w-12 h-12 rounded-full"> 
                                        <img
                                        className="w-full h-full object-cover" 
                                        src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                        alt="ProfilePic"/>
                                    </div>
                                    <div className="flex-1">
                                        <div className="flex items-start justify-between gap-4">
                                            
                                             <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                <h2>{userInform[idx]?.displayName} started following you!</h2>
                                            </div>
                                            <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid):
                                                        ()=>markNotificationRead(userInform[idx]?.uid + notification?.followID + user?.uid)}  className="text-gray-400 hover:text-gray-500">
                                                <X  className="w-4 h-4"/>
                                            </button>
                                        </div>
                                    </div>
                                    
                                </div>):
                                ""}
                                
                                 {notification.type==="bookclub_member_added"? (
                                    <div className="flex items-start gap-4">
                                        <div className="mt-1">
                                            <Book className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                        </div>
                                        <div className="w-12 h-12 rounded-full"> 
                                            <img
                                            className="w-full h-full object-cover" 
                                            src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                            alt="ProfilePic"/>
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex items-start justify-between gap-4">
                                                <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                    <h2>{userInform[idx]?.displayName} joined your book club {notification?.bookclubname}</h2>
                                                </div>
                                                <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        ()=>markNotificationRead(userInform[idx]?.uid + notification?.joinedBookClubID + user?.uid)}  className="text-gray-400 hover:text-gray-500">
                                                    <X className="w-4 h-4"/>
                                                </button>
                                            </div>
                                        </div>
                                    {/* <img src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} alt="ProfilePic" className="profilePic"/>         */}
                                    
                                    </div>):""}
                                
                                {notification.type==="bookclub_section_added"? (
                                    <div className="flex items-start gap-4">
                                        <div className="mt-1">
                                            <Users className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                        </div>
                                        <div className="w-12 h-12 rounded-full"> 
                                            <img
                                                className="w-full h-full object-cover" 
                                                src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                alt="ProfilePic"/>
                                        </div>
                                        <div className="flex-1">
                                            <div className="flex items-start justify-between gap-4">
                                                <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                    <h2>{userInform[idx]?.displayName} added a new section called {notification?.sectionName} to your book club {notification?.bookclubname}</h2>
                                                </div>
                                                <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.bookclubSectionCreatorID  + user?.uid))}  className="text-gray-400 hover:text-gray-500">
                                                    <X  className="w-4 h-4"/>
                                                </button>
                                            </div>
                                        </div>
                                    
                                    </div>):""}
                                   {notification.type==="bookclub_section_reply"? (
                                        <div className="flex items-start gap-4">
                                            <div className="mt-1">
                                                <MessageCircle className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                            </div>
                                            <div className="w-12 h-12 rounded-full"> 
                                                <img
                                                    className="w-full h-full object-cover" 
                                                    src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                    alt="ProfilePic"/>
                                            </div>
                                            <div className="flex-1">
                                                <div className="flex items-start justify-between gap-4">
                                                    <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                        <h2>{userInform[idx]?.displayName} added a new comment in your book club {notification?.bookclubname} in {notification?.bookclubsectionname}</h2>
                                                    </div>
                                                    <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.responderBookClubID  + user?.uid))} 
                                                        className="text-gray-400 hover:text-gray-500">
                                                        <X  className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>):""}
                                    {notification.type==="bookclub_comment_added"? (
                                        <div className="flex items-start gap-4">
                                        <div className="mt-1">
                                            <MessageCircle className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                        </div>
                                        <div className="w-12 h-12 rounded-full"> 
                                                <img
                                                    className="w-full h-full object-cover" 
                                                    src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                    alt="ProfilePic"/>
                                            </div>
                                            <div className="flex-1">
                                                <div className="flex items-start justify-between gap-4">
                                                    <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                        <h2>{userInform[idx]?.displayName} added a new comment in your book club {notification?.bookclubname}</h2>
                                                    </div>
                                                    <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.bookclubCommentCreatorID  + user?.uid))}  className="text-gray-400 hover:text-gray-500">
                                                        <X  className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>):""}                                        
                                    {notification.type==="bookclub_subsection_added"? (
                                            <div className="flex items-start gap-4">
                                                <div className="mt-1">
                                                    <Book className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                                </div>
                                                <div className="w-12 h-12 rounded-full"> 
                                                    <img
                                                        className="w-full h-full object-cover" 
                                                        src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                        alt="ProfilePic"/>
                                                </div>
                                                <div className="flex-1">
                                                <div className="flex items-start justify-between gap-4">
                                                    <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                        <h2>{userInform[idx]?.displayName} added a new sub section called {notification?.newSectionCreated} in your book club {notification?.bookclubname}</h2>
                                                    </div>
                                                    <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.newSectionBookClubID  + user?.uid))}  className="text-gray-400 hover:text-gray-500">
                                                        <X  className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            </div>
                                            </div>):""}

                                        {notification?.type==="bookclub_subsection_reply"? (
                                            <div className="flex items-start gap-4">
                                                <div className="mt-1">
                                                    <MessageCircle className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                                </div>
                                                <div className="w-12 h-12 rounded-full"> 
                                                    <img
                                                        className="w-full h-full object-cover" 
                                                        src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                        alt="ProfilePic"/>
                                                </div>
                                                <div className="flex-1">
                                                <div className="flex items-start justify-between gap-4">
                                                    <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                        <h2>{userInform[idx]?.displayName} added a new comment in your book club {notification?.bookclubname} in sub section {notification?.bookclubsubsectionname}</h2>
                                                    </div>
                                                    <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.responderBookClubID  + user?.uid))}  className="text-gray-400 hover:text-gray-500">
                                                        <X  className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            </div>
                                            </div>):""} 


                                       {notification?.type==="discussionboard_reply"? (
                                            <div className="flex items-start gap-4">
                                                <div className="mt-1">
                                                    <MessageCircle className={`w-5 h-5 ${!notification.isRead?'text-blue-500':'text-gray-400'}`}/>
                                                </div>
                                                <div className="w-12 h-12 rounded-full"> 
                                                    <img
                                                        className="w-full h-full object-cover" 
                                                        src={userInform[idx]?.photoURL? userInform[idx].photoURL : photoURL} 
                                                        alt="ProfilePic"/>
                                                </div>
                                                <div className="flex-1">
                                                <div className="flex items-start justify-between gap-4">
                                                    <div className={`text-sm font-semibold ${!notification.isRead?'text-gray-900':'text-gray-600'}`}>   
                                                        <h2>{userInform[idx]?.displayName} responded to your question: {notification?.question} in {notification?.discussionBoardName}</h2>
                                                    </div>
                                                    <button onClick={notification.uid ? 
                                                        ()=>markNotificationRead(notification.uid) :
                                                        (()=>markNotificationRead(userInform[idx]?.uid + notification?.responseID  + user?.uid))}  className="text-gray-400 hover:text-gray-500">
                                                        <X  className="w-4 h-4"/>
                                                    </button>
                                                </div>
                                            </div>
                                            </div>):""}               
                                        </div>)
                                        )}
                                    </div> 
                                </div>
                            </div>
                        );
                    }

export default Notifications;