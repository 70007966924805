import React, {useState} from "react";
import { useNavigate, useLocation, useParams} from 'react-router-dom';
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_, getProfilePic} from './../firebase';
import Popup from "../components/Popup/Popup";
import { ThumbsUp } from "lucide-react";




const BookRatings = () =>{

    
    const {book_title} = useParams();
    const location = useLocation();
    const user = auth_.currentUser;

    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);


    const [bookHelpedBusiness, setBookHelpedBusinessRB] = useState('');
    const [rating, setRating] = useState('');
    const [bookReviewText, savebookReviewText] = useState('');

              {/* Marketing */}
                {/* Accounting */}
                {/* Raising Capital */}
                {/* Franchise */}  
                {/* Product Management */}   
                {/* Sales */} 
                {/* Finance */}  
                {/* Beginners */}
                {/* Business Acquisition */}
    const [marketingChecked, setMarketingChecked] = useState(false);
    const [accountingChecked, setAccountingChecked] = useState(false);
    const [raisingCapitalChecked, setRaisingCapitalChecked] = useState(false);
    const [franchiseChecked, setFranchiseChecked] = useState(false);
    const [manageTeamsChecked, setManageTeamsChecked] = useState(false);
    const [productManagementChecked, setProductManagementChecked] = useState(false);
    const [salesChecked, setSalesChecked] = useState(false);
    const [financeChecked, setFinanceChecked] = useState(false);
    const [beginnersChecked, setBeginnersChecked] = useState(false);
    const [businessAcquisitionChecked, setBusinessAcqusitionChecked] = useState(false);


    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const onSubmitReview =(e)=>{
        e.preventDefault();

        console.log(bookHelpedBusiness);
        console.log(rating);

        console.log(marketingChecked);
        console.log(accountingChecked);
        console.log(raisingCapitalChecked);
        console.log(franchiseChecked);
        console.log(productManagementChecked);
        console.log(salesChecked);
        console.log(financeChecked);
        console.log(beginnersChecked);
        console.log(businessAcquisitionChecked);

        console.log(bookReviewText);

        const date = new Date;

        setDoc(doc(db,"BookRatings",book_title,user.uid),{
            bookGoodFor: "Marketing="+marketingChecked+"; Sales="+salesChecked+"; Accounting="+accountingChecked+"; Finance="+financeChecked+"; RaisingCapital="+raisingCapitalChecked+"; Beginners="+beginnersChecked+"; Franchise="+franchiseChecked+"; ManagingTeams="+manageTeamsChecked+"; ProductManagement="+productManagementChecked+"; BusinessAcquisitions="+businessAcquisitionChecked,
            bookHelpedBusiness: bookHelpedBusiness,
            rating: rating,
            review: bookReviewText,
            reviewCreated: date,
            uid: user.uid,
        });
    } 
    
    const handleRBChange = (value) =>{
        setBookHelpedBusinessRB(value)
    }
    const handleRatingChange = (event) =>{
        setRating(event.target.value);
    }
    
    // checkboxes
    const handleMarketingCheckboxChange = () =>{
        setMarketingChecked(!marketingChecked);
    }
    const handleAccountingCheckboxChange = () =>{
        setAccountingChecked(!accountingChecked);
    } 
    const handleRaisingCapitalCheckboxChange = () =>{
        setRaisingCapitalChecked(!raisingCapitalChecked);
    } 
    const handleFranchiseCheckboxChange = () =>{
        setFranchiseChecked(!franchiseChecked);
    }
    const handleManagingTeamsCheckboxChange = () =>{
        setManageTeamsChecked(!manageTeamsChecked);
    }      
    const handleProductManagementCheckboxChange = () =>{
        setProductManagementChecked(!productManagementChecked);
    } 
    const handleSalesCheckboxChange = () =>{
        setSalesChecked(!salesChecked);
    } 
    const handleFinanceCheckboxChange = () =>{
        setFinanceChecked(!financeChecked);
    } 
    const handleBeginnerCheckboxChange = () =>{
        setBeginnersChecked(!beginnersChecked);
    } 
    const handleBusinessAcquisitionCheckboxChange = () =>{
        setBusinessAcqusitionChecked(!businessAcquisitionChecked);
    } 
    
    
    const checkboxItems = [
        {name: "Marketing", 
            val: productManagementChecked, 
            ochange:  handleMarketingCheckboxChange
        },
        {name: "Accounting", 
            val: accountingChecked, 
            ochange:  handleAccountingCheckboxChange
        },
        {name: "Raising Capital", 
            val: raisingCapitalChecked, 
            ochange:  handleRaisingCapitalCheckboxChange
        },
        {name: "Franchise", 
            val: franchiseChecked, 
            ochange:  handleFranchiseCheckboxChange
        },
        {name: "Managing Teams", 
            val: manageTeamsChecked, 
            ochange:  handleManagingTeamsCheckboxChange
        },
        {name: "Product Management", 
            val: productManagementChecked, 
            ochange:  handleProductManagementCheckboxChange
        },
        {name: "Sales", 
            val: salesChecked, 
            ochange:  handleSalesCheckboxChange
        },
        {name: "Finance", 
            val: financeChecked, 
            ochange:  handleFinanceCheckboxChange
        },
        {name: "Beginners", 
            val: beginnersChecked, 
            ochange:  handleBeginnerCheckboxChange
        },
        {name: "Business Acquisition", 
            val: businessAcquisitionChecked, 
            ochange:  handleBusinessAcquisitionCheckboxChange
        }
    ]


    return(
        <section className="bg-gray-900">

        <div className="max-w-md mx-auto space-y-6 bg-gray-900 p-6">

        {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                content = {
                <div>
                    <h2 className="error-popup">Error</h2>
                    <p>Rating can not be blank!</p>
                </div>
                }
            />}

            <div className="flex gap-2 mb-2">
                <ThumbsUp className="w-24 h-24 text-blue-600"/>
                <h1 className="text-3xl font-bold text-gray-300">Rate & Review: {book_title}</h1>
            </div>

            
            <form onSubmit={onSubmitReview}>
                <div className="space-y-3">
                    <h3 className="text-sm font-medium text-gray-300 mb-2">Did this book help you with your business?</h3>
                    {['yes','no'].map((option)=>(
                        // <div className="flex items-center space-x-3 group">
                        //     <div className={`w-5 h-5 rounded-full border-2 flex items-center justify-center cursor-pointer transition-colors duration-200
                        //         ${bookHelpedBusiness === option ? 'border-blue-500':
                        //             'border-gray-300 group-hover:border-blue-400'
                        //         }`}>
                        //         <div className={`w-3  h-3 rounded-full transition-colors duration-200 ${
                        //             bookHelpedBusiness === option? 'bg-blue-600':''
                        //         }`}></div>
                                
                                <label
                                key={option} 
                                className="flex items-center space-x-3 cursor-pointer">
                                    <input
                                    id ={option}
                                    type ="radio" 
                                    value = {option} 
                                    checked={bookHelpedBusiness === option} 
                                    onChange={()=>handleRBChange(option)}
                                    className="w-5 h-5 text-blue-500 border-gray-300 focus:ring-blue-500 cursor-pointer"/>
                                    <span className="text-gray-300 select-none capitalize">{option}</span>
                                </label>
                        //     </div>
                        // </div>
                    ))}

                </div>


                {/* <div className="book-rating-div"> */}
                {/* <label>Did this book help you with your business</label>
                <div>
                <label>
                    <input type ="radio" value = "yes" checked={bookHelpedBusiness === "yes"} onChange={()=>handleRBChange("yes")}/>
                    Yes
                </label>
                </div> */}
                {/* <div>
                <label>
                    <input type ="radio" value = "no"  checked={bookHelpedBusiness === "no"} onChange={()=>handleRBChange("no")}/>
                    No
                </label>
                </div> */}
                {/* </div> */}

                <div className="space-y-3">
                <h3 className="text-sm font-medium text-gray-300 mt-2 mb-2">This book is good for?</h3>
                    {checkboxItems.map((cb)=>(
                     
                        <label
                        // key={option} 
                        className="flex items-center space-x-3 cursor-pointer">
                            <input
                            // id ={option}
                            type ="checkbox" 
                            value = {cb.val} 
                            // checked={bookHelpedBusiness === option} 
                            onChange={cb.ochange}
                            className="w-5 h-5 text-blue-500 border-gray-300 focus:ring-blue-500 cursor-pointer"/>
                            <span className="text-gray-300 select-none capitalize">{cb.name}</span>
                        </label>
                        ))}

                </div>
        
                {/* <div className="book-rating-div">
                <label>This book is good for?
                <div>
                    <input type ="checkbox" value ={marketingChecked} onChange={handleMarketingCheckboxChange}/>
                    <span>Marketing</span>
                </div>
                <div>
                    <input type ="checkbox" value ={accountingChecked} onChange={handleAccountingCheckboxChange}/>
                    <span>Accounting</span>
                </div>
                <div>
                    <input type ="checkbox" value ={raisingCapitalChecked} onChange={handleRaisingCapitalCheckboxChange}/>
                    <span>Raising Capital</span>
                </div>
                <div>
                    <input type ="checkbox" value ={franchiseChecked} onChange={handleFranchiseCheckboxChange}/>
                    <span>Franchise</span>
                </div>
                <div>
                    <input type ="checkbox" value ={manageTeamsChecked} onChange={handleManagingTeamsCheckboxChange}/>
                    <span>Managing Teams</span>
                </div>                
                <div>
                    <input type ="checkbox" value ={productManagementChecked} onChange={handleProductManagementCheckboxChange}/>
                    <span>Product Management</span>
                </div>
                <div>
                    <input type ="checkbox" value ={salesChecked} onChange={handleSalesCheckboxChange}/>
                    <span>Sales</span>
                </div>
                <div>
                    <input type ="checkbox" value ={financeChecked} onChange={handleFinanceCheckboxChange}/>
                    <span>Finance</span>
                </div>
                <div>
                    <input type ="checkbox" value ={beginnersChecked} onChange={handleBeginnerCheckboxChange}/>
                    <span>Beginners</span>
                </div>
                <div>
                    <input type ="checkbox" value ={businessAcquisitionChecked} onChange={handleBusinessAcquisitionCheckboxChange}/>
                    <span>Business Acquisition</span>
                </div>
                </label>
                </div> */}


                {/* check boxes: */}
                {/* Marketing */}
                {/* Accounting */}
                {/* Raising Capital */}
                {/* Franchise */}  
                {/* Product Management */}   
                {/* Sales */} 
                {/* Finance */}  
                {/* Beginners */}
                {/* Business Acquisition */} 

                <div className="mt-2 space-y-2">
                    <h3 className="text-sm font-medium text-gray-300">Rate Book?</h3>
                    <select 
                    onChange={handleRatingChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-gray-300 text-gray-900 ">
                        {[1, 1.25, 1.5, 1.75, 
                        2, 2.25, 2.5, 2.75, 
                        3, 3.25, 3.5, 3.75, 
                        4, 4.25, 4.5, 4.75, 5].map((rating)=>(
                            <option key={rating} value={rating}>
                                {rating}

                            </option>
                        ))}
                        
                    </select>

                </div>

                {/* <div className="book-rating-div">
                <label>Rate Book? */}
                {/* 1 - 5 (1/4) */}
                {/*<select  onChange={handleRatingChange}>
                    <option value="1">1</option>
                    <option value="1.25">1.25</option>
                    <option value="1.5">1.5</option>
                    <option value="1.75">1.75</option>
                    <option value="2">2</option>
                    <option value="2.25">2.25</option>
                    <option value="2.5">2.5</option>
                    <option value="2.75">2.75</option>
                    <option value="3">3</option>
                    <option value="3.25">3.25</option>
                    <option value="3.5">3.5</option>
                    <option value="3.75">3.75</option>
                    <option value="4">4</option> 
                    <option value="4.25">4.25</option>
                    <option value="4.5">4.5</option>
                    <option value="4.75">4.75</option>
                    <option value="5">5</option>                                                                              
                </select>
                </label>
                </div> */}

                <div className="mt-2">
                <label>Write a Review?

                <textarea
                 rows={5} 
                 cols={35}
                 type="text"
                 value={bookReviewText}
                 onChange = {e => savebookReviewText(e.target.value)}
                 className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
                </label>
                </div>

                <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
                type="submit"> Submit Review</button>
            </form>
      
        </div>
        </section>
    );
}

export default BookRatings;