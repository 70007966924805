// import 'bootstrap/dist/css/bootstrap.min.css';

import logo from './logo.svg';
import { auth, googleProvider } from './firebase';
// import './App.css';
import './index.css';
import Navbar from './components/Navbar/index';
import LoggedInNavbar from  './components/Navbar/loggedin';

import Footer from './components/FooterBar';
import AcceptClubInvite from './components/AcceptClubInvite';
import AdminDashboard from './components/ClubAdminDashboard';

import React, {useState,useEffect} from "react";

import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';

import Notifications from './pages/notifications'
import Home from  './pages';
import Login from  './pages/login';
import Register from  './pages/register';
import ContactUs from './pages/contactus';
import GetPremium from './pages/subscriptions/frontend/getPremium';
import TermsOfUse from './pages/legal/terms-of-use';
import PrivacyPolicy from './pages/legal/privacy-policy';
import BookRatings from './pages/bookRatings';
import NewsAgg from './pages/news_agg';

import EbooksUnleashed from './pages/bookReadingTool/ebooks_unleashed';
import ReadTool from './pages/bookReadingTool/readTool'

import Discover from  './pages/discover';
import BookRecommendations from  './pages/bookrecs';
import ReadingChallenge from './pages/readingChallenge';
import BookDetails from './pages/bookDetails';
import SearchBooksScreen from './pages/searchBooks';
import InboxMessages from './InboxMessages';
import InboxHomeScreen from './pages/inboxHomeScreen';


import GettingStartedBasicInfo from './pages/onboardingScreens/basicInfo';
import GettingStartedEnterExperienceScreen from './pages/onboardingScreens/experienceScreen';
import GettingStartedUploadPhotoScreen from './pages/onboardingScreens/uploadPhoto';




// Book Categories
import BookCategories from  './pages/categories';
import BeginnerEntrepreneurBookList from  './pages/categoryLists/beginnerEntrepreneur';
import BusinessStrategyCategoryScreen from './pages/categoryLists/businessStrategy';
import FinanceCategoryScreen from './pages/categoryLists/finance';
import FranchiseCategoryScreen from './pages/categoryLists/franchise';
import NonprofitCategoryScreen from './pages/categoryLists/nonprofit';
import RaisingMoneyCategoryScreen from './pages/categoryLists/raisingMoney';
import PopularBooksCategoryScreen from './pages/categoryLists/popularBooks';
import FinancialFreedomCategoryScreen from './pages/categoryLists/financialFreedom';

// Readers
import Readers from  './pages/readers';
import Projects from  './pages/postProjects';

// Shelves
import FinishedReadingShelf from  './pages/bookShelves/FinishedReading';
import CurrentlyReadingShelf from  './pages/bookShelves/CurrentlyReading';
import WantToReadShelf from "./pages/bookShelves/WantToRead"
import WishlistShelf from "./pages/bookShelves/Wishlist"
import FavoritesShelf from "./pages/bookShelves/Favorites"
import MyBooksScreen from "./pages/bookShelves/MyBooksScreen"

// Groups
import GroupHomeScreen from  './pages/groups/home';
import ViewGroup from  './pages/groups/view';
import GroupMemberList from './pages/groups/memberListScreen';


// Book Clubs
import BookClubHomeScreen from  './pages/bookClubs/home';
import ViewBookClub from  './pages/bookClubs/view';
import CreateBookClub from './pages/bookClubs/createNewBookClub';
import BookClubBookDetails from './pages/bookClubs/bookClub_bookDetailsScreen';
import BookClubLibraryList from './pages/bookClubs/bookClubLibraryList';
import BookClubCurrentlyReadingShelf from './pages/bookClubs/currentClubRead';
import BookClubFinishedReadingShelf from './pages/bookClubs/pastClubReads';
import BookClubMemberList from './pages/bookClubs/memberListScreen';

import BookClubDiscussionSections from './pages/bookClubs/bookClubDiscussions/bookClubDiscussionSections';
import BookClubSubDiscussionSections from './pages/bookClubs/bookClubSubDiscussions/bookClubSubDiscussionSections';


// Discussion Boards
import DiscussionBoardsHomeScreen from  './pages/discussionBoards/home';
import DiscussionBoardQuestionsScreen from "./pages/discussionBoards/discussionBoardQuestionsView";
import ViewSpecificDiscussionBoardQuestion from "./pages/discussionBoards/viewSpecificQuestion";
import CreateDiscussionBoardQuestion from "./pages/discussionBoards/createQuestion";
import CreateDiscussionBoard from "./pages/discussionBoards/createNewDashboard";

// User profile page
import AccountSettings from "./pages/settings";
import ReaderProfile from "./pages/readerProfile";

import Followers from "./pages/followers";
import Following from "./pages/following";


import Success from "./pages/subscriptions/frontend/Success";
import Cancel from "./pages/subscriptions/frontend/Cancel";


import {getAuth, onAuthStateChanged} from "firebase/auth";
import Popup from './components/Popup/Popup';

import {Crisp} from 'crisp-sdk-web';
import Posts from './components/Blogs/post';
import Story1 from './components/Blogs/story1';
import CookieConsent from 'react-cookie-consent';
// import { AlertBanner } from '@thumbtack/thumbprint-react';
import {Link } from "react-router-dom";
import EbookUnleashedBookDetails from './pages/bookReadingTool/ebook_unleashed_detail';
// import {Banner} from "flowbite-react";

function App() {

  const crisp_token = "68b0efb4-7a20-496f-b436-a1e42178403e";

  Crisp.configure(crisp_token);

  const [user, setUser] = useState(null);

  const getUsers = async() =>{
    const auth = getAuth();
    onAuthStateChanged(auth, (user)=>{
      try{
        if(user){
            const userInfo = auth.currentUser;
        
            setUser(userInfo)
            // console.log(userInfo);

        }
      }catch(e){
        // console.log("console:");
        console.log(e);
      }
    
    });

}
useEffect(()=>{
  getUsers();
},[])




  return (
    

    <Router>
  
      {/* <AlertBanner theme="info"> Finish setting up your profile.{' '}<Link to="/settings">Settings</Link></AlertBanner> */}
      {user? <LoggedInNavbar/>:<Navbar/>}
      {/* <Navbar/> */}
      {/* <Crisp crispWebsiteId = {crisp_token}/> */}
      <Routes>
          {user? <Route exact path ="/"  element={ <Discover />}/>:<Route exact path ="/"  element={ <Home />}/>}
          <Route exact path ="/login"  element={ <Login />}/>
          <Route path ="/register"  element={<Register />} />
          <Route path ="/contactus"  element={<ContactUs />} />
          <Route path ="/getpremium"  element={<GetPremium />} />
          <Route path ="/termsofuse"  element={<TermsOfUse />} />
          <Route path ="/privacypolicy"  element={<PrivacyPolicy />} />
          <Route path ="/blogs"  element={<Posts />} />
          <Route path ="/blogs/:title"  element={<Story1 />} />
          <Route path ="/news"  element={<NewsAgg />} />
          <Route path ="/read/:title"  element={<ReadTool />} />
          <Route path ="/ebooks_unleashed" element={user?<EbooksUnleashed />:<Home/>} />
          <Route path ="/ebook_unleashed_detail/:title" element={user?<EbookUnleashedBookDetails />:<Home/>} />

          <Route path ="/projects" element={user?<Projects />:<Home/>} />


          

          
          


  
          <Route path ="/success"  element={user?<Success />:<Navigate to="/"/>} />
          <Route path ="/cancel"  element={user?<Cancel />:<Navigate to="/"/>} />

          
          {/* <Route path ="/discover"  element={user?<Discover />:<Navigate to="/"/>} /> */}
          <Route path ="/getstarted_basic_info"  element={user?<GettingStartedBasicInfo />:<Home/>} />
          <Route path ="/getstarted_experience"  element={user?<GettingStartedEnterExperienceScreen />:<Home/>} />
          <Route path ="/getstarted_profilepic"  element={user?<GettingStartedUploadPhotoScreen />:<Home/>} />


          <Route path ="/discover"  element={user?<Discover />:<Home/>} />
          <Route path ="/categories"  element={user?<BookCategories/>:<Home/>}/>
          <Route path ="/bookrecs"  element={user?<BookRecommendations />:<Home/>} />
          <Route path ="/reading_challenge"  element={user?<ReadingChallenge />:<Home/>} />
          <Route path ="/book_details/:book_name"  element={user?<BookDetails />:<Home/>} />
          <Route path ="/search_books"  element={user?<SearchBooksScreen />:<Home/>} />
          <Route path ="/book_club_books_details/:book_name"  element={user?<BookClubBookDetails />:<Home/>} />

          <Route path ="/notifications" element={user?<Notifications />:<Home/>} />
          <Route path ="/inbox/:name" element={user?<InboxMessages />:<Home/>} />
          <Route path ="/inbox" element={user?<InboxHomeScreen />:<Home/>} />


          


          
          

          <Route path ="/beginner_entrepreneurship_books"  element={user?<BeginnerEntrepreneurBookList />:<Home/>} />
          {/* <Route path ="/business_strategy"  element={user?< BusinessStrategyCategoryScreen/>:<Navigate to="/"/>} /> */}
          <Route path ="/business_strategy"  element={user?< BusinessStrategyCategoryScreen/>:<Home/>} />
          <Route path ="/finance"  element={user?< FinanceCategoryScreen/>:<Home/>} />
          <Route path ="/franchise"  element={user?< FranchiseCategoryScreen/>:<Home/>} />
          <Route path ="/nonprofit"  element={user?< NonprofitCategoryScreen/>:<Home/>} />
          <Route path ="/raising_money"  element={user?< RaisingMoneyCategoryScreen/>:<Home/>} />
          <Route path ="/popular_books"  element={user?< PopularBooksCategoryScreen/>:<Home/>} />
          <Route path ="/financial_freedom"  element={user?< FinancialFreedomCategoryScreen/>:<Home/>} />


          <Route path ="/groups"  element={user?<GroupHomeScreen />:<Home/>} />
          <Route path ="/group/:groupName"  element={user?<ViewGroup />:<Home/>} />
          <Route path ="/group/:groupName/members"  element={user?<GroupMemberList />:<Home/>} />



          <Route path ="/readers"  element={user?<Readers />:<Home/>} />
          <Route path ="/bookclubs"  element={user?<BookClubHomeScreen />:<Home/>} />
          <Route path ="/bookclub/:clubNameId"  element={user?<ViewBookClub />:<Home/>} />
          <Route path ="/invite/:clubNameId/:inviteId" element={<AcceptClubInvite />} />
          <Route path ="/admin/:clubNameId" element={user?<AdminDashboard />:<Home/>} />




          <Route path ="/discussionboards"  element={user?<DiscussionBoardsHomeScreen />:<Home/>} />
          <Route path ="/discussionboard/:id"  element={user?< DiscussionBoardQuestionsScreen/>:<Home/>} />
          <Route path ="/discussionboardquestion/:club/:id"  element={user?< ViewSpecificDiscussionBoardQuestion/>:<Home/>} />
          <Route path ="/creatediscussionboardquestion"  element={user?< CreateDiscussionBoardQuestion/>:<Home/>} />
          <Route path ="/creatediscussionboard"  element={user?< CreateDiscussionBoard/>:<Home/>} />




          <Route path ="/finishedreading"  element={user?<FinishedReadingShelf />:<Home/>} />
          <Route path ="/currentlyreading"  element={user?<CurrentlyReadingShelf />:<Home/>} />
          <Route path ="/wantotread"  element={user?<WantToReadShelf />:<Home/>} />
          <Route path ="/wishlist"  element={user?<WishlistShelf />:<Home/>} />
          <Route path ="/favorites"  element={user?<FavoritesShelf />:<Home/>} />

          <Route path ="/viewbookclub"  element={user?< ViewSpecificDiscussionBoardQuestion/>:<Home/>} />
          <Route path ="/createdbookclub"  element={user?< CreateBookClub/>:<Home/>} />
          <Route path ="/bookclublibrary"  element={user?< BookClubLibraryList/>:<Home/>} />
          <Route path =":clubNameId/currentreadshelf"  element={user?< BookClubLibraryList/>:<Home/>} />
          <Route path =":clubNameId/pastreadshelf"  element={user?< BookClubLibraryList/>:<Home/>} />
          <Route path =":clubNameId/:section_name"  element={user?< BookClubDiscussionSections/>:<Home/>} />
          <Route path =":clubNameId/:section_name/:sub_section_name"  element={user?< BookClubSubDiscussionSections/>:<Home/>} />
          <Route path =":clubNameId/members"  element={user?< BookClubMemberList/>:<Home/>} />




          <Route path ="/settings"  element={user?< AccountSettings/>:<Home/>} />
          <Route path ="/profile/:id"  element={user?< ReaderProfile/>:<Home/>} />

          <Route path =":display_name/followers"  element={user?< Followers/>:<Home/>} />
          <Route path =":display_name/following"  element={user?< Following/>:<Home/>} />

          

          <Route path ="/rate_book/:book_title"  element={user?< BookRatings/>:<Home/>} />
          <Route path ="/mybooks"  element={user?< MyBooksScreen/>:<Home/>} />
   
          
          
          
      </Routes>
      <CookieConsent
      location='bottom'
      buttonText="I agree"
      declineButtonText="Decline"
      style={{backgroundColor:"#242424", color: "#FFF"}}
      buttonStyle={{color: "#4e503b", fontSize: "14px"}}
      declineButtonStyle={{fontSize:"14px"}}
      expires={150}>This website uses cookies to enhance the website experience.</CookieConsent>
      <Footer/> 
    </Router> 

    
    // <div className="App">
      
    //   <header className="App-header">
      
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
