import React, {useState,useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc, getCountFromServer} from 'firebase/firestore';
import {auth_, db} from './../firebase';
import {getAuth, onAuthStateChanged} from "firebase/auth";

import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../components/Popup/Popup";
import { Settings } from "lucide-react";

const ReadingChallenge = () =>{
    
    const user = auth_.currentUser;

    const navigate = useNavigate();

    const onFinishedBookButton  = () =>{
 
        navigate('/finishedreading');

    }

    // const [user, setUser] = useState("");
    const [booksReadCount, countFinishedBooks] = useState(0);
    const [bookGoal, showBookGoal] = useState(0);
    const [bookGoalSettingForm, showBookGoalSettingForm] = useState(false);

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [selectedYear, setSelectedYear] = useState(2024);

    const readingChallengeProgress = (booksReadCount/bookGoal)*100;

    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const getFinishedBooksCount= async() =>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users", user.uid, "Finished Book");
        const count = await getCountFromServer(c);
        countFinishedBooks(count.data().count);
        }
    const getFinishedReadingBooks = async() =>{
            await getDocs(collection(db, "Users", user.uid, "Finished Book")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                    // console.log("BOOKS:");
                    // console.log(book);
                    showFinishedReadingBooks(book);       
            });   
        }

        const getBookGoal = async() =>{
            await getDocs(collection(db, "Users", user.uid, "ReadingChallenge")).then((querySnapshot)=>{
                const book = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                    // console.log("BOOKS:");
                    // console.log(book[0].numBooksWantReadGoalAmount);
                    showBookGoal(book[0].numBooksWantReadGoalAmount);       
            });   
        }
    const numBooksToRead = async(event)=>{
        event.preventDefault();

        try{

            const firestore = getFirestore();
            console.log(event.target.numBooks.value);
            // const books = await getDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"));
            // console.log(name);
            if(event.target.numBooks.value===null|| event.target.numBooks.value===0){
                emptyNotification();
            }
            // else if(books.exists()){
                // console.log(name);
                // duplicateNotification();
            // }
            else{
                setDoc(doc(firestore,"Users",user.uid, "ReadingChallenge",  "TotalBookGoal"),{
                    numBooksWantReadGoalAmount: event?.target?.numBooks?.value,
    
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }




    }

    const ProgressBar = ({progress})=>{
        const cappedProgress = Math.min(progress, 100);
        const overarchieved = progress > 100;

        return(
            <div className="relative">
                <div className="w-full bg-gray-200 rounded-full h-6">
                    <div className={`rounded-full h-6 transition-all duration-500 flex items-center justify-end pr-2 text-white text-sm 
                        ${overarchieved? 'bg-green-600':'bg-blue-600'}`}
                        style={{width: `${cappedProgress}`}}>
                            {progress.toFixed(0)}%
                    </div>
                </div>
                {
                    overarchieved && (
                        <div className="absolute top-0 right-0 -mr-4 bg-green-100 text-green-800 px-2 py-1 rounded-full text-xs">
                            +{(readingChallengeProgress-100).toFixed(0)}%
                        </div>
                    )
                }
           </div>
        );

    }

    useEffect(()=>{

        getFinishedBooksCount();
        getFinishedReadingBooks();
        getBookGoal();
        },[])

    return (
        <section className="bg-gray-900">
        <div className="p-6 max-w-6xl mx-auto bg-gray-900 min-h-screen">
            <div className="mb-8 flex justify-between items-center">
                <div>
                    <h1 className="text-3xl font-bold mb-2">Reading Challenges</h1>
                    <p className="text-gray-200">Track your reading progress throughout the year</p>
                </div>
                <div className="flex gap-4">
                <select
                value={selectedYear}
                onChange={(e)=>setSelectedYear(e.target.value)}
                className="bg-gray-700 border border-gray-300 rounded-lg px-4 py-2">
                    {[2024, 2023].map(
                        year=>(
                            <option key={year} value={year}>
                                {year}
                            </option>
                        )
                    )}
                </select>
                <button
                onClick={()=>showBookGoalSettingForm(!bookGoalSettingForm)}
                className="bg-gray-900 p-2 rounded-lg hover:bg-gray-300 transition-colors">
                    <Settings className="w-5 h-5"/>
                </button>
                </div>
            </div>

            {bookGoalSettingForm &&(
                <div className="bg-gray-700 p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
                    <h2 className="text-xl font-bold mb-4">Adjust Reading Goal</h2>
                    <p className="text-sm text-gray-200">How many books do you want to read?</p>
                <form onSubmit={numBooksToRead}>
                    <input
                    className="w-full border rounded px-4 py-2 mb-4 mt-4" 
                    name = "numBooks"
                    value = {booksReadCount}
                    type="number" 
                    placeholder="Enter number books..."/>
                    <div className="flex justify-start gap-2">
                    <button
                            onClick={()=>showBookGoalSettingForm(false)}
                            
                            className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">Cancel</button>
                    <button
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
                    type="submit">Submit</button>
                    </div>
                </form>
            </div>
            )}
            
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your book club has been created!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue creating your book club"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter name for your book club!"
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                  title = "Error"
                content = "Book club already exists!"
            />} 

            {/* Progress Bar */}
            <div className="bg-gray-700 p-6 rounded-lg shadow-sm border border-gray-100 mb-8">
                <div className="flex justify-between mb-2">
                    <span className="font-semibold">{booksReadCount? booksReadCount: "0"} of {bookGoal} books</span>
                    <span className="text-gray-300">{
                    Math.round(readingChallengeProgress)
                    }% complete</span>
                </div>
                {/* <div
                className="bg-blue-600 rounded-full h-4 transition-all duration-500"
                style={{width:`${readingChallengeProgress}%`}}>

                </div> */}
                <ProgressBar progress={readingChallengeProgress}/>

            </div>



            {/* <h2>Your have read {booksReadCount? booksReadCount: "0"} Books!</h2> */}
            {/* <Bar
                // width={100}
                // height={50}
                // options={{maintainAspectRatio:false}}
                data = {
                {
                    labels:[
                        "Books"
                    ],
                    datasets: [
                        {
                            label: "Books Completed (%)",
                            backgroundColor: 'rgba(96, 125, 139)',
                            data: [(booksReadCount/bookGoal)*100],
                            fill: true
                        }
                    ]
                }
            }/> */}


            

            

   

            {/* <h2>Finished Books</h2> */}
            {/* <div className="card-container">
                    {
                        FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                            <div className="card">
                                <br/>
                                <img src={book.bookImage} height={100} width={60}/>
                                <h2 key={idx}>{book.bookTitle}</h2>
                            </div>

                        ))
                        
                    }
            </div> */}


            {/* Book List */}
            <h2 className="text-2xl font-semibold">Finished Books</h2> 
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                
                {FinishedReadingBooks?.slice(0,4).map((book,idx)=>(
                    
                        <div className="flex bg-gray-700 rounded-lg overflow-hidden"> 
                           
                            <img 
                            src={book.bookImage} 
                            className="w-24 h-36 object-cover"/>
                            <div className="p-4 flex-1">
                                <h3 className="font-semibold">{book.bookTitle}</h3>
                                <p className="text-gray-300 text-sm">Author</p>
                            </div>
                        </div>

                    ))}
            </div>

            <button
            className="mt-2 bg-blue-700 text-white px-4 py-2 rounded-lg hover:bg-blue-300 transition-colors" 
            onClick={onFinishedBookButton}>
                View All Books Read
            </button>

        </div>
        </section>

    );
};
export default ReadingChallenge;