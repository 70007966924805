import React, {useState,useEffect,useRef} from "react";

import {Nav,NavLink, NavMenu, Hamburger, Bars} from "./NavbarElements";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate} from 'react-router-dom';
import { Bell, Book, BookOpen, ChevronDown, DoorOpen, Glasses, Home, Library, Lightbulb, Mail, Menu, Notebook, Search, Settings, TrendingUp, User, Users, X } from "lucide-react";
import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook, faBars, faBell, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './../../../src/App.css'
import { set } from "react-ga";

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const [isMenuOpen,setIsMenuOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('home');
    const [activeDropdown, setActiveDropdown] = useState(null);
    const dropdownTimeoutRef = useRef(null);


    const navigate = useNavigate();

    const navItems = [
        {
            id:'home',
            label: 'Home',
            icon: Home,
            hasDropdown: false,
            link: "/"
        },
        {
            id:'mybooks',
            label: 'Login',
            icon: DoorOpen,
            hasDropdown: false,
            link: "/login"
        },
        {
            id:'register',
            label: 'Register',
            icon: DoorOpen,
            hasDropdown: false,
            link: "/register"
        }
    ];


    const toggleMenu = () =>{
        setIsOpen((open)=>!open)

    }




    // const handleOutsideClick = (event) =>{
    //     if(menuRef.current && 
    //         !menuRef.current.contains(event.target) &&
    //         event.target !== buttonRef.current){
    //             setIsOpen(false);

    //     }
    // }


    const handleMouseEnter = (id) =>{
        // if(window.innerWidth>=768){
        //     setActiveDropdown(id);
        // }
    
        if(dropdownTimeoutRef.current){
            clearTimeout(dropdownTimeoutRef.current);
        }
    
        setActiveDropdown(id);
    }
    
    
    const handleMouseLeave = () =>{
        // if(window.innerWidth>=768){
        //     setActiveDropdown(null);
        // }
        dropdownTimeoutRef.current = setTimeout(()=>{
            setActiveDropdown(null);
        }, 300)
    }
    

    useEffect(()=>{

        const handleOutsideClick =(e)=>{
            if(!e.target.closest('.nav-item-container')){
                setActiveDropdown(null);
            }
    
        }

        // const hideMenu = e =>{
        //     // if(e.path.tagName !=="BUTTON"){
        //     //     setIsOpen(false);

        //     // }
        //     console.log("loggedin");
        //     console.log(e);
            
    
        // }
        setIsMenuOpen(false);
        
        document.body.addEventListener('click', handleOutsideClick);
        return () => document.body.removeEventListener('click', handleOutsideClick);

    },[]);

    return (
        <div className="h-[85px]">
       
            <nav class="fixed w-full top-0 left-0 z-50">
                {/* Gradient border line */}
            <div className="h-1 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"></div>
            {/* Main Navigation */}
            <div className="backdrop-blur-lg border-b bg-gray-900/50 bg-gray/70">
                <div className="max-w-7xl mx-auto px-4">
                    <div className="flex justify-between items-center h-20">
                        {/* logo */}
                        <div className="flex items-center">
                            <a href="/">
                            <span className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent hover:from-purple-600 hover:to-pink-600 transition-all duration-500 cursor-pointer">
                                Entrepreneurship Books App
                            </span>
                            </a>
                        </div>

                        {/* Desktop Navigation */}
                        <div className="hidden md:flex items-center space-x-4">
                            {/* <div className="relative inline-block"> */}
                                {navItems.map(({id, label, icon: Icon, link})=>(
                                    <div
                                    key={id}
                                    className="nav-item-container relative inline-block"
                                    onMouseEnter={()=>handleMouseEnter(id)}
                                    onMouseLeave={handleMouseLeave}
                                    
                                    >
                                        <button
                                        
                                        // onMouseLeave={handleMouseLeave}
                                        onClick={()=>{
                                            setActiveTab(id)
                                            navigate(link)}}
                                        className={`flex items-center px-4 py-2.5 rounded-xl text-sm font-medium transition-all duration-300 ease-in-out group hover:scale-105 active:scale-95 ${
                                            activeTab === id ? 'text-blue-600 bg-blue-300/80 shadow-sm':
                                            'text-gray-600 hover:bg-blue-300/80'
                                        }`}>
                                            <span className=" text-gray-200 ml-2">{label}</span>
                           
                                        </button>
                                
                                    </div>
                                ))}
                            
                        </div>
                        {/* Right side actions */}

                        <div className="flex items-center space-x-2">   
                {/* mobile menu button */}
                <button
                onClick={()=>setIsMenuOpen(!isMenuOpen)}
                className="md:hidden p-2.5 rounded-xl text-gray-600 hover:bg-gray-50/80 transition-all duration-300">
                    {isMenuOpen ?
                    <X className="h-5 w-5"/>:
                    <Menu className="h-5 w-5"/>}
                </button>
            </div>

                    </div>  
                    
            
                

            

                   

        

            {/* Mobile Menu */}
            <div className={`md:hidden transition-all duration-500 ease-in-out ${
                        isMenuOpen? 'max-h-screen opacity-100':
                        'max-h-0 opacity-0'

                    } overflow-hidden`}>

                        <div className=" px-2 py-4 space-y-1">
                            {/* <div className="relative"> */}
                                {navItems.map(({id, label, icon: Icon, link})=>(
                                    <div key={id} className="nav-item-container space-y-2">
                                        <button
                                         key={id}
                                         className={`w-full flex items-center justify-between px-4 py-3 rounded-lg text-sm font-medium
                                            transition-all duration-300
                                            ${activeTab===id?
                                                'text-blue-600 bg-gray-300/80':
                                                'text-gray-600 hover:bg-gray-300/80'
                                            }`}
                                         onClick={()=>{
                                            navigate(link)
                                            setActiveTab(id)
                                            // if(dropdownItems){
                                            setActiveDropdown(activeDropdown === id? null: id)
                                            // }else{
                                                // setIsMenuOpen(false)
                                            
                                        //  }
                                         }}>
                                            <div className="px-2 flex items-center">
                                                <Icon className={`h-5 w-5 ${activeTab=== id? 'text-blue-500':'text-gray-400'}`}/>
                                                <span className=" text-gray-200 ml-3">{label}</span>
                                            </div>
                                         

                                        </button>

                                    </div>

                                ))}
                                </div>
                                </div>

                            </div>
                        </div>
             </nav>
        </div>
  


    );
};


export default Navbar;