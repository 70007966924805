import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import {db, auth_} from './../firebase';
import {collection, DocumentSnapshot, getDocs, QuerySnapshot, setDoc, getDoc, doc, getFirestore} from 'firebase/firestore';

// import axios from 'axios';
import { Check, ChevronDown, Search, X } from "lucide-react";

const ITEMS_PER_PAGE = 10;

const SearchBooksScreen = () =>{
    const navigate = useNavigate();

    const user = auth_.currentUser;
    const location = useLocation();
    const [book, setBook] = useState("");
    const [result, setResult] = useState([]);
    const [apiKey, setApiKeyhowReader] = useState("AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw");

    const [selectedCategory, setSelectedCategory ] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const [allBooks, setBooks] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [uniqueCategories, setUniqueCategories] =useState([]);



    const getBooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const allBooksData = querySnapshot.docs.map((doc)=>({
                ...doc.data()
            }));
            setBooks(allBooksData);

            const allCategories = new Set();

            allBooksData.forEach(book=>{

                if(Array.isArray(book.category)){
                    book.category.forEach(
                        cat=>allCategories.add(cat)
                    );
                }
  
            });

            const sortedCategories = ["All", ...Array.from(allCategories).sort()];

            setUniqueCategories(sortedCategories);


            // const categories = ["All", ...new Set(allBooks.map(book =>book.category))];


            // setAvailableCategories(Array.from(allCategories).sort())
        });
    }

    



    const filteredBooks = allBooks.filter(book=>{
       

        const matchesSearch = book?.title?.toLowerCase().includes(searchQuery?.toLowerCase())||
        book?.author?.toLowerCase().includes(searchQuery?.toLowerCase());

        // check genres
        // const matchesCategory = selectedCategory==="All" || book?.category?.includes(selectedCategory);

        
        const matchesCategory = selectedCategory==="All" || book?.category?.includes(selectedCategory);

        

        return matchesSearch && matchesCategory;
        
    });

    const toggleCategory = (category) =>{
        setSelectedCategory(prev=>
            prev.includes(category)?
            prev.filter(cat =>cat!==category):
            [...prev, category]
        );
    }

    // calculate pagination
    const totalPages = Math.ceil(filteredBooks.length/ITEMS_PER_PAGE);
    const paginatedBooks = filteredBooks.slice(
        (currentPage - 1)*ITEMS_PER_PAGE,
        currentPage*ITEMS_PER_PAGE

    );

    function handleChange(event){
        const book = event.target.value;
        setBook(book);
    }

    // function handleSubmit(event){
    //     event.preventDefault();
    //     axios.get("https://www.googleapis.com/books/v1/volumes?q="
    //     +book+"&key="
    //     +apiKey+"&maxResults=4").then(
    //         data =>{
    //             console.log(data.data.items);
    //             setResult(data.data.items);
    //         }
    //     )
       
    // }

    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_details/'+title,{
            state: {
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }


    const onToFinishedBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToCurrentlyReadingBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Finished Book", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWantToReadBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const read = await getDoc(doc(firestore, "Users",user.uid, "Finished Book",title));
            const current = await getDoc(doc(firestore, "Users",user.uid, "Currently Reading",title));
            const want = await getDoc(doc(firestore, "Users",user.uid, "Want to Read",title));
    

            if(read.exists || current.exists || want.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Currently Reading", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    const onToWishlistBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const wishlist = await getDoc(doc(firestore, "Users",user.uid, "Wishlist",title));
       

            if(wishlist.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Wishlist", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }


    const onToFavoritesBooksShelf = async (title, author, search, event ) =>{
        event.preventDefault();
        let date = new Date();
        date = date.toString();
        try{

            const firestore = getFirestore();

    
            const favorities = await getDoc(doc(firestore, "Users",user.uid, "Favorites",title));
    

            if(favorities.exists){
                console.log("book exists in shelves. Would you like to move it?");

            }else{
                setDoc(doc(firestore,"Users",user.uid, "Favorites", title),{
                    Authors: author,
                    DateandTime: date,
                    bookTitle: title,
                    searchTerm: search

                });
                console.log("done!");
            }

        }catch(event){
            console.log("Error Adding Book: "+event );

        }


    }

    useEffect(()=>{
        getBooks();
        setCurrentPage(1);

    },[searchQuery, selectedCategory]);


    const PaginationButton =({page, isActive, onClick}) =>(
        <button 
        className={` px-3 py-1 rounded ${isActive? 'bg-blue-500 text-white':'bg-gray-100 text-gray-600 hover:bg-gray-200'}`}
        onClick={onClick}>
            {page}
        </button>
    );

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className="min-h-screen">
        <div>
        <h1 className="text-3xl font-bold mb-8">Book Search</h1>
        </div>
        <div className="max-w-7xl mx-auto px-4 py-8">
            
            <div className="bg-gray-900 rounded-lg shadow-sm p-4 mb-8">
            <div className="flex flex-col sm:flex-row gap-4 ">
                {/* Search input */}
                <div className="relative flex-1">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20}/>
                    <input 
                    type="text"
                    placeholder="Search by books by title or author..."
                    className="text-gray-900  hover:text-gray-50 w-full pl-10 pr-4 py-3 bg-gray-700 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:bg-gray-900"
                    value={searchQuery}
                    onChange={(e)=>setSearchQuery(e.target.value)}/>
                </div>

                {/* Category Dropdown */}
                {/* <select
                    className="bg-gray-700 px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={selectedCategory}
                    onChange={(e)=>setSelectedCategory(e.target.value)}
                >
                    {categories.map(category=>(
                        <option key={category} value={category}>{category}</option>

                    ))}
                </select> */}

                {/* Category Dropdown */}
                <div>
                  
                    <select
                    value={selectedCategory}
                    onChange={(e)=>setSelectedCategory(e.target.value)}
                    className="bg-gray-700 w-full px-3 py-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
                        
                    {uniqueCategories.map(category=>(
                        <option>
                            {category}
                        </option>

                    ))}

                    </select>
                </div>
                {/* <div className="relative">
                    <button 
                    className="text-gray-900  hover:text-gray-50 w-full md:w-48 px-4 py-3 bg-gray-700 border border-gray-200 rounded-lg flex items-center justify-between hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    onClick={()=>setCategoryDropdownOpen(!isCategoryDropdownOpen)}>
                        <span className="text-gray-200">
                            {selectedCategory.length===0?
                            'Select Category':
                            `${selectedCategory.length} categories selected`}
                        </span>
                        {
                            selectedCategory.length>0 &&(
                                <button
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    setSelectedCategory([]);
                                }}
                                className="ml-2 text-gray-500 hover:text-gray-700">
                                    <X size={16}/>

                                </button>
                            )
                        }
                        <ChevronDown size={20} className="text-gray-400"/>

                    </button>
                    {isCategoryDropdownOpen &&(<div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg">
                        {availableCategories.map(category=>(
                            <label
                            key={category}
                            className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer"
                            >
                                <input 
                                type="checkbox"
                                className="hidden"
                                checked={selectedCategory.includes(category)}
                                onChange={()=>toggleCategory(category)}/>
                                <span className={`mr-2 ${selectedCategory.includes(category)?'text-blue-600':'text-gray-600'}`}>
                                    {selectedCategory.includes(category)?<Check size={16}/>:null}

                                </span>
                                <span>{category}</span>

                            </label>
                            // <button
                            // key={category}
                            // className="w-full px-4 py-2 text-left hover:bg-gray-50 focus:outline-none focus:bg-gray-50">
                            //     {category}

                            // </button>
                        ))}
                    </div>
                )}

                </div> */}
                </div>
                </div>


                {/* Book Grid */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                    {paginatedBooks.map(book=>(
                        <div onClick={() =>onBookDetailsButton(book.title,book.description,book.cover,book.amzn_link,book.ibook_link,book.bookshop_org_link)} className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-sm transition-shadow">
                        <div className="flex h-full">
                            <div className="w-1/3 bg-gray-100">
                                <img
                                className="w-full h-full object-cover" 
                                src={book.cover}/>
                            </div>
                            <div className="w-2/3 p-4 flex flex-col">
                                <h2 className="text-lg font-semibold text-gray-900 mb-1 line-clamp-2">{book.title}</h2>
                                <p className="text-sm text-gray-600 mb-2">By {book.author}</p>
                                <div className="mt-auto items-center flex flex-wrap gap-2">
                                    {book.category && book.category.map(cat =>(
                                        <span key={cat} 
                                        className="px-2 py-1 bg-blue-50 text-blue-600 text-xs font-medium rounded-full">
                                            {cat}
                                        </span>

                                    ))}
                                </div>

                            </div>
                        </div>
                        </div>
                    ))}

                </div>

                {filteredBooks.length>0?(
                    <div className="mt-8 flex justify-center gap-2">
                        <button
                        onClick={()=>setCurrentPage(prev=>prev-1)}
                        disabled ={currentPage===1}
                        className="px-3 py-1 rounded bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50 disabled:hover:bg-gray-100">
                            Previous
                        </button>
                        {[...Array(totalPages)].map((_,i)=>(
                            <PaginationButton 
                                key={i+1}
                                page={i+1}
                                isActive={currentPage===i+1}
                                onClick={()=>setCurrentPage(i+1)}


                            />
                        ))}

                        <button
                        onClick={()=>setCurrentPage(prev=>prev+1)}
                        disabled ={currentPage===totalPages}
                        className="px-3 py-1 rounded bg-gray-100 text-gray-600 hover:bg-gray-200 disabled:opacity-50 disabled:hover:bg-gray-100">
                            Next
                        </button>

                    </div>
                ):(
                    <div className="text-center py-12 mt-8">
                        <p className="text-gray-500">No books found matching your search </p>

                    </div>
                )}
            {/* <h1>{location.state.id}</h1>
            <img src={result.map(book=>(book.volumeInfo.imageLinks.thumbnail))}/>
            <p>{result.map(book=>(book.volumeInfo.description))}</p> */}
            {/* <form onSubmit={handleSubmit}>
                <div>
                    <input className="search_input" onChange={handleChange} placeholder="Enter Book Name" type="text"/>
                    <input className="search_input" type = "submit" value="search"/>
                </div>
                <div className="card-container">
                    {result.map(book => (
                        <div onClick={() =>onBookDetailsButton(book.volumeInfo.title)} className="card">
                            <h3>{book.volumeInfo.title}</h3>
                            <img src={book.volumeInfo.imageLinks!==undefined?book.volumeInfo.imageLinks.thumbnail:''}/>

                        </div>
                    ))}
                    
                </div>
            </form> */}
            </div>


        </div>
        </section>

    );
};
export default SearchBooksScreen;