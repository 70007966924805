import { addDoc, collection, doc, getDoc, getDocs, orderBy, query, setDoc, updateDoc } from "firebase/firestore";
import { ChevronDown, Plus, Search, X } from "lucide-react";
import React, {useState, useEffect} from "react";
import {db, auth_} from './../firebase';
import { update } from "sneaks-api/models/Sneaker";

const Projects = () =>{
    const user = auth_.currentUser;
    const [projectUsers, setProjectUsers] = useState({});
    const [commenters, getCommenters] = useState({});
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState({});


    const fetchUserData = async(userId) =>{
        try{
            const userDoc = await getDoc(doc(db, "Users", userId));
            if(userDoc.exists()){
                return userDoc.data();
            }

        }catch(error){
            console.log("Error fetching users");

        }

    }

    const TagDropdown = ({
        label,
        predefinedOptions,
        selectedTags,
        onTagsChange,
        placeholder
    })=>{
        const [isOpen, setIsOpen] = useState(false);
        const [customInput, setCustomInput] = useState("");

        const handleAddTag = (tag, e) =>{
            e?.preventDefault();
            if(!selectedTags.includes(tag) && tag.trim() !==''){
                onTagsChange([...selectedTags, tag]);
            }
            setIsOpen(false);
            setCustomInput("");
        }

        const handleRemoveTag = (tagToRemove)=>{
            onTagsChange(selectedTags.filter(tag => tag!== tagToRemove));
        }

        const handleCustomSubmit = (e) =>{
            if(e.key == "Enter" && customInput.trim()){
            e.preventDefault();
            }
        }

        return(
            <div className="relative">
                <label className="block text-sm font-medium text-gray-300 mb-1">
                    {label}
                </label>

                {/* selected tags */}
                <div className="min-h-[42px] border text-white rounded-md bg-black p-2 flex flex-wrap gap-2">
                    {selectedTags.map((tag)=>(
                        <span
                            key = {tag}
                            className="inline-flex items-center text-white px-2 py-1 rounded-md text-sm">
                            {tag}
                            <button
                            onClick={()=>handleRemoveTag(tag)}
                            className="ml-1 hover:text-blue-800">
                                <X className="h-3 w-3"/>
                            </button>
                        </span>
                    ))}
                    <button
                    onClick={(e)=>{
                        e.preventDefault();
                        setIsOpen(!isOpen);
                    }
                    }
                    className="inline-flex items-center hover:text-gray-300">
                        {selectedTags.length===0?placeholder:''}
                        <ChevronDown className="h-4 w-4 ml-1"/>
                    </button>
                </div>

                {/* Dropdown Menu */}
                {isOpen && (
                    <div className="absolute z-10 mt-1 w-full bg-gray-700 border rounded-md shadow-lg">
                        {/* predefined option */}
                        <div className="p-2 border-b">
                            <div className="grid grid-cols-1 gap-2">
                                {predefinedOptions.map((option)=>(
                                    <button
                                    key={option}
                                    onClick={(e)=>handleAddTag(option,e)}
                                    className={`text-left px-3 py-2 rounded-md text-sm hover:bg-gray-900 ${
                                        selectedTags.includes(option) ? 'bg-gray-900 text-gray-400':''
                                    }`}
                                    disabled={selectedTags.includes(option)}>
                                        {option}
                                    </button>
                                ))}

                            </div>
                        </div>

                        {/* Custom Input */}
                        <div className="p-2">
                            <div className="flex items-center">
                                <input
                                type="text"
                                value={customInput}
                                onChange={(e)=>setCustomInput(e.target.value)}
                                onKeyDown={handleCustomSubmit}
                                placeholder="Add custom tag..."
                                className="flex-1 px-3 py-2 text-sm border-0 focus:ring-0 focus:outline-none"/>
                                {customInput && (
                                    <button
                                    onClick={()=>handleAddTag(customInput)}
                                    className="ml-2 p-1 text-blue-600 hover:text-blue-700">
                                        <Plus className="h-4 w-4"/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        )
    }
    
    const [posts, setPosts] = useState([{
        id: 1,
        title: "test",
        description: "Seeking stuff",
        category: "Education",
        author: "Jessie Jo",
        stage:"Early Development",
        // type: "idk",
        seeking: ["Technical Cofounder","Investment"],
        comments: [
            {id:1, author:"Mike Ross", text:"Interesting", timestamp: "2024-11-29"},
            {id:1, author:"Mike Ross", text:"Interesting", timestamp: "2024-11-29"}
        ]
    },
    {
        id: 1,
        title: "test",
        description: "Seeking stuff",
        category: "Education",
        author: "Jessie Jo",
        stage:"Early Development",
        // type: "idk",
        seeking: ["Technical Cofounder","Investment"],
        comments: [
            {id:1, author:"Mike Ross", text:"Interesting", timestamp: "2024-11-29"},
            {id:1, author:"Mike Ross", text:"Interesting", timestamp: "2024-11-29"}
        ]
    }]);
    const [projects, setProjects] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [activePostId, setActivePostId] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const [projectStageOptions] = useState(["Idea","Early Development","MVP","Scaling"]);
    const [projectCategoryOptions] = useState(["Tech", "Healthcare", "Finance", "Education","E-commerce"]);
    const [projectSeekingOptions] = useState(["Co-Founder", "Advisor", "Developer", "Designer","Engineer"]);

    const [selectedStages, setSelectedStages] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedSeeking, setSelectedSeeking] = useState([]);


    const projectCategories = ["Tech", "Healthcare", "Finance", "Education","E-commerce"]
    const projectStages = ["Idea","Early Development","MVP", "Scaling"]
    const projectSeeking = ["Co-Founder", "Advisor", "Developer", "Designer","Engineer"]

    const [projectForm, setProjectForm] = useState(false);


    const [projectData, setProjectData] = useState({
        title:'',
        description:'',
        category: '',
        author: user?.uid,
        stage: '',
        seeking:'',
    
    })


    const submitProjectToCloud = async(projectData)=>{
        try{
            const docRef = addDoc(collection(db, "Projects"),{
                ...projectData,
                category: selectedCategories,
                stage: selectedStages,
                seeking:selectedSeeking,
                createdAt: new Date()
            });

            const proj_uid = (await docRef).id;

            const projectDocRef = doc(db, "Projects", proj_uid);

            await updateDoc(projectDocRef,
                {
                    uid: proj_uid
                }
            );

            await setDoc(doc(db, "Users",user?.uid,"PostProjects", proj_uid),{
                ...projectData,
                category: selectedCategories,
                stage: selectedStages,
                seeking:selectedSeeking,
                uid: proj_uid,
                createdAt: new Date()
            });

            // console.log("Project submitted successfully: ", proj_uid);
            console.log("Project submitted successfully: ");
            return proj_uid;

        }catch(error){
            console.error("Error submitting projects", error);

        }

    }

    const handlePostSubmit = async(e)=>{
        e.preventDefault();
        try{

            const projectId = await submitProjectToCloud(projectData);
            console.log("Projected submitted successfully");

        }catch(error){
            console.error("Error submitting project blah")

        }   
    }

    const addComment = async(projectId, commentData)=>{
        try{
            const commentsRef = await addDoc(collection(db, "Projects",projectId, "Comments"),{
                text: commentData.text,
                userId: commentData.author,
                createdAt: new Date()
            });

            return commentsRef.uid;

        }catch(error){
            console.error("Error adding comment!")

        }
        
    }

    // const fetchComments = async(projectId)=>{
    //     try{
    //         const commentsQuery = query(
    //             collection(db, "Projects", projectId, "Comments"),
    //             orderBy("createdAt", "desc")
    //         );

    //         const snapshot = await getDocs(commentsQuery);
    //         const comments = snapshot.docs.map(doc=>({
    //             id: doc.uid,
    //             ...doc.data(),
    //             createdAt: doc.data().createdAt?.toDate()
    //         }));

    //         return comments;



    //     }catch(error){

    //         console.error("Error fetching comments!")

    //     }

    // }

    const handleCommentSubmit = async(projectId, commentText)=>{
       
        try{
            await addDoc(collection(db, "Projects",projectId, "Comments"),{
                text: commentText,
                userId: user?.uid,
                createdAt: new Date()
            });

            const commentsQuery = query(
                collection(db, "Projects", projectId, "Comments"),
                orderBy("createdAt", "desc")
            );

            const commentsSnapshot = await getDocs(commentsQuery);
            const updatedComments = commentsSnapshot.docs.map(doc=>({
                id: doc.uid,
                ...doc.data(),
                createdAt: doc.data().createdAt?.toDate()
            }));

            setComments(prev =>({
                ...prev,
                [projectId]: updatedComments
            }));
            
        }catch(error){
            console.error("Error adding coment");

        }

    }

   
    const fetchProjects = async()=>{
        try{
            // query projects ordered by creation date
            const projectsQuery = query(collection(db, "Projects"), orderBy("createdAt", 'desc'));
            // get the documents
            const querySnapshot = await getDocs(projectsQuery);

            // convert the data
            const projectsList = querySnapshot.docs.map(doc=>({
                id: doc.uid,
                ...doc.data(),
                createdAt: doc.data().createdAt?.toDate()
            }));

            setProjects(projectsList);

            const users_ ={};
            const commentsByProject = {};
            const comment_users_ ={};
            for (const project of projectsList){
                if(project.author){
                    const userData = await fetchUserData(project.author);
                    if(userData){
                        users_[project.author] = userData;
                    }
                }

                const commentsQuery = query(
                    collection(db, "Projects",project.uid, "Comments"),
                    orderBy("createdAt", "desc")
                );


                const commentsSnapshot = await getDocs(commentsQuery);

                const projectComments = commentsSnapshot.docs.map(doc=>({
                    id: doc.uid,
                    ...doc.data(),
                    createdAt: doc.data().createdAt?.toDate() 
                }))

                for (const comment of projectComments){
                    if(comment.userId && !comment_users_[comment.userData]){
                        const userDoc = await getDoc(doc(db, "Users", comment.userId));
                        if(userDoc.exists()){
                            comment_users_[comment.userId] = userDoc.data()

                        }
                    }
                }
                
                commentsByProject[project.uid] = projectComments;

                // commentsByProject[project.uid] = commentsSnapshot.docs.map(doc =>({
                //     id: doc.uid,
                //     ...doc.data(),
                //     createdAt: doc.data().createdAt?.toDate()
                // }));
                
                
                // if(commentsByProject[project.uid].userId){
                //     console.log("hello")
                //     const commentUserData = await fetchUserData(commentsByProject[project.uid].userId);
                //     if(commentUserData){
                //         comment_users_[commentsByProject[project.uid].userId] = commentUserData;
                //     }
                // }
            }

            setComments(commentsByProject);
            getCommenters(comment_users_);

            setProjectUsers(users_);
            setLoading(false);



        }catch(error){
            console.log("Error fetching projects")

        }
    }

    const onJoinGroup = async() =>{

    }

    useEffect(()=>{
        fetchProjects();
    },[]);

    return (
        <div className="min-h-screen bg-gray-900 py-8">
            <div className="max-w-4xl mx-auto px-4">
            <div className="flex justify-between mb-4">
                <div>
                    <h1 className="text-3xl font-bold text-gray-300">Business Idea Sharing Hub</h1>
                    <p className="mb-8">Find business partners, get advice, and learn from others</p>
                </div>
                <div>
                {/* <button
                onClick={onJoinGroup}
                className="bg-purple-600 text-white px-3 py-2 rounded-md hover:bg-purple-700">
                    Join Group
                </button> */}
                </div>
            </div>
                

                {/* Search and Filter Bar */}
                <div className="mt-4 rounded-lg shadow-sm p-4 mb-6">
                    <div className="flex gap-4 mb-4">
                        {/* <div className="flex-1 relative">
                            <Search className="absolute left-3 top-2.5 text-gray-400 h-5 w-5"/>
                            <input
                            type="text"
                            placeholder="Search projects..."
                            className="w-full pl-10 pr-4 py-2 border rounded-md"
                            value={searchTerm}
                            onChange={(e)=>searchTerm(e.target.value)}/>
                            </div> */}
                            <button
                            onClick={()=>setProjectForm(!projectForm)}
                            className="px-4 py-2 bg-blue-600 text-white rounded-md flex items-center gap-2 hover:bg-blue-700">
                                Post A Project
                            </button>
                    </div>
                    {/* Filter tags */}
                    {/* <div className="flex gap-4">
                        <select className="px-3 py-1.5 border rounded-md text-sm">
                            <option value="">All Categories</option>
                            {projectCategories.map(cat=>(
                                <option key={cat} value={cat}>{cat}</option>
                            ))}

                        </select>
                        <select className="px-3 py-1.5 border rounded-md text-sm">
                        <option value="">All Stages</option>
                            {projectStages.map(stage=>(
                                <option key={stage} value={stage}>{stage}</option>
                            ))}

                        </select>

                        <select className="px-3 py-1.5 border rounded-md text-sm">
                        <option value="">All Seeking</option>
                            {projectSeeking.map(seek=>(
                                <option key={seek} value={seek}>{seek}</option>
                            ))}

                        </select>

                    </div> */}

                </div>


                {/* New Project Form */}
                {projectForm && (<div className="bg-gray-700 rounded-lg shadow p-8">
                    <h2 className="text-xl font-semibold mb-4">Share Your Project</h2>
                    <form onSubmit={handlePostSubmit} className="space-y-4">
                        <input
                        type="text"
                        placeholder="Project Title"
                        value={projectData.title}
                        onChange={(e)=>setProjectData({...projectData, title: e.target.value})}
                        className="w-full px-3 py-2 border rounded-md"
                        />
                        <textarea
                        placeholder="Project Description"
                        value={projectData.description}
                        onChange={(e)=>setProjectData({...projectData, description: e.target.value})}
                        className="w-full px-3 py-2 border rounded-md h-32"/>
                        <div className="grid grid-cols-2 gap-4">

                            <TagDropdown
                                label="Project Stage"
                                predefinedOptions={projectStageOptions}
                                selectedTags={selectedStages}
                                onTagsChange={setSelectedStages}
                                placeholder="Select or add stages"/>

                            <TagDropdown
                                label="Project Category"
                                predefinedOptions={projectCategoryOptions}
                                selectedTags={selectedCategories}
                                onTagsChange={setSelectedCategories}
                                placeholder="Select or add categories"/>

                            <TagDropdown
                                label="Seeking"
                                predefinedOptions={projectSeekingOptions}
                                selectedTags={selectedSeeking}
                                onTagsChange={setSelectedSeeking}
                                placeholder="Select or add seeking"/>


                            {/* <input
                            type="text"
                            value={projectData.category}
                            onChange={(e)=>setProjectData({...projectData, category: e.target.value})}
                            placeholder="Enter category"
                            className="px-3 py-2 border rounded-md"/> */}
                            {/* <input
                            type="text"
                            value={projectData.stage}
                            onChange={(e)=>setProjectData({...projectData, stage: e.target.value})}
                            placeholder="Enter project stage"
                            className="px-3 py-2 border rounded-md"/> */}
                            {/* <input
                            type="text"
                            value={projectData.seeking}
                            onChange={(e)=>setProjectData({...projectData, seeking: e.target.value})}
                            placeholder="Seeking (comma-seperated)"
                            className="px-3 py-2 border rounded-md"/> */}
                            
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">Share Project</button>
                    </form>
                </div>)}

                {/* Project Post */}
                <div className="mt-2 space-y-6">
                    {projects.map(post=>(
                        <div 
                        key={post.uid}
                        className="bg-gray-700 rounded-lg p-6">
                            <div className="flex justify-between items-start mb-4">
                            <div>
                                <h2 className="text-xl font-semibold">{post.title}</h2>
                                {post.author && projectUsers[post.author] &&(
                                    <div className="text-gray-300">Posted by {projectUsers[post.author].displayName}</div>
                                )}
                                
                            </div>
                            {/* <span className="bg-gray-100 text-gray-600 px-3 py-1 rounded-full text-sm">
                                {post.type}
                            </span> */}

                            <div className="flex flex-wrap gap-2 mb-4">
                                {post.stage.map((item, idx)=>(
                                        <span key={idx} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                                            {item}
                                        </span>

                                    ))}
                            </div>

                        </div>
                        <p className="mb-4">{post.description}</p>

                        
                        <div className="flex flex-wrap gap-2 mb-4">
                        Seeking:{post.seeking.map((item, idx)=>(
                                <span key={idx} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                                     {item}
                                </span>

                            ))}
                       </div>

                       <div className="flex flex-wrap gap-2 mb-4">
                            {post.category.map((item, idx)=>(
                                <span key={idx} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                                    {item}
                                </span>

                            ))}
                       </div>

                       {/* Comment Section */}
                         <div className="mt-6 border-t pt-4">
                            <h3 className="font-semibold mb-4">Comments</h3>
                            <div className="space-y-4">
                                 {comments[post.uid]?.map(comment=>(
                                    <div key={comment.userId} className="bg-gray-900 p-3 rounded">
                                        <div className="flex justify-between text-sm text-gray-600">

                                        {comment.userId && commenters[comment.userId] &&(
                                            <span className="text-gray-300">Posted by {commenters[comment.userId].displayName}</span>
                                        )}
                                            {/* <span> {commenters(comment.userId).displayName}</span> */}
                                            <span>{comment.createdAt?.toLocaleDateString()}</span>
                                        </div>
                                        <p className="mt-1">{comment.text}</p>

                                    </div>
                                ))} 
                            </div>
                            {/* Comment Form */}
                             <div className="mt-4 flex gap-2">
                                <form onSubmit={(e)=>{
                                    e.preventDefault();
                                    handleCommentSubmit(post.uid, newComment);
                                    setNewComment("");
                                }} className="mb-4">
                                    <textarea
                                    value={newComment}
                                    onChange={(e)=>setNewComment(e.target.value)}
                                    placeholder="Add a comment.."
                                    className="w-full p-2 border rounded-md"

                                    />
                                    <button className="mt-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-50 hover:text-gray-900">
                                        Post
                                    </button>
                                </form>

                            </div>

                        </div> 
                       </div> 
                    ))}

                </div>
               

                

            </div>

        </div>
    )


}

export default Projects;