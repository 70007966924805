import { Contact } from "lucide-react";
import React, {useState} from "react";


const ContactUs = () =>{

    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');



    return(
        <section class="min-h-screen bg-gray-900 p-4 md:p-6 ">
        <div className= "max-w-3xl mx-auto mt-10">
            {/* <h1>Contact Us</h1> */}
            <div className="flex items-center justify-center gap-2 mb-2">
                <Contact className="w-6 h-6 text-blue-600"/>
                <h1 className="text-3xl font-bold text-gray-300">Contact Us</h1>
            </div>
            <div className="text-center">
            <p>If you have any suggestions on feedback,
            please submit it here: <a className="underline text-blue-600" href="https://bm93pem25sd.typeform.com/to/augvi2rM">Feedback Suggestions</a>.</p>
            
            <p>If you have any book suggestions,
            please submit those here: <a className="underline text-blue-600" href="https://bm93pem25sd.typeform.com/to/augvi2rM">Book Suggestions</a>.</p>
            
            <p>Anything else, please submit your questions or comments below.</p>
            </div>

            <form className="mt-2">
            <div>  
                <label>Email</label>       
                <input
                    // class = "input_register"
                    id = "Email"
                    name = "Email"
                    type = "email"
                    required
                    placeholder='Enter email'
                    className="w-full border rounded px-4 py-2 mb-4"
                    onChange={(e)=>setEmail(e.target.value)}
                    />
            </div>
            <div>
                <label>Subject</label>         
                <input
                    // class = "input_register"
                    id = "Subject"
                    name = "Subject"
                    type = "text"
                    required
                    placeholder='Enter subject'
                    className="w-full border rounded px-4 py-2 mb-4"
                    onChange={(e)=>setSubject(e.target.value)}
                    />
            </div>
            <div> 
                <p>Message</p>
                <textarea
                    // class = "input_register"
                    className="w-full border rounded px-4 py-2 mb-4 h-32"
                    id = "Message"
                    name = "Message"
                    type = "text"
                    required
                    placeholder='Enter message'
                    
                    onChange={(e)=>setMessage(e.target.value)}
                    />
            </div>
            <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
            type="submit">Submit</button>
            </form>
        </div>
    </section>

    );
};

export default ContactUs;