import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate} from 'react-router-dom';
import { faBoltLightning, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore } from '@fortawesome/free-solid-svg-icons';


const BookCategories = () =>{
    const navigate = useNavigate();

    const onRaisingMoneyButton  = () =>{
 
        navigate('/raising_money');
    
    }

    const onNonprofitButton  = () =>{
 
        navigate('/nonprofit');
    
    }   
    
    const onBeginnerEntrepreneurButton  = () =>{
 
        navigate('/beginner_entrepreneurship_books');
    
    }

    const onBusinessStrategyButton  = () =>{
 
        navigate('/business_strategy');
    
    }

    const onFinanceButton  = () =>{
 
        navigate('/finance');
    
    }
        
    const onFranchiseButton  = () =>{
 
        navigate('/franchise');
    
    }


    const onPopularBooksButton  = () =>{
 
        navigate('/popular_books');
    
    } 
    
    const onFinancialFreedomBooksButton  = () =>{
 
        navigate('/financial_freedom');
    
    } 
    
    return (
            <section class="bg-gray-50 dark:bg-gray-900">
                <div class=" flex flex-col  items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <h1 class="mb-6 text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Explore Books By Category</h1>
                    <div class="mt-16 gap-4 p-4 grid  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <button class=" w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onBusinessStrategyButton}>  
                        Business Strategy
                        
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChess} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFinanceButton}>
                        Finance  
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faArrowTrendUp} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFranchiseButton}>
                        Franchise
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faStore} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onNonprofitButton}>
                        Nonprofit Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faSchool} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onRaisingMoneyButton}>
                        Raising Money For Your Business
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faDollarSign} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onBeginnerEntrepreneurButton}>
                        Business Lessons
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faChalkboardTeacher} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onPopularBooksButton}>
                        Popular Books
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faFire} size ="2xl"/>
                    </button>
                    <button class="w-full text-white font-medium text-sm px-5 py-5 text-center bg-white rounded-lg shadow shadow-black hover:shadow-gray-500 dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-700 dark:border-gray-700 hover:dark:bg-gray-900" onClick={onFinancialFreedomBooksButton}>
                        Financial Freedom
                        <br/>
                        <br/>
                        <FontAwesomeIcon icon={faBoltLightning} size ="2xl"/>
                    </button>
                    </div>                    
                </div>
            </section>
    );
};

export default BookCategories;