import React, { useState,useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import {query, where, collection, DocumentSnapshot, getDocs, getDoc, setDoc, doc, deleteDoc, getCountFromServer, getFirestore} from 'firebase/firestore';
import {auth_, db, getProfilePic} from './../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookReader, faCheck} from '@fortawesome/free-solid-svg-icons';
import {v4 as uuidv4} from 'uuid';import { BookOpen, Clock, MessageCircle, User, UserCheck, UserPlus } from "lucide-react";
const ReaderProfile = () =>{
    // const params = useParams();
    // console.log(useParams().id);
    let readerDisplayname = useParams().id;
    
    
    const [uid, setUid] = useState(null);
    // const [userInfo, setUserInfo] = (null); 
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // const location = useLocation();

    // console.log(window.location.href);
    // console.log(location.pathname);
   

    const navigate  = useNavigate();
    const user = auth_.currentUser;

    // const [user, setUser] = useState(props.location.state ? 
    //     props.location.state:'')

    // console.log("PROPS");
    // console.log( location.state.id.displayName);

    const [followingCount, countFollowing] = useState(0)
    const [followersCount, countFollowers] = useState(0)
    const [booksReadCount, countFinishedBooks] = useState(0)
    const [reader, showUserInfo] = useState([]);
    

    const [FinishedReadingBooks, showFinishedReadingBooks] = useState([]);
    const [CurrentlyReadingBooks, showCurrentlyReadingBooks] = useState([]);
    const [WantToReadBooks, showWantToReadBooks] = useState([]);
    const [Wishlist, showWishlistBooks] = useState([]);
    const [Favorites, showFavoriteBooks] = useState([]);
    const [isFollowingReader, setAsFollowingReader] = useState(false);

    const [aboutText, showAboutText] = useState("");
    const [profBackgroundText, showProfBackgroundText] = useState("");
    const [companyDescriptionText, showCompanyDescriptionText] = useState("");
    const [roleInCompanyText, showRoleInCompany] = useState("");
    const [ownershipYears, showOwnershipYears] = useState("");
    const [industryText, showIndustryText] = useState("");

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    
    const getAboutandExperienceInfo = async () =>{
        try{

        const aboutAndExperienceInformation =  await getDoc(doc(db, "Users", uid));
        if(aboutAndExperienceInformation.data()?.photoURL){
            setPhotoURL(aboutAndExperienceInformation.data().photoURL);
        }
            if(aboutAndExperienceInformation.data()?.about){
            showAboutText(aboutAndExperienceInformation.data().about);
            }

            if(aboutAndExperienceInformation.data()?.experience?.professional_background){
                showProfBackgroundText(aboutAndExperienceInformation.data().experience.professional_background);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_desc){
                showCompanyDescriptionText(aboutAndExperienceInformation.data().experience.company_desc);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_role){
                showRoleInCompany(aboutAndExperienceInformation.data().experience.company_role);
            }

            if(aboutAndExperienceInformation.data()?.experience?.company_years_in){
                showOwnershipYears(aboutAndExperienceInformation.data().experience.company_years_in);
            }

            if(aboutAndExperienceInformation.data()?.experience?.industry){
                showIndustryText(aboutAndExperienceInformation.data().experience.industry);
            }
        }catch(error){
            console.log("hello 2");
            // console.log(readerDisplayname);
            // console.log(uid);
            // console.log(userInfo);
            console.log(error);
        }
    
    
    
        
       
    }
    const followReader = async (reader_name, reader_uid, reader_email) =>{
        const firestore = getFirestore();
        const date = new Date;
        const followUniqueID = uuidv4();

        // console.log("reader ");
        // console.log(reader_name);
        // console.log(reader_uid);
        // console.log(reader_email);

       
        const followingReader = await getDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));

        if(followingReader.data()){
            console.log("Member already is following!");

            setAsFollowingReader(!isFollowingReader);
            deleteDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid));
            deleteDoc(doc(firestore,"Users",user.uid,"Following", reader_uid));

            if(followingReader.followID){
                deleteDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followingReader.followID + reader_uid));
            }

          


        }else{
            // console.log("Member is now following!");
            setAsFollowingReader(!isFollowingReader);

            try {

            setDoc(doc(firestore,"Users", user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: reader_name,
                followingEmail: reader_email,
                followID: followUniqueID,
                followingUID: reader_uid,
            });
            
            setDoc(doc(firestore,"Users",reader_uid,"Followers", user.uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followerUID: user.uid,
            });

            setDoc(doc(firestore,"Users",reader_uid,"Notifications", user.uid + followUniqueID+reader_uid),{
                DateandTime: date,
                followerDisplayName: user.displayName,
                followerEmail: user.email,
                followID: followUniqueID,
                followerUID: user.uid,
                type: "follow",
                isRead: false,
                uid: user.uid + followUniqueID+reader_uid
            });

            setDoc(doc(firestore,"mail", user.uid + followUniqueID+reader_uid),{
                to: reader_email,
                message: {
                    subject: "You have a new follower!",
                    html:  user?.displayName + " just started following you! <code><br/><button><a href='https://entrepreneurshipbooksapp.com/notifications'>View</a></button></code>",

                }
            });

            setDoc(doc(firestore,"Users",user.uid,"Following", reader_uid),{
                DateandTime: date,
                followingDisplayName: user.displayName,
                followID: followUniqueID,
                followingUID: reader_uid,
            });

        }catch(error){
            // console.log("following error");
            console.log(error);

    }
}

    }

    const getFollowers = async() =>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users", uid, "Followers");
        const count = await getCountFromServer(c);
        countFollowers(count.data().count);
        }

    const getFollowing = async() =>{
            const firestore = getFirestore();
            const c = collection(firestore, "Users",uid, "Following");
            const count = await getCountFromServer(c);
            countFollowing(count.data().count);
            } 


    const getCurrentlyReadingBooks = async() =>{
    
                    await getDocs(collection(db, "Users", uid, "Currently Reading")).then((querySnapshot)=>{
                        const book = querySnapshot.docs.map((doc)=>({
                            ...doc?.data()
                        }));
        
                            // console.log("BOOKS:");
                            // console.log(book);
                            showCurrentlyReadingBooks(book);
                
                    });     
        }
        
        
        const getFinishedReadingBooks = async() =>{
                        await getDocs(collection(db, "Users", uid, "Finished Book")).then((querySnapshot)=>{
                            const book = querySnapshot.docs.map((doc)=>({
                                ...doc?.data()
                            }));
                                // console.log("BOOKS:");
                                // console.log(book);
                                showFinishedReadingBooks(book);       
                        });   
            }
        
            const getWantToReadBooks = async() =>{
                        await getDocs(collection(db, "Users", uid, "Want to Read")).then((querySnapshot)=>{
                            const book = querySnapshot.docs.map((doc)=>({
                                ...doc?.data()
                            }));
                                // console.log("BOOKS:");
                                // console.log(book);
                                showWantToReadBooks(book);       
                        });   
            } 
            
            
            const onFollowingButton  = () =>{
                navigate("/"+readerDisplayname.toLowerCase().replace(/ /g,"")+'/following',{
                    state:{
                        username: readerDisplayname,
                        id: uid
                    }
                });
            }

    const onFollowersButton  = () =>{
                navigate("/"+readerDisplayname.toLowerCase().replace(/ /g,"")+'/followers',{
                    state:{
                        username: readerDisplayname,
                        id: uid
                    }
                });
            }

            const messageReader = (person)=>{
                navigate("/inbox/"+person.displayName.toLowerCase().replace(/ /g,""),{
                    state:{
                        id: person.uid
                    }
                })
            }

            const checkFollowing = async () =>{
                try{
        
                    const firestore = getFirestore();
                    const following = await getDoc(doc(firestore,"Users",user?.uid,"Following",uid));
                    // console.log(member);
                    if(following.data()){
                        // console.log(following.data());
                        console.log("hi uid 1");
                        setAsFollowingReader(!isFollowingReader);
                    }else{
                        console.log("hi uid 2");
                        setAsFollowingReader(isFollowingReader);
                    }
                }catch(e){
                        console.log("hello 3");
                        console.log(e);
                        // console.log(uid);
                        // console.log(readerDisplayname);
                    }
        
            }
        
            const fetchUser = async()=>{
                if(!readerDisplayname) return;
                setLoading(true);
        
                try{
                    const userRef = collection(db, 'Users');
                    const q = query(userRef, where('displayName','==', readerDisplayname));
                    const querySnapshot = await getDocs(q);
                    // console.log("hollistic");
        
        
                    if(querySnapshot.empty){
                        // console.log("something wrong")
                        setUid(null);
                        setEmail(null);
                        setError('No user found!')
                    }else{
                        // get first matching user ID
                        // console.log("hi poo");
                        const userDoc = querySnapshot.docs[0];
                        // console.log(userDoc.id);
                        
                        // console.log(userDoc.data());
                        // setUserInfo(userDoc.data()); 
                        // console.log(userDoc.data().uid);
                        
                        // console.log(userDoc.data().email);
                        setUid(userDoc.id);
                        showUserInfo(userDoc.data());
                        // console.log(uid);
                        // console.log(userInfo);
                        setEmail(userDoc.data().email);

                        // get number of followers count
                        const follower_c = collection(db, "Users", userDoc.id, "Followers");
                        const follower_count = await getCountFromServer(follower_c);
                        countFollowers(follower_count.data().count);

                        // get number of followings count
                        const following_c = collection(db, "Users", userDoc.id, "Following");
                        const following_count = await getCountFromServer(following_c);
                        countFollowing(following_count.data().count);


                        const aboutAndExperienceInformation =  await getDoc(doc(db, "Users", userDoc.id));
                        try{
                        if(aboutAndExperienceInformation.data()?.photoURL){
                            setPhotoURL(aboutAndExperienceInformation.data().photoURL);
                        }
                        if(aboutAndExperienceInformation.data()?.about){
                        showAboutText(aboutAndExperienceInformation.data().about);
                        }

                        if(aboutAndExperienceInformation.data()?.experience?.professional_background){
                            showProfBackgroundText(aboutAndExperienceInformation.data().experience.professional_background);
                        }

                        if(aboutAndExperienceInformation.data()?.experience?.company_desc){
                            showCompanyDescriptionText(aboutAndExperienceInformation.data().experience.company_desc);
                        }

                        if(aboutAndExperienceInformation.data()?.experience?.company_role){
                            showRoleInCompany(aboutAndExperienceInformation.data().experience.company_role);
                        }

                        if(aboutAndExperienceInformation.data()?.experience?.company_years_in){
                            showOwnershipYears(aboutAndExperienceInformation.data().experience.company_years_in);
                        }

                        if(aboutAndExperienceInformation.data()?.experience?.industry){
                            showIndustryText(aboutAndExperienceInformation.data().experience.industry);
                        
                        }
                    }catch(e){

                    }

                    await getDocs(collection(db, "Users", userDoc.id, "Want to Read")).then((querySnapshot)=>{
                        const book = querySnapshot.docs.map((doc)=>({
                            ...doc?.data()
                        }));
                            
                            showWantToReadBooks(book);       
                    }); 


                    await getDocs(collection(db, "Users", userDoc.id, "Currently Reading")).then((querySnapshot)=>{
                        const book = querySnapshot.docs.map((doc)=>({
                            ...doc?.data()
                        }));
        
                            showCurrentlyReadingBooks(book);
                
                    }); 

                    await getDocs(collection(db, "Users", userDoc.id, "Finished Book")).then((querySnapshot)=>{
                        const book = querySnapshot.docs.map((doc)=>({
                            ...doc?.data()
                        }));
                         
                            showFinishedReadingBooks(book);       
                    }); 


                    try{
        
                        // const firestore = getFirestore();
                        const following = await getDoc(doc(db,"Users",user?.uid,"Following",userDoc.id));
                        // console.log(member);
                        if(following.data()){
                            // console.log(following.data());
                            // console.log("hi uid 1");
                            setAsFollowingReader(!isFollowingReader);
                        }else{
                            // console.log("hi uid 2");
                            setAsFollowingReader(isFollowingReader);
                        }
                    }catch(e){
                            // console.log("hello 3");
                            console.log(e);
                            // console.log(uid);
                            // console.log(readerDisplayname);
                        }



                    }
        
                }catch(err){
                    console.log("hello")
                    console.log(err);
                    setError(err.message);
                    setUid(null);
                    setEmail(null);
                    
        
                }finally{
                    setLoading(false);
                }
        
            }
    useEffect(()=>{

        fetchUser();
        // getAboutandExperienceInfo();
        // getFollowers();
        // getFollowing();
        // checkFollowing();
        
    }, [uid])

    useEffect(()=>{
        // console.log(readerDisplayname);
        // fetchUser();
        // getAboutandExperienceInfo();
        // getFollowers();
        // getFollowing();
        // getFinishedReadingBooks();
        // getCurrentlyReadingBooks();
        // getWantToReadBooks();
        
        // checkFollowing();
        


        
    },[followingCount, followersCount])


    return (
        <section className="p-4 bg-gray-50 dark:bg-gray-900">
            {loading && <div>Loading...</div>}
            {error && <div className="text-red-500">{error}</div>}
        {uid && (<div className="max-w-4xl mx-auto">
            <div className="flex flex-col md:flex-row items-start gap-6 mb-8">
                <img src={reader.photoURL? reader.photoURL : photoURL} alt="ProfilePic" className="w-48 h-48 rounded-full object-cover"/>
                {/* <p>Chance: {uid}</p> */}
                {/* <p>Blah: {reader}</p> */}
                {/* <p>{userInfo.uid}</p> */}
                <div className="flex-1">
                    <div className="flex flex-col md:flex-row justify-between items-start">
                        <div>
                            <h1 className="text-3xl font-bold">{reader?.name?reader?.name:""}</h1>
                            <p>@{reader?.displayName?reader?.displayName:""}</p>
                        </div>
                    <div className="flex gap-3">
                        {isFollowingReader? 
                        <button className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={()=>followReader(readerDisplayname, uid, email )}><UserCheck size={20}/>Following</button>
                        :<button className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700" onClick={()=>followReader(readerDisplayname,uid, email )}><UserPlus size={20}/>Follow</button>}
                        
                        {user.uid !== reader.uid? 
                        <button className="flex items-center gap-2 px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 hover:text-gray-900" onClick={()=>messageReader(reader)} ><MessageCircle size={20}/>Message </button>
                        :""}

                    </div>
                    </div>
                    <p className="text-gray-200 mt-4">
                    {aboutText ? aboutText : "No response yet. "}
                    </p>
                    <div className="flex gap-6 mt-4">
                        <div onClick={()=>onFollowersButton()} className="text-center hover:bg-blue-600 hover:text-gray-900">
                        {/* <a 
                        className = "flex items-center gap-2 text-gray-200 hover:text-blue-600" 
                        onClick={()=>onFollowersButton()}>
                            <User size={20}/>
                            <span class="text-sm">
                                {followersCount} Followers
                            </span>
                        </a> */}
                            <div className="font-semibold">{followersCount}</div>
                            <div className="text-sm text-gray-600">Followers</div>
                        </div>
                        <div onClick={()=>onFollowingButton()} className="text-center hover:bg-blue-600 hover:text-gray-900">
                            <div className="font-semibold">{followingCount}</div>
                            <div className="text-sm text-gray-600">Following</div>
                        {/* <a 
                        className = "flex items-center gap-2 text-gray-200 hover:text-blue-600" 
                        onClick={()=>onFollowingButton()}>
                            <User size={20}/>
                            <span class="text-sm">
                                {followingCount} Following
                            </span>
                        </a> */}
                        </div>
                        <div className="text-center hover:bg-blue-600 hover:text-gray-900">
                            <div className="font-semibold">{FinishedReadingBooks?.length}</div>
                            <div className="text-sm text-gray-600">Books Read</div>
                        </div>

                        <div className="text-center hover:bg-blue-600 hover:text-gray-900">
                            <div className="font-semibold">0</div>
                            <div className="text-sm text-gray-600">Reviews</div>
                        </div>
                        
                    </div>
                    

                    
                </div>        
            </div>
            
            {/* <h2 className="text-2xl font-bold">Bio</h2>
            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    About Me
                </h2>
                <p className="text-gray-700 leading-relaxed">
                    {aboutText ? aboutText : "No response yet. "}
                </p>
            </div> */}

            <h2 className="text-2xl font-bold">Experience</h2>
            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    Professional Background
                </h2>
                <p className="text-gray-700 leading-relaxed">{profBackgroundText ? profBackgroundText : "No response yet."}</p>
            </div>

            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    Company Description
                </h2>
                <p className="text-gray-700 leading-relaxed">{companyDescriptionText ? companyDescriptionText : "No response yet."}</p>
            </div>


            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    Your role in that company
                </h2>
                <p className="text-gray-700 leading-relaxed">{roleInCompanyText ? roleInCompanyText : "No response yet."}</p>
            </div>

            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    Years of Ownership Experience
                </h2>
                <p className="text-gray-700 leading-relaxed">{ownershipYears ? ownershipYears : "No response yet."}</p>
            </div>

            <div className="mb-8 bg-indigo-300 p-6 rounded-lg">
                <h2 className="text-gray-900 text-xl font-bold mb-3 flex items-center">
                    Industry
                </h2>
                <p className="text-gray-700 leading-relaxed">{industryText ? industryText : "No response yet."}</p>
            </div>
         

           

            {/* <h1 className="text-2xl font-bold">Library</h1> */}
            <hr className="mt-8 mb-8"/>
            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
                    <BookOpen size={20}/>
                    Currently Reading
                </h2>
                <div className="flex flex-col items-start gap-6">
                    {CurrentlyReadingBooks?.slice(0,4).map((book,idx)=>(  
                        <div className="flex items-start gap-4">    
                            <img className="w-20 h-30 object-cover rounded-lg shadow-md" src={book.bookImage? book.bookImage: ""} />
                            <div>
                            <p className="text-sm font-medium">{book.bookTitle}</p>
                            <p className="text-xs text-gray-600">By Author</p>
                            </div> 
                        </div>
                        
                            ))  
                    }
                </div>

            </div>
            <div>
                <h2 className="text-xl font-semibold mb-4 flex items-center gap-2">
                    <Clock size={20}/>
                        Want To Read
                </h2>
                <div className="flex flex-col items-start gap-6">
                    {WantToReadBooks?.slice(0,4).map((book,idx)=>(  
                        <div className="flex items-start gap-4">    
                            <img className="w-20 h-30 object-cover rounded-lg shadow-md" src={book.bookImage? book.bookImage: ""} />
                            <div>
                                <p className="text-sm font-medium">{book.bookTitle}</p>
                                <p className="text-xs text-gray-600">By Author</p>
                            </div> 
                        </div>
                        
                            ))  
                    }
                </div>

            </div>
        </div>) }
    </section>

    );
};
export default ReaderProfile;