import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, DocumentSnapshot, getDocs, getCountFromServer, getFirestore, updateDoc} from 'firebase/firestore';
import {auth_, uploadProfilePic} from '../../firebase';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../../components/Popup/Popup";
import { Workflow } from "lucide-react";


const GettingStartedEnterExperienceScreen = () =>{

    const [profBackgroundText, saveProfBackgroundText] = useState("");
    const [companyDescriptionText, saveCompanyDescriptionText] = useState("");
    const [userIntentionDescriptionText, saveUserIntentionDescriptionText] = useState("");


    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const [isEmpty, checkEmpty] = useState(false);

    const navigate = useNavigate();

    const onPrevButton  = () =>{
        navigate('/getstarted_experience');
    }

    const onNextButton  = () =>{
        navigate('/getstarted_profilepic');
    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    const emptyNotification = () =>{
        checkEmpty(!isEmpty);   
    }


    const [user, setUser] = useState("");
 
    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
              
                
    
            }
        });
    }


    const professionalBackgroundResponses =(e)=>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;

        if(profBackgroundText.trim()===""||
        companyDescriptionText.trim()===""||
        e.target.your_company_role.value.trim()===""||
        e.target.your_industry.value.trim()===""||
        e.target.years_in_company.value.trim()===""||
        userIntentionDescriptionText.trim()===""){
            
            
            emptyNotification();
            

        }else{

            try {

                updateDoc(doc(firestore,"Users",user.uid),{

                    experience:{
                    
                            professional_background: profBackgroundText,
                            company_desc: companyDescriptionText,
                            company_role: e.target.your_company_role.value,
                            company_years_in: e.target.years_in_company.value,
                            industry: e.target.your_industry.value
                    },

                    user_intentions: userIntentionDescriptionText

                        
                    }
                );

                successNotification();
                navigate('/getstarted_profilepic');
                
            } catch (error) {
                // console.log("prof_Experience");
                // console.log(error);
                errorNotification();

                
            }
        }

    }

 

    

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 random-lg shadow-sm">


            {/* if popup is open dont open */}
            {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your image was uploaded!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    title = "Error!"
                    content = "There was an issue uploading your image. Try again!"
                />
                </div>}


            <div className="flex gap-2 mb-2">
                <Workflow className="w-24 h-24 text-blue-600"/>
                <div>
                    <h1 className="text-3xl font-bold text-gray-300">Getting Started!</h1>
                    <p>Tell Us About Your Experience</p>
                </div>
            </div>


            <form class="space-y-4 md:space-y-6 overflow-auto" onSubmit={professionalBackgroundResponses}>
            {/* <div className="space-y-3"> */}
            <div className='bg-gray-800 rounded-lg p-4'>
                <label>What is your professional background</label>
                <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={profBackgroundText}
                    onChange = {e => saveProfBackgroundText(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
            
            <div>
                <label>Your company</label>

            <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={companyDescriptionText}
                    onChange = {e => saveCompanyDescriptionText(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>                
            </div>
            <div>
                <label>Your role in that company</label>
                <input 
                name="your_company_role"
                type="text"           
                className="w-full border rounded px-4 py-2 mb-4"></input>
            </div>
            <div>
                <label className="prof_bg_inputs">How many years of ownership experience do you have</label>
                <input
                name="years_in_company"
                type="number" 
                className="w-full border rounded px-4 py-2 mb-4"></input>
            </div>
            <div>
                <label>What industry are you in</label>
                <input 
                name="your_industry"
                type="text"
                className="w-full border rounded px-4 py-2 mb-4"></input>
            </div>
            <div>
                <label>What do you hope to achieve or find in this app?</label>

            <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    value={userIntentionDescriptionText}
                    onChange = {e => saveUserIntentionDescriptionText(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>                
            </div>
          
            <div className="flex justify-end gap-2">
                <button className="px-4 py-2 bg-white text-gray-900 rounded hover:bg-gray-600" onClick={onPrevButton}>Prev</button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Next</button>
            </div>
            </div>
            </form>


            
            {/* <button onClick={onNextButton}>Next</button> */}

        
        </div>
        </section>

    );
};
export default GettingStartedEnterExperienceScreen;