import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, DocumentSnapshot, getDocs, getDoc, getCountFromServer, getFirestore, updateDoc, query, where} from 'firebase/firestore';
import {auth_, db, uploadProfilePic} from '../../firebase';
import {getAuth, onAuthStateChanged, signOut, updateProfile} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../../components/Popup/Popup";
import { Award } from "lucide-react";


const GettingStartedBasicInfo = () =>{

    const [aboutText, saveAboutText] = useState("");
    const [userName, saveUsername] = useState("");
    const [name, saveName] = useState("");


    const [isDuplicate, setDuplicateMessage] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isWhiteSpace, checkSpaces] = useState(false);

    const [isEmpty, checkEmpty] = useState(false);






    const navigate = useNavigate();

    const onPrevButton  = () =>{
        navigate('/finishedreading');
    }

    const onNextButton  = () =>{
        navigate('/getstarted_experience');
    }


    const duplicateNotification = () =>{
        setDuplicateMessage(!isDuplicate);   
    }
    
    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    const whiteSpaceNotification = () =>{
        checkSpaces(!isWhiteSpace);   
    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const emptyNotification = () =>{
        checkEmpty(!isEmpty);   
    }

    const [user, setUser] = useState("");
   
    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
                saveName(userInfo?.displayName)
        
                
    
            }
        });
    }


    const getUniqueName = async(uniqueName) =>{

        const usernames = await getDocs(query(collection(db, "Users"), where("displayName", "==", uniqueName)));
        // const usernames = findName.docs;
        // console.log("USERNAME");
        // console.log(usernames.docs.length>0);
        return usernames.docs.length>0
      
    }




    const basicInforResponses = async (e)=>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;
        // console.log(aboutText);
        if(aboutText.trim()==="" || name.trim()==="" || userName.trim()===""){
            emptyNotification();

        }else if(/\s/g.test(userName)){
            whiteSpaceNotification();

        }else if(await getUniqueName(userName.toLowerCase().replace(/ /g,""))){
            duplicateNotification();

        }else{

            try {

            
                updateDoc(doc(firestore,"Users",user.uid),{
                    about: aboutText
                });

                updateDoc(doc(firestore,"Users",user.uid),{
                    displayName: userName.toLowerCase().replace(/ /g,"")
                });

                updateProfile(user, {displayName: userName.toLowerCase().replace(/ /g,"")});


                updateDoc(doc(firestore,"Users",user.uid),{
                    name: name
                });

                successNotification();

                navigate('/getstarted_experience');

                // setDoc(doc(firestore,"Users",user.uid),{
                //     accountCreated: date,
                //     displayName: e.target.username.value,
                //     email: email,
                //     firstName: e.target.firstName.value,
                //     lastName: e.target.lastName.value,
                //     website: "used_web_version_not_app",
                //     uid: user.uid,
                    
                // });
                
            } catch (error) {
                console.log(error);
                errorNotification();
                
                }
        }

    }    


    

    

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 random-lg shadow-sm">
             


                {/* if popup is open dont open */}
                {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your information was added successfully!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    title = "Error!"
                    content = "There was an issue saving your information. Try again!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isDuplicate && 
                    <div>
                    <Popup
                    handleClose = {duplicateNotification}
                    title = "Username already exists!"
                    content = "The username you entered already exist. Enter a new one!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isWhiteSpace && 
                    <div>
                    <Popup
                    handleClose = {whiteSpaceNotification}
                    title = "Usernames can not have space!"
                    content = "Usernames can not have spaces. Enter a valid username."
                />
                </div>}


                 {/* if popup is open dont open */}
                 {isEmpty && 
                    <div>
                    <Popup
                    handleClose = {emptyNotification}
                    title = "Field can not be empty!"
                    content = "All fields must be entered."
                />
                </div>}

                <div className="flex gap-2 mb-2">
                <Award className="w-24 h-24 text-blue-600"/>
                <div>
                    <h1 className="text-3xl font-bold text-gray-300">Getting Started!</h1>
                    <p>Create Your Profile!</p>
                </div>
            </div>




           
            {/* <button>Edit</button> */}
            <form class="space-y-4 md:space-y-6 overflow-auto" onSubmit={basicInforResponses}>
            {/* <div className="space-y-3"> */}
                <div className='bg-gray-800 rounded-lg p-4'>

                <h3>Tell Us Your Name</h3>
                <input
                    type="text"
                    placeholder="Enter your full name"
                    value={name}
                    onChange = {e => saveName(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4"/>

                <h3>Choose A Username</h3>
                <input
                    type="text"
                    placeholder="Enter your username"
                    value={userName}
                    onChange = {e => saveUsername(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4"/>

                    <h3>About</h3>
                    <textarea
                    rows={5} 
                    cols={35}
                    type="text"
                    placeholder="Tell us about yourself"
                    value={aboutText}
                    onChange = {e => saveAboutText(e.target.value)}
                    className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
                {/* </div> */}
            {/* </div> */}
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Next</button>
            </div>
            </form>


            {/* <button onClick={onNextButton}>Next</button> */}
        
        </div>
        </section>

    );
};
export default GettingStartedBasicInfo;