import React from 'react';

const MoveBookPopup = props =>{
    return (
        <div className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4'>
            <div className='bg-white rounded-lg shadow-xl max-w-md w-full transform transition-all'>
                <div className='p-6'>
                    <div className='flex justify-between items-center mb-4'>
                        <h2 className='text-xl font-semibold text-gray-900'>{props.title}</h2>
                        <button 
                            onClick={props.handleClose}
                            className='text-gray-400 hover:text-gray-600 transition-colors'>
                                <svg>
                                    <path></path>
                                </svg>

                            </button>
                            

                        </div>
                    <div>
                        {props.content}
                    </div>

                </div>
                {/* <button className='popup-btn-close' onClick={props.handleClose}>x</button> */}
                
                
                <button
                className='px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors' 
                onClick={props.handleClose}>Cancel</button>
            </div>
        </div>
    )

}

export default MoveBookPopup;