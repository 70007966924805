import React from 'react';
import {auth_} from '../../firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
const Message = ({message}) =>{
    const [user] = useAuthState(auth_);

    return(
        <div className={`flex items-start gap-2.5 ${message.uid === user.uid ? "flex-row-reverse":""}`} >
            <img 
                className='w-8 h-8 rounded-full flex items-center justify-center'
                src={message.avatar}
                alt="user avatar"
            />
            <div className={`
            max-w-[80%] px-4 py-2 rounded-lg ${message.uid === user.uid ? 'bg-blue-600 text-white rounded-br-none':
                'bg-gray-200 text-gray-900 rounded-bl-none'}`}>
                <p className='user-name'>{message.name}</p>
                <p className='user-message'>{message.text}</p>
                
            </div>
        </div>
       

    );
};

export default Message;