import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams} from 'react-router-dom';
import {doc, setDoc, collection, DocumentSnapshot, getDocs, QuerySnapshot, getFirestore, getDoc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import {PlusCircle} from 'lucide-react';



const GroupHomeScreen = () =>{
    const navigate = useNavigate();

    const {clubNameId} = useParams();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const date = new Date;
    
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const [group_Description, saveGroupDescription] = useState("");

    const [isGroupPrivate, setIsGroupPrivate] = useState(false);

    const [Groups, showGroups] = useState([]);
    const [groupCreationForm, showGroupCreationForm] = useState(false);



    const getGroups = async() =>{
        await getDocs(collection(db, "Groups")).then((querySnapshot)=>{
            const groups = querySnapshot.docs.map((doc)=>({
                ...doc?.data()
            }));

            if(groups.name!==""){
                showGroups(groups);
            }
            
            

        });   


    }
    

 
    const onViewGroupButton = (group_name)=>{

        navigate(`/group/${group_name}`);

    }



    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

  


    const onSubmitNewGroupName = async (event) =>{
        event.preventDefault();
        try{



            const firestore = getFirestore();
            // console.log(event?.target?.bc_name?.value);
            const name = await getDoc(doc(firestore,"Groups",event?.target?.group_name?.value));
            // console.log(name);
            if(event.target.group_name.value===null|| event?.target?.group_name?.value.trim()===""|| group_Description.trim()===""){
                emptyNotification();
            }else if(name.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
                setDoc(doc(firestore,"Groups",event?.target?.group_name.value),{
                    name: event?.target?.group_name?.value,
                    uid: event?.target?.group_name?.value.replace(/ /g,"").toLowerCase(),
                    creator: user?.displayName,
                    creator_uid: user?.uid,
                    creator_email: user?.email,
                    createdAt: date,
                    isPrivate: isGroupPrivate,
                    description: group_Description
                });
                // console.log("Document Written with ID: ",dashboard.id )
                successNotification();

            }
  
        }catch(event){
            warningNotification();
            // console.log("Error Adding Document: ",event );

        }


    }


    

    useEffect(()=>{
        getGroups();
    },[])


    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div class="max-w-4xl mx-auto">
        <div class="flex flex-col md:flex-row justify-between items-center">
                <div>
                    <h1 class="text-3xl font-bold">Groups</h1>
                    <p class="text-gray-600 mt-2">Join the conversation in our groups!</p>
                </div>
            
            {/* <button
            class="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors" 
            onClick={()=>onCreateNewBookClubButton()}> */}
            <button
            class="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors" 
            onClick={()=>showGroupCreationForm(!groupCreationForm)}>
                <PlusCircle class="w-5 h-5"/>
                Create A Group
            </button>
            </div>

            {groupCreationForm&&(
                <div className="mt-2 bg-gray-700 rounded-lg shadow-lg p-6 mb-6">
                    <h2 className="text-xl font-bold mb-4">Create A Community</h2>
                    <form onSubmit={onSubmitNewGroupName}>
                            <input  
                            className="w-full border rounded px-4 py-2 mb-4" 
                            placeholder="Enter Group Name" 
                            type="text" 
                            name="group_name" ></input>
                          
                            
                        <textarea
                            rows={5} 
                            cols={35}
                            type="text"
                            placeholder="Enter Group Description"
                            value={group_Description}
                            onChange = {e => saveGroupDescription(e.target.value)}
                            className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
                       
                        <label><input
                                type="checkbox"
                                checked = {isGroupPrivate}
                                onChange = {(e)=>setIsGroupPrivate(e.target.checked)}
                            />Make Group Private</label>

                        <div className="flex justify-end gap-2">
                            <button
                                onClick={()=>showGroupCreationForm(false)} 
                                
                                className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">
                                    Cancel
                            </button>

                            <button
                            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" 
                            type="submit">
                                Submit
                            </button>
                        </div>
                
                    </form>
                </div>
            )}

            <div className="gap-6 grid grid-cols-1 ">
                    {
                        Groups?.map((group,idx)=>(
                            <div class="bg-white border border-white rounded-lg hover:bg-gray-900 hover:text-white" onClick={()=>onViewGroupButton(group?.name)}>
                                <div class="p-6">
                            <h3 class="text-xl font-semibold mb-2 text-bg-gray-900" key={idx}>{group?.name}</h3>
                            <div class="flex items-center gap-4 text-sm text-gray-500 ">
                            <button class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                            onClick={()=>onViewGroupButton(group?.name)}>
                                View
                                </button>
                            </div>
                            </div>
                            </div>
                        ))
                    }
                    

        </div>
        </div>
        </section>

    );
};
export default GroupHomeScreen;