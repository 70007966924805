import {faBars} from  '@fortawesome/free-solid-svg-icons';
import {NavLink as Link } from "react-router-dom";
import styled from "styled-components";

export const NavMenu = styled.div `
    display: flex;
    align-items: center;
    margin-right: -24px;
    background-color: #ffffff;

    @media screen and (max-width: 768px){
        // display: none;

    }
`;

export const Hamburger = styled.button `
    display: none;
    padding: 10px;
    margin: 2px;
    background-color: #607d8b;
    @media screen and (max-width: 768px){
        display: block;
        // display: none;
        // position: absolute;

        // &:focus + div{
        //     display: flex;
        //     display: block; 
        //     background-color: red;
        //     position: absolute;
        //     margin-top: 5rem;
        //     flex-direction: column;
        // }
    }
`;

export const Nav = styled.nav `
    background: #607d8b;
    height: 85px;
    // width: 100%;
    // width: 1000px;
    display: flex;
    justify-content: space-between;
    padding: 0.2rem calc((100vw - 1000px)/2);
    z-index: 12;
    // position: fixed;

    @media screen and (max-width: 768px){
        // flex-direction: column;
        // background-color: green;
        // position: relative;
        // display: none;
    }
`;

export const NavLink = styled(Link) `
    background: #607d8b;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    margin: 1rem;
    height: 100%;
    cursor: pointer;
    &.active{
        color: #ffffff
    }

    @media screen and (max-width: 768px){
        flex-direction: column;
        display: block;

        // background-color: green;
        // position: relative;
    }
`;

export const Bars = styled(faBars) `
    background: #607d8b;
    display: none;
    @media screen and (max-width: 768px){
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 75%);
        font-size: 1.8rem;
        cursor: pointer;

    }
`;

