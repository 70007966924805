import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {collection, DocumentSnapshot, setDoc, getDocs, getDoc, setDocs,QuerySnapshot, doc, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";
import Popup from "../../components/Popup/Popup";




const CreateDiscussionBoardQuestion = () =>{
    const navigate = useNavigate();
    const location = useLocation();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;

    const [DiscussionBoards, showDiscussionBoards] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);


    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
        navigate('/discussionboard/'+location?.state?.id.toLowerCase().replace(/ /g,""),
        {state: {
            id: location?.state?.id
            }
        });
    }

    const getDiscussionBoards = async() =>{
        await getDocs(collection(db, "DiscussionBoards")).then((querySnapshot)=>{
            const discussionboards = querySnapshot.docs.map((doc)=>({
                ...doc.data(), id:doc.id, name: doc.displayName
            }));

            if(discussionboards.name!==""){
                showDiscussionBoards(discussionboards);
            }
            
            

        });   


    }


    const onSubmitQuestion = async (event) =>{
        event.preventDefault();
        let date = new Date();
        try{

            const firestore = getFirestore();
            const question = await getDoc(doc(firestore,"DiscussionBoards",location?.state?.id, "Questions", event?.target?.db_question?.value));

            if(event?.target?.db_name?.value===null|| event?.target?.db_name?.value.trim()===""){
                emptyNotification();
            }else if(question.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
            setDoc(doc(firestore,"DiscussionBoards",location?.state?.id, "Questions", event?.target?.db_question?.value),{
                author: user?.displayName,
                author_uid: user?.uid,
                author_email: user?.email,
                dateAsked: date.toString(),
                details: event?.target?.db_question_details?.value,
                question: event?.target?.db_question?.value,
                questionID: event?.target?.db_question?.value.replace(/ /g,"").toLowerCase()

            });
            successNotification();
            onSubmitButton();
            // console.log("Document Written with ID: ",dashboard.id )
        }

        }catch(event){
            console.log("Error Adding Question: ",event );
            warningNotification();

        }


    }

    useEffect(()=>{
        getDiscussionBoards();
    },[])


    return (
        <div>
            
            <h2>Ask A Question in {location.state.id} Discussion Board</h2>

                        {/* if popup is open dont open */}
                        {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your question has been submitted!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue submitting your question!"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Please enter question!"
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                  title = "Error"
                content = "Question already asked!"
            />} 

            <form onSubmit={onSubmitQuestion}>
                <label>
                    Question:
                    <textarea className="textarea-format" type="text" name="db_question"></textarea>

                </label>
                <br/>

                <label>
                    Details (optional):
                    <textarea className="textarea-format" type="text" name="db_question_details"></textarea>

                </label>

                <br/>

                <p>Language: English</p>
                <br/>
             
                <button type="submit" >Submit</button>
            </form>

        </div>

    );
};
export default CreateDiscussionBoardQuestion;