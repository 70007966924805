import React, { useState,useEffect } from "react";
import { useNavigate, useParams, useLocation} from 'react-router-dom';
import { auth_, db } from "../firebase";
import {collection, setDoc, doc, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import { NavMenu } from "../components/Navbar/NavbarElements";
import {Link } from "react-router-dom";

import { getAnalytics, logEvent } from 'firebase/analytics';


const InboxHomeScreen = () =>{

    const analytics = getAnalytics();

    logEvent(analytics, "web_inboxHomeScreen");


    let location = useLocation();
    let navigate = useNavigate();
    let [messages, setMessages] = useState([]);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const user = auth_.currentUser;



    const fetchMessages = async () =>{
        await getDocs(collection(db, "Users", user.uid, "Inbox")).then((querySnapshot)=>{
        let messages = querySnapshot.docs.map((doc)=>({
                      ...doc.data()
                  }));

        // console.log(messages);
        setMessages(messages);
            });  
            }

        
      const onMessage = (nm, uniqueID)=>{
        navigate("/inbox/"+nm.toLowerCase().replace(/ /g,""),{
            state:{
                id: uniqueID
            }
        })
      } 
      
      
      const toReaders = ()=>{
        navigate("/readers");
      }
    
 


useEffect(()=>{
    fetchMessages();

},[]);

    // if(!location?.state?.notificationsRead){
    //     console.log("hey")
    //     console.log(location?.state?.notificationsRead);
    //     return <h1>No notifications yet..</h1>

    // }else{
        return (
            <div className="p-4 h-screen bg-gray-900 flex flex-col">
                {/* <p>great</p> */}
                <div className="border-b px-6 py-4">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-4">
                            <div>
                            <h1 className="text-xl font-semibold">Messages</h1>
                            <p>Connect with more people in the platform. Explore new readers <Link  className="underline" onClick={()=>toReaders()}>here</Link>!</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                
                <div className="mt-2 flex-1 overflow-y-auto rounded-lg border">
                     {messages?.map(
                        (message) => 
                            <div className="flex items-center px-6 py-4 border-b cursor-pointer hover:bg-gray-700">
                            <div className="flex-1 min-w-0 ml-4" onClick={()=>onMessage(message.name, message.uid)}>
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center space-x-2">
                                        <span className="text-gray-300">
                                            {message.name}
                                        </span>

                                    </div>
                                
                                </div>  
                            </div>
                            </div>
                        
                    )} 
                    </div>
                    


               </div>
               </div>
         

        );
}
// }

export default InboxHomeScreen;