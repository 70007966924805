import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation} from 'react-router-dom';
import {collection,getDoc, doc,  DocumentSnapshot, getDocs, QuerySnapshot, setDoc, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { useEffect } from "react";




const DiscussionBoardQuestionsScreen = () =>{
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();
    const user = auth_.currentUser;
    const location = useLocation();

    

    const [DiscussionBoardQuestions, showDiscussionBoardQuestions] = useState([]);
    const [author, getAuthorInfo] = useState([]);
    const [sortBy, setSortBy] = useState("latest");
    const [searchTerm, setSearchTerm] = useState("");
    const [showDiscussionBoardQuestionForm, setShowDiscussionBoardQuestionForm] = useState(false);


    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const questionArray = [];
    const authorArray = [];

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);


    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }
    const onSubmitButton  = () =>{
        navigate('/discussionboard/'+location?.state?.id.toLowerCase().replace(/ /g,""),
        {state: {
            id: location?.state?.id
            }
        });
    }


    const onViewButton  = (club, question, details) =>{
        navigate('/discussionboardquestion/'+club.toLowerCase().replace(/ /g,"")+"/"+question.toLowerCase().replace(/ /g,"") ,{
            state:{
                user_question: question, 
                club_name: club,
                question_details: details
            }

        });
    }

    const onAskQuestionButton  = (db_name) =>{
        navigate('/creatediscussionboardquestion',{
            state:{
                id: db_name
            }
        });
    }




    const getDiscussionBoardQuestions = async() =>{

        const dbQuestions = await getDocs(collection(db, "DiscussionBoards",location?.state?.id, "Questions"));
        const questions =  dbQuestions.docs;
        // questionArray.push(questionInfo.data());
        // showDiscussionBoardQuestions(questions.data());

        // console.log(questions.data());



        questions.forEach( async(question) => {
            // const questionInfo =  await getDoc(doc(db, "Users", question.data().author_uid));
            const questionAuthor = await getDoc(doc(db, "Users", question.data().author_uid));


            questionArray.push(question.data());
            showDiscussionBoardQuestions(questionArray);
            // console.log(question.data());


            authorArray.push(questionAuthor.data());
            getAuthorInfo(authorArray);
          });

    }


    const onViewReaderProfileButton  = (reader) =>{
     
        navigate('/profile/'+reader.displayName.toLowerCase().replace(/ /g,''),{
        state: {
            id: reader.uid,
            username: reader.displayName,
            email: reader.email
        }
    });
    }

    const onSubmitQuestion = async (event) =>{
        event.preventDefault();
        let date = new Date();
        try{

            const firestore = getFirestore();
            const question = await getDoc(doc(firestore,"DiscussionBoards",location?.state?.id, "Questions", event?.target?.db_question?.value));

            if(event?.target?.db_name?.value===null|| event?.target?.db_name?.value.trim()===""){
                emptyNotification();
            }else if(question.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
            setDoc(doc(firestore,"DiscussionBoards",location?.state?.id, "Questions", event?.target?.db_question?.value),{
                author: user?.displayName,
                author_uid: user?.uid,
                author_email: user?.email,
                dateAsked: date.toString(),
                details: event?.target?.db_question_details?.value,
                question: event?.target?.db_question?.value,
                questionID: event?.target?.db_question?.value.replace(/ /g,"").toLowerCase()

            });
            successNotification();
            onSubmitButton();
            // console.log("Document Written with ID: ",dashboard.id )
        }

        }catch(event){
            console.log("Error Adding Question: ",event );
            warningNotification();

        }


    }
    

    useEffect(()=>{
        getDiscussionBoardQuestions();
    },[DiscussionBoardQuestions])


    return (
        <section className="bg-gray-900">
        <div className="w-full max-w-4xl mx-auto bg-gray-900 min-h-screen">
            <div className="px-6">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold mb-2">{location?.state?.id}</h1>
                    <p></p>
                </div>

                <div className="flex justify-between items-center mb-6">
                    <div className="flex flex-col md:flex-row gap-4 ">
                        {/* <button
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                        onClick={()=>onAskQuestionButton(location?.state?.id)}>
                            Ask A Question
                        </button> */}
                        <div>
                         <button
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                        onClick={()=>setShowDiscussionBoardQuestionForm(!showDiscussionBoardQuestionForm)}>
                            Ask A Question
                        </button>
                        </div>
                        
                        <div>
                        <select
                        value={sortBy}
                        onChange={(e)=>setSortBy(e.target.value)}
                        className="border rounded px-3 py-2 bg-gray-700">
                            <option value="latest">Sort by: latest</option>
                        </select>
                        </div>
                        <div className="relative">
                        <input
                        type="text"
                        placeholder="Search topics.."
                        value={searchTerm}
                        onChange={(e)=>setSearchTerm(e.target.value)}
                        className="border rounded px-4 py-2 w-64"/>
</div>
                    </div>
                    </div>

                        {showDiscussionBoardQuestionForm&&(
                            <div className="bg-gray-700 rounded-lg shadow-lg p-6 mb-6">
                                <h2 className="text-xl font-bold mb-4">Create A Community</h2>
                                <form onSubmit={onSubmitQuestion}>

                                    <textarea
                                    placeholder="Enter question.."
                                    className="w-full border rounded px-4 py-2 mb-4 h-32" 
                                    type="text" 
                                    name="db_question"></textarea>
                                    <textarea 
                                    placeholder="Enter more details (optional)"
                                    className="w-full border rounded px-4 py-2 mb-4 h-32" 
                                    type="text" 
                                    name="db_question_details"></textarea>

              
                                    <div className="flex justify-end gap-2">
                                        <button
                                        onClick={()=>setShowDiscussionBoardQuestionForm(false)} 
                                        
                                        className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">Cancel</button>
                                        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Submit</button>
                                    </div>
                                
                                </form>
                            </div>
                        )}
                        
                        
                    
                    
                    
            
            
              <div className="bg-gray-700 rounded-lg shadow overflow:hidden"> 
                    {
                        DiscussionBoardQuestions?.map((question, idx) => (
                            <div 
                            key={idx} 
                            className={`border-b last:border-b-0 hover:bg-gray-900`}
                            onClick={()=>onViewButton(location?.state?.id, question?.question, question?.details)} >
                                <div className="p-4">
                                <div className="flex justify-between items-start mb-2">
                                    <h3
                                    className="text-lg font-semibold inline" 
                                    >{question?.question}</h3>
                                </div>
                                <span className="text-sm text-gray-500">{question?.dateAsked}</span>
                                <div className="flex items-center gap-6 text-sm text-gray-600">
                                <span>By {author[idx].displayName}</span>
                                    {/* <span 
                                    className="hover:underline cursor-pointer"
                                    onClick={()=>onViewReaderProfileButton(author[idx])}>By {author[idx].displayName}</span> */}

                                </div>
                        
                                </div>
                            </div>
                        
                        ))
                    }
                    </div> 
            </div>
        </div>
        </section>

    );
};
export default DiscussionBoardQuestionsScreen;