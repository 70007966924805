import React, {useState, useRef} from 'react';
import logo from '../images/logo.svg';
import {createUserWithEmailAndPassword, signInWithPopup, sendEmailVerification} from 'firebase/auth';
import { auth_,googleProvider } from '../firebase';
import { NavLink, useNavigate} from 'react-router-dom';
import Popup from '../components/Popup/Popup';
import {collection, DocumentSnapshot, getDocs, getDoc, setDocs,QuerySnapshot, addDoc, doc, setDoc, getFirestore} from 'firebase/firestore';
import {Link } from "react-router-dom";



const Register = () =>{
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setConfirmedPassword] = useState('');

    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isEmptyMessage, setIsEmptyMessage] = useState(false);



    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();

    const [type, setType] = useState("password");
    const [checked, setChecked]  = useState(false);


    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const handleViewPasswordToggle = () =>{
        setChecked(!checked);

        if(type === "password"){
            setType("text");
        }else{
            setType("password");
        }
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }


    const emptyNotification = () =>{
        // console.log("warning message");
        setIsEmptyMessage(!isEmptyMessage);   
    }


    
    


    const signInWithGoogle = async() =>{
        let date = new Date();
        date = date.toString();
        
        try{
           

            await window.RecaptchaVerifier;
            await signInWithPopup(auth_, googleProvider).then((userCredential)=>{
                // signed in
                const user = userCredential.user;
                const firestore = getFirestore();

                setDoc(doc(firestore,"Users",user.uid),{
                    accountCreated: date,
                    name: user.displayName,
                    displayName: user.displayName.replace(/ /g,'').toLowerCase(),
                    email: user.email,
                    registration_method: "Google Button",
                    website: "used_web_version_not_app",
                    uid: user.uid,
                    
                });
                successNotification();
                navigate('/getstarted_basic_info');
                // console.log("hi user");
                // console.log(user);
            }).catch((error)=>{
                const errorCode = error.code;
                const errorMessage = error.message;
                // console.log(errorCode, errorMessage);
    
            });
        }
        catch(error){
            console.log(error)
        }

    }

    const onSubmit = async (e) =>{
        e.preventDefault();
        const firestore = getFirestore();
        const date = new Date;
        console.log(e.target.password);
        if(e.target.password.value !== e.target.password2x.value){
            warningNotification();

        }else if(e.target.email.value.trim()===""){
            emptyNotification();


        }else if(e.target.password.value.trim()===""){
            emptyNotification();
        }else{

        // console.log(email);

        // const emailRegex = /^[^\s@]+]+$/;

        try{

            await createUserWithEmailAndPassword(auth_, email, password).then((userCredential)=>{
                // signed in
                const user = userCredential.user;
                // console.log("user");
                // console.log(user);
                // console.log(e.target.username.value);
                

                // user.sendEmailVerification();

                // user.sendEmailVerification().then(function(){
                //     console.log("")

                // }).catch(function(error){
                //     console.log(error.message);

                // });

                // await sendEmailVerification(user);

                setDoc(doc(firestore,"Users",user.uid),{
                    accountCreated: date,
                    // displayName: e.target.username.value,
                    email: email,
                    // firstName: e.target.firstName.value,
                    // lastName: e.target.lastName.value,
                    website: "used_web_version_not_app",
                    uid: user.uid,
                    
                });
                successNotification();
                navigate('/getstarted_basic_info');
                // navigate('/login');

            });
        }catch(error){
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);

        };
    }

    }
    
    
    return (
   
            <section class="bg-gray-50 dark:bg-gray-900">
                <div class="overflow-auto flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <a href="/" class="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
                        <img class="w-8 h-8 mr-2" src={logo} alt="Entrepreneurship Books App Logo"/>
                        Entreprenership Books App
                    </a>
                    <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:pl-0 dark:bg-gray-800 dark:border-gray-700">
                    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">

                {/* if popup is open dont open */}
                {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Verify your email!"
                    content = "Verification email was sent. Please check your email to verify your account!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "Passwords do not match!"
            />}


            {isEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title = "Error"
                content = "Field can not be blank!!"
            />}
                    <h1 class='text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white'>Register</h1>
                    <form class="space-y-4 md:space-y-6" onSubmit={onSubmit}>
                    {/* <div>
                        
                            <input
                                class = "input_register"
                                id = "first-name"
                                name = "firstName"
                                type = "text"
                                required
                                placeholder='First Name'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        {/* <div>
                           
                            <input
                                class = "input_register"
                                id = "last-name"
                                name = "lastName"
                                type = "text"
                                required
                                placeholder='Last Name'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        {/* <div>
                    
                            <input
                                class = "input_register"
                                id = "username"
                                name = "username"
                                type = "text"
                                required
                                placeholder='Username'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div> */}
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                            <input
                                class= "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                id = "email-address"
                                name = "email"
                                type = "email"
                                required
                                placeholder='Email Address'
                                onChange={(e)=>setEmail(e.target.value)}
                            />
                        </div>
                        <p class="text-sm font-bold text-gray-500 dark:text-gray-400">Please Note: Password must be atleast 6 characters long.</p>
                        <p class="text-sm font-bold text-gray-500 dark:text-gray-400">Include uppercase letters and a mix of numbers and symbols.</p>                        
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Password
                            </label>
                            <input
                                class= "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                id = "password"
                                name = "password"
                                type = {type}
                                required
                                placeholder='Password'
                                onChange={(e)=>setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                Confirm Password
                            </label>
                            <input
                                class= "bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                id = "repassword"
                                name = "password2x"
                                type = {type}
                                required
                                placeholder='Password (6 characters)'
                                onChange={(e)=>setConfirmedPassword(e.target.value)}
                            />
                        </div>
                        <div class="flex items-center justify-between">
                        <div class="flex items-start">
                            <div class="flex items-center h-5">
                                <input 
                                    class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-700 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                                    id ="show-password"
                                    type = "checkbox"
                                    checked = {checked}
                                    onChange = {handleViewPasswordToggle}
                                />
                            </div>
                            <div class="ml-3 text-sm">
                                <label for="show-password" class='text-gray-500 dark:text-gray-300'>Show Password</label>
                            </div>
                        </div>
                        </div>
                        <p class="text-sm font-light text-gray-500 dark:text-gray-400">By continuing, you agree to Entrepreneuship Books App <Link to="/termsofuse">Terms of Use</Link> and <Link to="/privacypolicy">Privacy Notice</Link>.</p>
                        <div>
                            <button type="submit" class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-pimary-800">
                                Register
                            </button>
                        </div>                      
                    </form>
                    <div class="my-6 flex items-center gap-4">
                        <hr class="w-full border-gray-300"/>
                        <p class="text-sm font-light text-gray-500 dark:text-gray-400">or</p>
                        <hr class="w-full border-gray-300"/>
                    </div>
                    <div>
                            
                            <button onClick ={() =>signInWithGoogle()} type="button" class="w-full flex items-center justify-center gap-4 py-2.5 px-4 text-sm tracking-wide text-white border border-gray-300 rounded-md bg-transparent hover:bg-primary-700 ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20px" class="inline" viewBox="0 0 512 512">
                                <path fill="#fbbd00"
                                    d="M120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308H52.823C18.568 144.703 0 198.922 0 256s18.568 111.297 52.823 155.785h86.308v-86.308C126.989 305.13 120 281.367 120 256z"
                                    data-original="#fbbd00" />
                                <path fill="#0f9d58"
                                    d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216C305.044 385.147 281.181 392 256 392z"
                                    data-original="#0f9d58" />
                                <path fill="#31aa52"
                                    d="m139.131 325.477-86.308 86.308a260.085 260.085 0 0 0 22.158 25.235C123.333 485.371 187.62 512 256 512V392c-49.624 0-93.117-26.72-116.869-66.523z"
                                    data-original="#31aa52" />
                                <path fill="#3c79e6"
                                    d="M512 256a258.24 258.24 0 0 0-4.192-46.377l-2.251-12.299H256v120h121.452a135.385 135.385 0 0 1-51.884 55.638l86.216 86.216a260.085 260.085 0 0 0 25.235-22.158C485.371 388.667 512 324.38 512 256z"
                                    data-original="#3c79e6" />
                                <path fill="#cf2d48"
                                    d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606C388.668 26.629 324.381 0 256 0l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z"
                                    data-original="#cf2d48" />
                                <path fill="#eb4132"
                                    d="M256 120V0C187.62 0 123.333 26.629 74.98 74.98a259.849 259.849 0 0 0-22.158 25.235l86.308 86.308C162.883 146.72 206.376 120 256 120z"
                                    data-original="#eb4132" />
                                </svg>
                                Register With Google
                            </button>
                        </div>   
                    <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                        Already have an account? {' '}
                        <NavLink class="font-medium text-primary-600 hover:underline dark:text-primary-500" to="/login">
                            Login
                        </NavLink>
                    </p>
                    </div>
                    </div>
                </div>
            </section>
   

    );
};

export default Register;