import { useNavigate, useLocation, useParams} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import {collection, deleteDoc, DocumentSnapshot, getFirestore, getDoc, setDoc, getDocs, doc} from 'firebase/firestore';
import {db, auth_} from './../../firebase';
import { Search } from 'lucide-react';


const BookClubMemberList = () =>{

    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");
    const [Members, showMembers] = useState([]);
    const [searchInput, setSearchInput] = useState("");

    const location = useLocation();
    const navigate = useNavigate();
    const {clubNameId} = useParams();
    

    const memberArray = [];


    const getMembers = async() =>{

        const bookClubMembers = await getDocs(collection(db, "BookClubs", clubNameId, "Members"));
         const members = bookClubMembers.docs;

         members.forEach( async(member) => {
            const userInfo =  await getDoc(doc(db, "Users", member.data().followerUID));

            memberArray.push(userInfo.data());
            showMembers(memberArray);
          });
      
    }


    const onViewReaderProfileButton  = (user) =>{
     
        navigate('/profile/'+user.displayName.toLowerCase().replace(/ /g,""),{
        // state: {
        //     id: user.uid,
        //     username: user.displayName
        // }
    });
    }

    const handleChange = (e) =>{
        e.preventDefault();
        setSearchInput(e.target.value);
        // console.log(e.target.value);
    }

    useEffect(()=>{
        getMembers();
        
    },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
            <div className="p-4">
            
                <h2 className="text-xl md:text-2xl font-bold mb-4">{clubNameId} Members</h2>

            <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4"/>
                <input
                className="w-full bg-gray-700 pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-base md:text-sm" 
                type="search"
                placeholder="Search readers..."
                onChange={handleChange}
                value={searchInput}/>
            </div>
        
                 {
        Members?.filter(followers => {
            if(!searchInput) return true // set false if you do not want any reader to show before seaching
                if(followers.displayName && followers.displayName!=="" && (followers.displayName.includes(searchInput)||followers.email.includes(searchInput))){
                    return true
                }
            
        }).map((followers,idx)=>( 
            <div onClick={() => onViewReaderProfileButton(followers)} className="flex items-center gap-3 p-4 hover:bg-gray-700 rounded-lg transition-colors"> 
                <div className="w-10 h-10 md:w-12 md:h-12 rounded-full bg-gray-200 overflown-hidden flex-shrink-0">
                    <img src={followers.photoURL? followers.photoURL : photoURL} alt="ProfilePic" className="w-full h-full object-cover"/>
                </div>
                <div className="flex-grow min-w-10">         
                    <h2 className="font-medium" key={idx}> {followers.displayName}</h2> 
                </div>
            </div> 

        ))    

     }
        
        </div>
    </section>
    );
}

export default BookClubMemberList;