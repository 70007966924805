import React, {useState} from "react";
import { useEffect } from "react";
import { useNavigate} from 'react-router-dom';

// import Papa from "papaparse";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import AdsComponent from './../../components/AdComponents';
import ListCard from "../../components/ListView/ListCard";
import {collection, setDoc, doc, onSnapshot, getCountFromServer, deleteDoc, getFirestore, getDoc, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';
import {db, auth_, getProfilePic} from './../../firebase';

const BusinessStrategyCategoryScreen = () =>{

    // const [csvData, parsedCsvData] = useState([]);
    const [books, showBooks] = useState([]);

    const navigate = useNavigate();


    const onBookDetailsButton  = (title, category, image, amazon_link, apple_link, bookshop_link) =>{

        navigate('/book_details/'+title,{
            state: {
                book_title: title,
                book_category: category,
                book_image: image,
                amazon: amazon_link,
                apple: apple_link,
                bookshop: bookshop_link,
            }
        });
    }    


    const getBooks = async() =>{
        await getDocs(collection(db, "Books")).then((querySnapshot)=>{
            const booksData = querySnapshot.docs.filter(doc => doc.data().category=="Business Strategy").map((doc)=>({
                ...doc.data()
            }));
             showBooks(booksData);
        });
    }

    useEffect(()=>{
        getBooks();
    },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
            <div>
            <AdsComponent dataAdSlot='8809066687'/>
            <div class=" flex flex-col  items-center px-6 py-8 mx-auto lg:py-0">
                <h1 class="mb-6 text-xl font-bold loading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">Books On Business Strategy</h1>
            </div>
                    <ul class=" overflow-auto-y w-full divide-y divide-white dark:divide-white">
                        {
                            books?.map((data,idx)=>(
                                // <img src={data[2]? data[2]:""} className="img-book" onClick ={()=>onBookDetailsButton(data[0],data[4],data[2], data[3], data[6], data[7])}/> 
                                // <div onClick ={()=>onBookDetailsButton(data[0],data[4],data[2], data[3], data[6], data[7])}>
                                <div class="hover:bg-gray-800" onClick ={()=>onBookDetailsButton(data.title,data.description,data.cover,data.amzn_link,data.ibook_link,data.bookshop_org_link)}>

                                {/* <ListCard  image={data.cover? data.cover:""} 
                                title= {data.title}/> */}

                                    <li class="pb-3 sm:pb-4">
                                        <div class="flex items-center space-x-4 p-4 rtl:space-x-reverse">
                                            <div class="flex-shrink-0">
                                                <img class="shadow shadow-white h-46 w-24" src={data.cover? data.cover:""}/>
                                            </div>
                                            <div class="flex-1 min-w-0">
                                                <p class="text-sm font-bold text-gray-900 dark:text-white">
                                                    {data.title} 
                                                </p>
                                                <p class="text-sm text-gray-500  dark:text-gray-400">
                                                    By {data.author} 
                                                </p>
                                            </div>
                                            <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                <FontAwesomeIcon icon={faArrowRight}/>
                                            </div>
                            
                                        </div>
                                    </li>


                                </div>
    
                            ))
                            
                        }
                        </ul>

            </div>
        </section>
    );
};

export default BusinessStrategyCategoryScreen;