import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate} from 'react-router-dom';

// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import Hero from './../components/Hero';
import HomeGeneral from './../components/Home';
import Features from './../components/Features';
import {feats} from './../components/Features/featureInfo';
import Footer from './../components/FooterBar';
import {HomeGeneralContainer,
    HomeGeneralP} from "./../components/Home/HomeElements";


import one from './../images/1-removebg.png';
import two from './../images/2-removebg.png';
import three from './../images/3-removebg.png';
import four from './../images/4-removebg.png';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faClock, faHouse, faBoltLightning, faInstagram, faChess, faArrowTrendUp, faDollarSign, faFire, faSchool, faChalkboardTeacher, faStore, faPenToSquare, faPeopleCarryBox, faSearch, faBook, faBookOpen, faBookOpenReader, faSwatchbook } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";
import { ArrowRight, BookMarked, BookOpen, Search, ThumbsUp, TrendingUp, Users } from "lucide-react";



import community_image from '../images/community.png';
import reading_challenge_image from '../images/reading_challenges.png';
import book_discovery_image from '../images/book_discovery.png';


const Home = () =>{
    const navigate = useNavigate();
    const features = [
        {
            title: "Smart Book Discovery",
            description: "Find your next read with our advanced recommendations engine that learns from your preferences.",
            image:book_discovery_image,
            details: [
                "Personalized Recommendations",
                "Business-Category Filtering"
            ]
        },
        {
            title: "Track Your Reading Journey",
            description: "Keep track of your books, set reading goals, and visualize your reading habits.",
            image: reading_challenge_image,
            details: [
                "Reading statistics",
                "Progress tracking",
                "Custom reading goals"
            ]
        },
        {
            title: "Join Communities",
            description: "Connect with fellow entrepreneurs, join book clubs, ask questions to help you on your business journey, and participate in reading challenges.",
            image: community_image,
            details: [
                "Virtual book clubs",
                "Discussion groups",
                "Reading challenges"
            ]
        }

    ];

    return (
        // <div className="min-h-screen bg-gray-900">

         <div className="min-h-screen bg-gradient-to-b from-gray-900 to-white"> 
             <Helmet>
                 <title>The Entrepreneurship Books App</title>
                 <meta name="description" content="Dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
                 <meta name="keywords" content="book lover, entreprenuership, mentorship, networking, personal growth, professional development, advice platform, business books, career guidance, community engagement, expert insights, learning resources, skill-building, networking platform, online mentorship"/>
                 <meta property="og:title" content="The Entrepreneurship Books App"/>
                 <meta property="og:description" content="Welcome to our dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
                 <meta property="og:url" content="https://www.entrepreneurshipbooksapp.com/"/>
             </Helmet>
            <main className="pt-16">
                <section className="px-4 sm:px-8 max-w-7xl mx-auto py-20">
                    <div className="text-center">
                        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-purple-600 to-blue-500 bg-clip-text text-transparent mb-6">
                            Discover Your Next Favorite Book For Your Business
                        </h1>
                        <p className="text-xl text-gray-500 mb-8 max-w-2xl mx-auto">
                            Join our growing community of entrepreneurs who use our platform to find their perfect books, track their reading journey, and connect with like-minded people to grow financially and professionally.
                        </p>
                        <button
                        onClick={()=>navigate("/login")} 
                        className="px-8 py-4 bg-purple-600 text-white rounded-lg text-lg font-medium hover:bg-purple-700 flex items-center mx-auto">
                            Get Started For Free!
                            <ArrowRight className="ml-2 h-5 w-5"/>
                        </button>

                    </div>
                </section>

                {/* <section className="py-20 bg-gray-300"> */}
                <section className="border-t border-gray-800 py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-bold text-center mb-16">Experience Entrepreneurship in a New Way</h2>
                        <div className="space-y-24">
                            {features.map((feature, idx)=>(
                                <div
                                key={feature.title} 
                                className={`flex flex-col ${
                                    idx%2===0 ? 'lg:flex-row':'lg:flex-row-reverse'
                                } gap-12 items-center`}>
                                    {/* Feature image */}
                                    <div className="lg:w-1/2">
                                        <div className="relative">
                                            <div className="absolute inset-0 bg-purple-200 rounded-3xl transform rotate-3"></div>
                                            <img
                                            alt={feature.title}
                                            src={feature.image}
                                            className="relative rounded-2xl shadow-lg w-full"
                                            />
                                        </div>
                                    </div>
                                    {/* Feature Description */}
                                    <div className="lg:w-1/2">
                                        <h3 className="text-gray-200 text-2xl font-bold mb-4">{feature.title}</h3>
                                        <p className="text-gray-900 mb-6 text-lg">{feature.description}</p>
                                        <div className="space-y-3">
                                            {feature.details.map((detail)=>(
                                                <div key={detail} className="flex items-center text-gray-700">
                                                <div className="w-2 h-2 bg-purple-500 rounded-full mr-3"></div>
                                                    <span className="text-gray-800">{detail}</span>
                                                </div>
                                            ))}

                                        </div>

                                    </div>

                                </div>


                            ))}

                        </div>

                    </div>

                </section>

                {/* <section className="bg-gray-200 py-20"> */}
                <section className="border-t border-gray-900 py-20">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="grid md:grid-cols-3 gap-8">
                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <BookOpen className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Personalized Recommendations</h3>
                                <p className="text-gray-600">Get book suggestions tailored to your reading preferences and history.</p>
                            </div>

                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <TrendingUp className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Track Your Progress</h3>
                                <p className="text-gray-600">Set reading goals and monitor your reading statistics over time.</p>
                            </div>


                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <Users className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Join Our Communities</h3>
                                <p className="text-gray-600">Connect with other entrepreneurs, share reviews, ask questions, and discover new perspectives.</p>
                            </div>

                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <Search className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Advanced Search</h3>
                                <p className="text-gray-600">Find and filter books by business categories, and more to find exactly what you are looking for.</p>
                            </div>


                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <BookMarked className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Reading List</h3>
                                <p className="text-gray-600">Create and share custom reading list with your community.</p>
                            </div>


                            <div className="text-center p-6">
                                <div className="bg-purple-100 rounded-full p-3 w-12 h-12 mx-auto mb-4">
                                    <ThumbsUp className="h-6 w-6 text-purple-600"/>
                                </div>
                                <h3 className="text-gray-900 text-xl font-semibold mb-2">Social Features</h3>
                                <p className="text-gray-600">Follow friends and share your reading experiencs.</p>
                            </div>
                        </div>
                    </div>

                </section>

                <section>
                    
                </section>
            </main>
        </div>
        // <main>
        //     <Helmet>
        //         <title>The Entrepreneurship Books App</title>
        //         <meta name="description" content="Dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
        //         <meta name="keywords" content="book lover, entreprenuership, mentorship, networking, personal growth, professional development, advice platform, business books, career guidance, community engagement, expert insights, learning resources, skill-building, networking platform, online mentorship"/>
        //         <meta property="og:title" content="The Entrepreneurship Books App"/>
        //         <meta property="og:description" content="Welcome to our dynamic platform where book enthusiasts and aspiring entrepreneurs converge to discover, connect, and grow. Dive into a vast library of books spanning various genres, while accessing valuable advice and insights from experienced professionals across entrepreneurship and beyond. Join our thriving community to explore new horizons, forge meaningful connections, and embark on a journey of personal and professional development unlike any other."/>
        //         <meta property="og:url" content="https://www.entrepreneurshipbooksapp.com/"/>
        //     </Helmet>
        //         <Hero/>
        //         <HomeGeneral/>
        //          <h2>Features</h2>
        //         <div className="category-div">
        //                 <FontAwesomeIcon icon={faClock} size ="2xl"/>
        //                 <br/>
        //                 <b>Reading Challenges</b>
        //                 <p>Motivate yourself to read more. Set goals to read more books to help you grow professionally. Be more knowledgable and successful</p>
                    
        //                 <FontAwesomeIcon icon={faPeopleCarryBox} size ="2xl"/>
        //                 <br/>
        //                 <b>Connect With Friends</b>
        //                 <p>Learn and grow with like-minded people all over the world.</p>

       
        //                 <FontAwesomeIcon icon={faSearch} size ="2xl"/>
        //                 <br/>
        //                 <b>Find Books and Get Recommendations</b>
        //                 <p>Search and get recommended books that help you grow personally and professionally.</p>

            
      

        //         </div>
        //        <div>
        //              <h2>Join Discussion Forums</h2>
        //             <div className="card-container">
        //                 <div className="card-hero">
        //                     <h3>Real Estate</h3>
        //                     <FontAwesomeIcon icon={faHouse} size ="2xl"/>
        //                     <p>Whether you're a seasoned investor, a first-time homebuyer, or a curious enthusiast, this platform is your hub for all things related to real estate. Engage in lively conversations, share insights, seek advice, and stay updated on the latest trends and market news. From property investment strategies to home buying tips, renovation ideas to landlord-tenant issues, our forum is a vibrant community.</p>

        //                 </div>
        //                 <div className="card-hero">
        //                     <h3>Personal Finance</h3>        
        //                     <FontAwesomeIcon icon={faArrowTrendUp} size ="2xl"/>
        //                     <p>Whether you're striving for financial independence, planning for retirement, or aiming to manage your money better, this platform is your go-to resource. Engage in insightful discussions, share strategies, seek advice, and stay informed on the latest trends and news in personal finance. From budgeting tips to investment strategies, debt management to tax planning, our forum is a supportive community.</p>
        //                 </div>
        //                 <div className="card-hero">
        //                     <h3>Create your own</h3>
        //                     <FontAwesomeIcon icon={faPenToSquare} size ="2xl"/>

        //                 </div>
        //             </div>
        //         </div>
        //          <div>
        //             <h2>Join Book Clubs</h2>
        //             <div className="card-container">
        //                 <div className="card-hero">
        //                     <h3>Awesome Club</h3>
        //                     <FontAwesomeIcon icon={faBookOpen} size ="2xl"/>
        //                     <p>Meet dynamic community of innovators, dreamers, and doers dedicated to mastering the art and science of entrepreneurship. This book club is not just about reading; it's about igniting inspiration, fostering creativity, and sparking actionable insights to drive your business forward. Dive into engaging discussions, exchange ideas with fellow entrepreneurs, and tap into a wealth of knowledge and experience to fuel your entrepreneurial journey.</p>
        //                 </div>
        //                 <div className="card-hero">
        //                     <h3>Entrepreneurship Club</h3>
        //                     <FontAwesomeIcon icon={faBookOpen} size ="2xl"/>
        //                     <p>Dive into the world of business innovation, leadership, and success stories with fellow aspiring entrepreneurs and seasoned business owners. Select thought-provoking books that explores various aspects of entrepreneurship, from startup strategies to growth hacking, from leadership to marketing tactics. Engage in lively discussions, share insights, and exchange ideas as we dissect each book's key concepts and practical applications. </p>

        //                 </div>
        //                 <div className="card-hero">
        //                     <h3>Create your own</h3>
        //                     <FontAwesomeIcon icon={faSwatchbook} size ="2xl"/>

        //                 </div>
        //             </div>
        //         </div>

        //         <h2>Motivate yourself to read more</h2>
        //         <p>Set goals to read more books to help you grow professionally. Be more knowledgable and successful. </p>
        //         <img className="img-hero" src={one}/>

        //        <h2>Find books to help you grow financially and professioally</h2>
        //         <p>Are you a novice entrepreneur? Are you a professional looking for books to help you get your next promotion?<br/>What about books to help you invest and get closer to financial freedom?<br/>Find what you are looking for here. </p>
        //         <img className="img-hero" src={four}/>

        //         <h2>Get personalized recommendations</h2>
        //         <p>Make our app your go to for business book recommendations</p>
        //         <img className="img-hero" src={two}/> 

        // </main>

    );
};

export default Home;