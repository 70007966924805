import React from 'react';
import apple from './Images/apple.png';
import android from './Images/google.png';

import logo from './Images/logo.svg';


const Footer = () =>{
    return(
        <footer class="bg-white dark:bg-gray-900">
            <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
                <div class="md:flex md:justify-between">
                    <div class="mb-6 md:mb-0">
                        <a href="/" class="flex items-center">
                            <img src={logo} class="h-8 me-3" alt="Entrepreneurship Books App Logo"/>
                            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Entreprenership Books App</span>
                        </a>
                    </div>
                    <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-4">
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-grey-900 uppercase dark:text-white">Follow Us</h2>
                            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                                <li class="mb-4">
                                    <a href="https://www.instagram.com/entrepreneurshipbooksapp/" class="hover:underline">Instagram</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-grey-900 uppercase dark:text-white">Download</h2>
                            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                                <li class="mb-4">
                                    <a href="https://apps.apple.com/us/app/entrepreneurship-books/id1631155648"  class="hover:underline">iOS</a>
                                </li>
                                <li class="mb-4">
                                    <a href="https://play.google.com/store/apps/details?id=com.entrep_bookclub_app.bookclub" class="hover:underline">Android</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-grey-900 uppercase dark:text-white">Legal</h2>
                            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                                <li class="mb-4">
                                    <a href="/privacypolicy" class="hover:underline">Privacy Policy</a>
                                </li>
                                <li class="mb-4">
                                    <a href="/termsofuse" class="hover:underline">Terms of Use</a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h2 class="mb-6 text-sm font-semibold text-grey-900 uppercase dark:text-white">Resources</h2>
                            <ul class="text-gray-500 dark:text-gray-400 font-medium">
                                {/* <li class="mb-4">
                                    <a href="/" class="hover:underline">About</a>
                                </li> */}
                                {/* <li class="mb-4">
                                    <a href="/getpremium" class="hover:underline">Get Premium</a>
                                </li> */}
                                <li class="mb-4">
                                    <a href="/contactus" class="hover:underline">Contact Us</a>
                                </li>
                                <li class="mb-4">
                                    <a href="/blogs" class="hover:underline">Blogs</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div class="sm:flex sm:items-center sm:justify-between">
                    <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="/" class="hover:underline">Entrepreneurship Books App</a>. All Rights Reserved.</span>
                    <div class="flex mt-4 sm:justify-center sm:mt-0">
                        <a href="https://www.instagram.com/entrepreneurshipbooksapp/" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                            <svg class="w-4 h-4" aria-hidden="true" xlmns="http://www.w3.org/2000/svg" fill="currentColor" viewBox='0 0 448 512'>
                                <path fill-rule="evenodd" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                            </svg>
                            <span class="sr-only">Instagram Page</span>
                        </a>
                    </div>
                </div>

            </div>
        </footer>

    );
};


export default Footer;