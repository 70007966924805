import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, getDoc, DocumentSnapshot, getDocs, query, getCountFromServer, getFirestore, updateDoc, where} from 'firebase/firestore';
import {auth_, uploadProfilePic, db} from './../firebase';
import {getAuth, onAuthStateChanged, updateProfile, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../components/Popup/Popup";
import {User, Bell, Lock, Book, Camera, LogOut, User2} from 'lucide-react';


const AccountSettings = () =>{

    const [aboutText, saveAboutText] = useState("");
    const [profBackgroundText, saveProfBackgroundText] = useState("");
    const [companyDescriptionText, saveCompanyDescriptionText] = useState("");


    const [isDuplicate, setDuplicateMessage] = useState(false);
    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isWhiteSpace, checkSpaces] = useState(false);
    const [isEmpty, checkEmpty] = useState(false);

    const [followingCount, countFollowing] = useState("");
    const [followersCount, countFollowers] = useState("");
    const [showPreview, setShowPreview] = useState(false);

    const [activeTab, setActiveTab] = useState("profile");
    const tabs =[
        {id: 'profile', label:"Profile", icon: User2},
        {id: 'notifications', label:"Notifications", icon: Bell},
        {id: 'privacy', label:"Privacy", icon: Lock},
        {id: 'account', label:"Account", icon: Book}
    ]
    const navigate = useNavigate();
    const firestore = getFirestore();
    const [user, setUser] = useState("");

    

    const userInfoForFollowsandFollowers = auth_.currentUser;

    const onFollowingButton  = () =>{
                navigate("/"+userInfoForFollowsandFollowers.displayName.toLowerCase().replace(/ /g,"")+'/following',{
                    state:{
                        username: userInfoForFollowsandFollowers.displayName,
                        id: userInfoForFollowsandFollowers.uid
                    }
                });
            }

    const onFollowersButton  = () =>{
                navigate("/"+userInfoForFollowsandFollowers.displayName.toLowerCase().replace(/ /g,"")+'/followers',{
                    state:{
                        username: userInfoForFollowsandFollowers.displayName,
                        id: userInfoForFollowsandFollowers.uid
                    }
                });
            }

            const getFollowersCount = async() =>{
            
                // const c = collection(firestore, "Users","xUBvQxl5H9Uh0mMnCigFnxbqDNo1", "Followers");
                const c = collection(firestore, "Users",userInfoForFollowsandFollowers.uid, "Followers");
                const count = await getCountFromServer(c);
                countFollowers(count.data().count);
                }
            
            const getFollowingCount = async() =>{
                    
                    const c = collection(firestore, "Users",userInfoForFollowsandFollowers.uid, "Following");
                    const count = await getCountFromServer(c);
                    countFollowing(count.data().count);
                    }

    

    const onFinishedBookButton  = () =>{
 
        navigate('/finishedreading');

    }


    const duplicateNotification = () =>{
        setDuplicateMessage(!isDuplicate);   
    }
    
    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    const whiteSpaceNotification = () =>{
        checkSpaces(!isWhiteSpace);   
    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const emptyNotification = () =>{
        checkEmpty(!isEmpty);   
    }

    
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const [userName, saveUsername] = useState("");

    const getUniqueName = async(uniqueName) =>{

        const usernames = await getDocs(query(collection(db, "Users"), where("displayName", "==", uniqueName)));
        // const usernames = findName.docs;
        // console.log("USERNAME");
        // console.log(usernames.docs.length>0);
        return usernames.docs.length>0
      
    }

    const updateUsername = async(e)=>{
        e.preventDefault();
        

        if(userName.trim()===""){
            emptyNotification();

        }else if(/\s/g.test(userName)){
            whiteSpaceNotification();

        }else if(await getUniqueName(userName.toLowerCase().replace(/ /g,""))){
            duplicateNotification();

        }else{
            try {

            
                updateDoc(doc(firestore,"Users",user.uid),{
                    displayName: userName.toLowerCase().replace(/ /g,"")
                });

                updateProfile(user, {displayName: userName.toLowerCase().replace(/ /g,"")});

                successNotification();
            
            }catch(e){
                errorNotification();

            }

        }

    }

    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
                if(userInfo?.photoURL){
                    setPhotoURL(userInfo.photoURL);
                }
                
                
    
            }
        });
    }

    const logout = async(user)=>{
        try{
            // await signOut(user);

            auth_.signOut();
            navigate('/');
            window.location.reload();
        }catch(err){
            console.error(err);
        }
    }

    const aboutResponse = async (e)=>{
        e.preventDefault();
        const date = new Date;
        console.log(aboutText);

        if(aboutText.trim()==""){
            emptyNotification();

        }else{

        try {

        
            updateDoc(doc(firestore,"Users",user.uid),{
                about: aboutText
            });

            successNotification();

            // setDoc(doc(firestore,"Users",user.uid),{
            //     accountCreated: date,
            //     displayName: e.target.username.value,
            //     email: email,
            //     firstName: e.target.firstName.value,
            //     lastName: e.target.lastName.value,
            //     website: "used_web_version_not_app",
            //     uid: user.uid,
                
            // });
            
        } catch (error) {
            console.log(error);
            errorNotification();
            
        }
    }

    }    

    const professionalBackgroundResponses =(e)=>{
        e.preventDefault();
        const date = new Date;

        if(profBackgroundText.trim()===""||
        companyDescriptionText.trim()===""||
        e.target.your_company_role.value.trim()===""||
        e.target.years_in_company.value.trim()===""||
        e.target.your_industry.value.trim()===""){
            emptyNotification();

        }else{

        try {

            updateDoc(doc(firestore,"Users",user.uid),{

                experience:{
                
                        professional_background: profBackgroundText,
                        company_desc: companyDescriptionText,
                        company_role: e.target.your_company_role.value,
                        company_years_in: e.target.years_in_company.value,
                        industry: e.target.your_industry.value
                }
                    
                }
            );

            successNotification();
            
        } catch (error) {
            console.log("prof_Experience");
            console.log(error);
            errorNotification();

            
        }
    }

    }



    const handleProfilePicChange = (e)=>{
        if(e.target.files[0]){
            // console.log("hey you"); 
            // console.log(e.target.files[0].name);
            setPhoto(e.target.files[0]);
           
            setPhotoURL(  URL.createObjectURL(e.target.files[0]));
        }

    }

    const handleUploadPicButton = async()=>{
        await uploadProfilePic(photo, user, setLoading);
        // navigate("/profile");


    }

    

    useEffect(()=>{
        getUsers();
        getFollowersCount();
        getFollowingCount();

        },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        
            {/* if popup is open dont open */}
            {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your information was added successfully!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    title = "Error!"
                    content = "There was an issue saving your information. Try again!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isDuplicate && 
                    <div>
                    <Popup
                    handleClose = {duplicateNotification}
                    title = "Username already exists!"
                    content = "The username you entered already exist. Enter a new one!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isWhiteSpace && 
                    <div>
                    <Popup
                    handleClose = {whiteSpaceNotification}
                    title = "Usernames can not have space!"
                    content = "Usernames can not have spaces. Enter a valid username."
                />
                </div>}


                 {/* if popup is open dont open */}
                 {isEmpty && 
                    <div>
                    <Popup
                    handleClose = {emptyNotification}
                    title = "Field can not be empty!"
                    content ="Field is empty."
                />
                </div>}


        <div class="min-h-screen p-4 md:p-8">
            <div class="max-w-6xl mx-auto">
            <div class="flex items-center justify-between mb-8">
                <div>
                    <h1 class="text-3xl font-bold b-8">Account Settings</h1>
                    <p>Manage Account</p>
                </div>
                <div class="flex items-center gap-4">
                    <a
                    onClick={()=>onFollowersButton()} 
                    class="flex items-center gap-2 text-gray-200 hover:text-blue-600">
                        <User size={20}/>
                        <span class="text-sm">
                            {followersCount} Followers
                        </span>

                    </a>
                    <a
                    onClick={()=>onFollowingButton()}
                    class="flex items-center gap-2 text-gray-200 hover:text-blue-600">
                        <User size={20}/>
                        <span class="text-sm">
                            {followingCount} Following
                        </span>
                    </a>
                </div>
            </div>
            <div class="flex flex-col md:flex-row gap-6">
                    <div class="w-full md:w-64">
                        <div class="bg-gray-700 rounded-lg shadow-sm">
                            <nav class="flex flex-row md:flex-col overflow-x-auto md:overflow-x-visible">
                                {tabs.map((tab)=>{
                                    const Icon = tab.icon;
                                    return(
                                        <button
                                        class={`flex items-center gap-3 p-4 w-full hover:bg-gray-50 hover:text-gray-900 transition-colors ${setActiveTab===tab.id?
                                            'bg-gray-100 text-blue-600 border-b-2 md:border-b-0 md:border-blue-600':
                                            'text-white'
                                        }`}
                                        onClick={()=>setActiveTab(tab.id)}
                                        key={tab.id}>
                                            <Icon size={20} />
                                            <span class="whitespace-nowrap">{tab.label}</span>
                                        </button>

                                    );

                                })}

                            </nav>
                        </div>
                    </div>

            <div class="flex-1">
                <div class="bg-gray-700 rounded-lg shadow-sm p-6">
            
            {activeTab==="profile" && (
                <div class="space-y-6">
                    <div class="flex justify-between items-center">
                       
                            <h2 class="text-xl semi-bold">Profile Picture</h2>
                            <button
                            onClick={()=>setShowPreview(!showPreview)}
                            class="text-blue-600 hover:text-blue-700 text-sm">
                                {showPreview? 'Hide Preview':'Show Preview'}

                            </button>


                    </div>

                    <div class="flex flex-col items-center md:items-start gap-4">
                        <div class="relative">
                            <div class="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
                                <img src={photoURL} alt="ProfilePic" className="w-full h-full object-cover"/>
                            </div>
                            <input
                                type="file" 
                                onChange={handleProfilePicChange} 
                                />
                                <button 
                                class="absolute bottom-0 right-0 p-2 bg-blue-600 rounded-full text-white hover:bg-blue-700 transition-colors">
                                    <Camera size={20} />
                                </button>
                                <button disabled ={loading || !photo} onClick={handleUploadPicButton}>Upload</button>
                        </div>
                    </div>
                            
                    <div class="w-full space-y-4">
                        <div>
                            <label
                            for="displayName" 
                            class="block text-sm font-medium text-gray-300 mb-1">
                            Username
                            </label>
                            <input
                            id="displayName"
                            type="text"
                            class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                            defaultValue={user?.displayName}/>
                        </div>
                        <div>
                            <label
                            for="name" 
                            class="block text-sm font-medium text-gray-300 mb-1">
                            Name
                            </label>
                            <input
                            id="name"
                            type="text"
                            class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                            defaultValue={user?.name}/>
                        </div>

                            <form onSubmit={updateUsername}>
                      
                                <div>
                                    <label  
                                        class="block text-sm font-medium text-gray-300 mb-1">
                                        Update Username?
                                    </label>
                                    <input
                                    class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                    type="text"
                                    value={userName}
                                    onChange = {e => saveUsername(e.target.value)}/>
                                </div>
                            
                            <button type="submit">Save</button>
                            </form>
                            <div>
                            <label
                            class="block text-sm font-medium text-gray-300 mb-1">EMAIL</label>
                            <input
                                class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                type="text" 
                                defaultValue={user?.email}/>
                            </div>
                           

                            <h2>Bio</h2>
                            <form onSubmit={aboutResponse}>
    
                                <div>
                                    <label  
                                    className="block text-sm font-medium text-gray-300 mb-1">About</label>
                                    <textarea
                                    rows={5} 
                                    cols={35}
                                    type="text"
                                    value={aboutText}
                                    onChange = {e => saveAboutText(e.target.value)}
                                    class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                    defaultValue={user?.about}></textarea>
                                </div>
                    
                            <button type="submit">Save</button>
                            </form>

                            <h2>Experience</h2>
                            <form onSubmit={professionalBackgroundResponses}>
                            <div className="prof_bg">
                            <div>
                                <label 
                                className="block text-sm font-medium text-gray-300 mb-1">What is your professional background</label>
                                <textarea
                                    rows={5} 
                                    cols={35}
                                    type="text"
                                    value={profBackgroundText}
                                    onChange = {e => saveProfBackgroundText(e.target.value)}
                                    defaultValue={user?.experience?.professional_background}
                                    class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"></textarea>
                            </div>
                            <div>
                            <label
                            className="block text-sm font-medium text-gray-300 mb-1">
                                Your company
                            </label>
                            <textarea
                                    rows={5} 
                                    cols={35}
                                    type="text"
                                    value={companyDescriptionText}
                                    onChange = {e => saveCompanyDescriptionText(e.target.value)}
                                    class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                    defaultValue={user?.experience?.company_desc }></textarea>                
                            </div>
                            <div>
                                <label 
                                className="block text-sm font-medium text-gray-300 mb-1">Your role in that company</label>
                                <input 
                                name="your_company_role"
                                type="text"           
                                class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                defaultValue={user?.experience?.company_role }></input>
                            </div>
                            <div>
                                <label 
                                className="block text-sm font-medium text-gray-300 mb-1">How many years of ownership experience do you have</label>
                                <input
                                name="years_in_company"
                                type="number" 
                                class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                defaultValue={user?.experience?.company_years_in }></input>
                            </div>
                            <div>
                                <label 
                                className="block text-sm font-medium text-gray-300 mb-1">What industry are you in</label>
                                <input 
                                name="your_industry"
                                type="text"
                                class="w-full p-2 border border-gray-300 bg-gray-700 text-white rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 outline-none transition-all"
                                defaultValue={user?.experience?.industry }></input>
                            </div>
                            </div>
                            <div class="flex gap-2 mt-2">
                                <button
                                class="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 transition-colors"
                                type="submit">Save</button>
                            </div>
                            </form>

                        
                 </div>
                </div>
            )}
            {activeTab==="account" && (
                <div class="space-y-6">
                        <div>
                            <h3 class="text-lg font-medium mb-4">Account Settings</h3>
                        <div>

                        </div>
                        <hr class="my-6"/>
                        <button 
                        class="flex items-center  gap-2 text-red-200 hover:text-red-700" 
                        onClick={()=>logout(user)}>
                            <LogOut size={20}/>
                            <span>Sign Out</span>
                        </button>

                    </div>
                </div>
            )}

            </div>
            </div>
            </div>
            </div>
        </div>
        </section>

    );
};
export default AccountSettings;