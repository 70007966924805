import React, {useState} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { useNavigate} from 'react-router-dom';
// import {collection, DocumentSnapshot, getDocs, QuerySnapshot} from 'firebase/firestore';

import {collection, DocumentSnapshot, getDoc, getDocs, setDocs,QuerySnapshot, addDoc, doc, setDoc, getFirestore} from 'firebase/firestore';
import {db, auth_} from './../../firebase';

// import {db} from './../../firebase';
import { useEffect } from "react";
import {PlusCircle} from 'lucide-react';

import Popup from "../../components/Popup/Popup";



const DiscussionBoardsHomeScreen = () =>{

    const user = auth_.currentUser;
    const date = new Date;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenErrorMessage, setIsOpenErrorMessage] = useState(false);
    const [isOpenDuplicateMessage, setIsOpenDuplicateMessage] = useState(false);
    const [isOpenEmptyMessage, setIsOpenEmptyMessage] = useState(false);
    const navigate = useNavigate();
    const documentSnapshot = new DocumentSnapshot();

    const [DiscussionBoards, showDiscussionBoards] = useState([]);
    const [showDiscussionBoard, setShowDiscussionBoard] = useState(false);
    const [db_Description, saveDBDescription] = useState("");


    const onCreateDashboardButton  = () =>{
        navigate('/creatediscussionboard');
    }


    const successNotification = () =>{
        // console.log("success message");
        setIsOpen(!isOpen);   
    }

    const warningNotification = () =>{
        // console.log("warning message");
        setIsOpenErrorMessage(!isOpenErrorMessage);   
    }

    const duplicateNotification = () =>{
        // console.log("duplicate message");
        setIsOpenDuplicateMessage(!isOpenDuplicateMessage);   
    }

    const emptyNotification = () =>{
        // console.log("empty message");
        setIsOpenEmptyMessage(!isOpenEmptyMessage);   
    }

    const onSubmitNewDiscussionBoardName = async (event) =>{
        event.preventDefault();
        try{
            // console.log("yo");
            const firestore = getFirestore();
            const name = await getDoc(doc(firestore,"DiscussionBoards",event?.target?.db_name.value));

            if(event?.target?.db_name?.value===null|| event?.target?.db_name?.value.trim()==="" || db_Description.trim()===""){
                emptyNotification();
            }else if(name.exists()){
                // console.log(name);
                duplicateNotification();
            }else{
            setDoc(doc(firestore,"DiscussionBoards",event?.target?.db_name.value),{
                creator: user?.displayName,
                creator_uid: user?.uid,
                creator_email: user?.email,
                dateCreated: date,
                discussionBoardName: event?.target?.db_name.value,
                discussionBoardNameID: event?.target?.db_name.value.replace(/ /g,"").toLowerCase(),
                description: db_Description
            });

            successNotification();
        }

        }catch(event){
            
            // console.log("Error Adding Document: ",event );
            warningNotification();


        }


    }

    
    
    const onViewButton  = (discussion_board_name) =>{
        navigate('/discussionboard/'+discussion_board_name.toLowerCase().replace(/ /g,""),{
            state: {
                id: discussion_board_name
            }
        });
    }

    const getDiscussionBoards = async() =>{
        await getDocs(collection(db, "DiscussionBoards")).then((querySnapshot)=>{
            const discussionboards = querySnapshot.docs.map((doc)=>({
                ...doc.data(), id:doc.id, name: doc.displayName
            }));

            if(discussionboards.name!==""){
                showDiscussionBoards(discussionboards);
            }
            
            

        });   


    }

    useEffect(()=>{
        getDiscussionBoards();
    },[])


    return (
        <section class="bg-gray-900">

            {/* if popup is open dont open */}
            {isOpen && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your discussion board has been created!"
                />
                </div>}

                {isOpenErrorMessage&&<Popup
                  handleClose = {warningNotification}
                  title = "Error"
                content = "There was an issue creating your dashboard!"
            />}

            {isOpenEmptyMessage&&<Popup
                  handleClose = {emptyNotification}
                  title  = "Error"
                content = "Fields can not be empty!"
            />} 
            
            {isOpenDuplicateMessage&&<Popup
                  handleClose = {duplicateNotification}
                  title = "Error"
                content = "Dashboard already exists!"
               
                
            />}
        <div class="max-w-4xl mx-auto ">
            <div class="flex flex-col md:flex-row justify-between items-center">
                <div>
                <h1 class="text-3xl font-bold">Discussion Boards</h1>
                <p class="text-gray-600 mt-2">Join the conversation in our discussion forums!</p>
                </div>
            
            {/* <button 
            class="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            onClick={onCreateDashboardButton}> */}
            <button 
            class="mb-4 flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
            onClick={()=>setShowDiscussionBoard(!showDiscussionBoard)}>
                <PlusCircle class="w-5 h-5"/>
                Create A Discussion Board
            </button>
            </div>

            {showDiscussionBoard&&(
                <div className="bg-gray-700 rounded-lg shadow-lg p-6 mb-6">
                    <h2 className="text-xl font-bold mb-4">Create A Community</h2>
                    <form onSubmit={onSubmitNewDiscussionBoardName}>

                        
                        <input 
                        className="w-full border rounded px-4 py-2 mb-4" 
                        placeholder="Enter Discussion Board Name" 
                        type="text" 
                        name="db_name" ></input>

                        <textarea
                            rows={5} 
                            cols={35}
                            type="text"
                            placeholder="Enter Discussion Board Description"
                            value={db_Description}
                            onChange = {e => saveDBDescription(e.target.value)}
                            className="w-full border rounded px-4 py-2 mb-4 h-32"></textarea>
                        <div className="flex justify-end gap-2">
                            <button
                            onClick={()=>setShowDiscussionBoard(false)} 
                            
                            className="px-4 py-2 border rounded hover:bg-gray-50 hover:text-gray-900">Cancel</button>
                            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" type="submit">Submit</button>
                        </div>
                    
                    </form>
                </div>
            )}

            <div class="gap-6 grid grid-cols-1 ">
                    {
                        DiscussionBoards?.map((boards,idx)=>(
                            <div class="bg-white border border-white rounded-lg hover:bg-gray-900 hover:text-white" onClick={()=>onViewButton(boards?.discussionBoardName)}>
                            <div class="p-6">
                            <h3 class="text-xl font-semibold mb-2 text-bg-gray-900" 
                            key={idx}>{boards?.discussionBoardName}</h3>
                            <div class="flex items-center gap-4 text-sm text-gray-500 hover:">
                            <button class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700" onClick={()=>onViewButton(boards?.discussionBoardName)}>
                                View
                            </button>
                            </div>
                            </div>
                            </div>
                        ))
                    }
                    
            </div>
        </div>
        </section>

    );
};
export default DiscussionBoardsHomeScreen;