import React, {useEffect, useState} from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import {
    query,
    collection, 
    orderBy,
    onSnapshot,
    limit,
    QuerySnapshot,
    Timestamp,
    runTransaction,
    serverTimestamp,
    doc,
    getFirestore,
    getDoc,
    deleteDoc,
    getDocs,
    setDoc,
    updateDoc,
    sen,
    getCountFromServer

} from 'firebase/firestore';

import {db, auth_, ROLES} from '../../firebase';
import { BookOpen, Calendar, Github, Menu, Slack, User, User2, Users } from 'lucide-react';

const AdminDashboard = ()=>{
    const {clubNameId} = useParams();
    const [club, setClub] = useState([]);
    const [members, setMembers] = useState([]);
    const [slackWebHook, setSlackWebHook] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);
    const [currentUserRole, setCurrentUserRole] = useState(null);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [activeTab, setActiveTab] = useState('overview');
    const firestore = getFirestore();

    useEffect(()=>{        
        const fetchGroupData = async()=>{
            const clubDoc = await getDoc(doc(firestore, "BookClubs", clubNameId));
            if(clubDoc.exists){
                const clubData = clubDoc.data();
                setClub(clubData);
            }

            // const memberList = await getDocs(collection(firestore, "BookClubs", clubNameId, "Members"));
            // console.log(memberList.docs);
            // if(memberList.exists){
            //     // const memberData = memberList.data();
            //     setMembers(memberList.docs);
            //     // console.log(memberList);
            // }

            await getDocs(collection(firestore, "BookClubs", clubNameId, "Members")).then((querySnapshot)=>{
                const memberList = querySnapshot.docs.map((doc)=>({
                    ...doc.data()
                }));
                setMembers(memberList);
            });   






        };

        fetchGroupData();

    },[clubNameId]);

    const canManageRoles = currentUserRole === ROLES.OWNER || currentUserRole === ROLES.ADMIN;
    const canDeleteRoles = currentUserRole === ROLES.OWNER;

    // function to format dates
    const formatTimestamp =(timestamp)=>{
        if(!timestamp) return '';

        // if its a firestore timestamp
        if(timestamp.seconds){
            return new Date(timestamp.seconds * 1000).toLocaleString();
        }

        // if its already a Date object
        if(timestamp instanceof Date){
            return timestamp.toLocaleString();
        }

        // if its a string
        return timestamp;

    }

    const [booksRead, countFinishedBook] = useState(0);


    // function to format dates
    const getBookRead = async(user_id)=>{
        const firestore = getFirestore();
        const c = collection(firestore, "Users",user_id, "Following");
        const count = await getCountFromServer(c);
        return count.data().count;
        } 
    

    

    const deleteGroup = async()=>{
        if(window.confirm("Are you sure want to delete this group? This action can not be undone.")){
            deleteDoc(doc(firestore, "BookClubs", clubNameId));
        }
    }

    const toggleModeratorStatus = async(userID)=>{

        const clubDoc = await getDoc(doc(firestore, "BookClubs", clubNameId));
        const moderators = clubDoc.data().moderatorIDs || [];
        if(moderators.includes(userID)){
            updateDoc(doc(firestore, "BookClubs", clubNameId),{
                moderatorIDs: moderators.filter(id=>id!==userID)
            });

        }else{
            updateDoc(doc(firestore, "BookClubs", clubNameId),{
                moderatorIDs: [...moderators, userID]
            });
        }

        const updateClubDoc = await getDoc(doc(firestore, "BookClubs", clubNameId));
        setClub(updateClubDoc.data());

    }

    if(!club) return <div>Loading...</div>;

    return(
        <section className='bg-gray-900'>
    <div className='bg-gray-900 rounded-lg shadow-lg p-4'>
    <Link to={`/bookclub/${clubNameId}`}>Back to Group</Link>
    {/* Mobile Admin header */}
        <div className='lg:hidden border-b border-gray-200 mt-8'>
            <div className='p-4'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-2xl font-bold mb-2'>{club.bookClubName} Admin Dashoboard</h2>
                    <p className='text-gray-600'>Manage your book club settings and members</p>
                    <button
                    className='p-2 hover:bg-gray-100 rounded-lg'
                    onClick={()=>setMobileMenu(!mobileMenu)}>
                        <Menu size={24}/>
                    </button>
                </div>
                {mobileMenu&&(
                    <div className='mt-4 space-y-2'>
                        {['Overview', 'Members', 'Settings'].map((tab)=>(
                            <button
                            key={tab}
                            onClick={()=>{
                                setActiveTab(tab.toLowerCase());
                                setMobileMenu(false)
                            }}
                            className={`w-full text-left px-4 py-2 rounded-lg ${
                                activeTab === tab.toLowerCase()?
                                'bg-purple-100 text-purple-600':
                                'hover:bg0gray-50'
                            }`}>
                                {tab}
                            </button>

                        ))}

                    </div>
                )}

                
                
            </div>
        </div>
        
    {/* Desktop Admin header */}
    <div className='hidden lg:block border-b border-gray-200 mt-8'>
            
                <div className='p-6'>
                    <h2 className='text-2xl font-bold mb-2'>{club.bookClubName} Admin Dashoboard</h2>
                    <p className='text-gray-600'>Manage your book club settings and members</p>
                    
                </div>
                
                    <div className='flex gap-6 px-6'>
                        {['Overview', 'Members', 'Settings'].map((tab)=>(
                            <button
                            key={tab}
                            onClick={()=>{
                                setActiveTab(tab.toLowerCase());
                                setMobileMenu(false)
                            }}
                            className={`px-4 py-3 border-b-2 ${
                                activeTab === tab.toLowerCase()?
                                'border-purple-600 text-purple-600':
                                'border:transparent text-gray-500 hover:text-gray-700'
                            }`}>
                                {tab}
                            </button>

                        ))}

                    </div>
              
        </div>
        <div className='p-4 lg:p-6'>
            {activeTab === 'overview'&&(
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                    <div className='bg-purple-50 rounded-lg p-4'>
                        <div className='flex items-center justify-between'>
                            <div>
                                <p className='text-purple-600 font-semibold text-sm lg:text-base'>Total Members</p>
                                <h3 className='text-xl lg:text-2xl font-bold'>{members?.length}</h3>

                            </div>
                            <Users className='text-purple-600' size={24}/>
                        </div>
                    </div>
                    {/* books read in club */}
                    <div className='bg-blue-50 rounded-lg p-4'>
                        <div className='flex items-center justify-between'>
                            <div>
                                <p></p>
                                <h3></h3>

                            </div>
                            <BookOpen className='text-blue-600' size={24}/>

                        </div>

                    </div>
                    <div className='bg-green-50 rounded-lg p-4'>

                    </div>

                        {/* books read in club */}
                        <div className='bg-gray-50 rounded-lg p-4'>
                        <div className='flex items-center justify-between'>
                            <div>
                                <p></p>
                                <h3></h3>

                            </div>
                            <BookOpen className='text-blue-600' size={24}/>

                        </div>

                    </div>
                    <div className='bg-green-50 rounded-lg p-4'>

                    </div>
                     
                </div>
            )}

            {activeTab==="members"&&(
                <div className='space-y-6'>
                    <div className='flex justify-between items-center'>
                        <h3 className='font-semibold'>Members ({members?.length})</h3>
                    </div>
                    <div className='overflow-x-auto'>
                        <table className='w-full'>
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Member</th>
                                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Role</th>
                                    {/* <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Status</th> */}
                                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Join Date</th>
                                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Books Read</th>
                                    <th className='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {members.map(member=>(
                                <tr key={member.followerUID }>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                        <div className='flex items-center'>
                                            <div className='h-10 w-10 bg-purple-100 rounded-full flex items-center justify-center'>
                                                <User2 className='text-purple-600' size={24}/>
                                            </div>
                                            <div className='ml-4'>
                                                <div className='text-sm font-medium text-gray-200'>{member.followerDisplayName}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td className='px-6 py-4 whitespace-nowrap'>
                                        <span></span>
                                    </td>
                                    {/* <td></td> */}
                                    <td className='px-6 py-4 whitespace-nowrap text-sm text-gray-500'>{formatTimestamp(member?.DateandTime)}</td>
                                    <td></td>
                                    <td className='px-6 py-4 whitespace-nowrap text-left text-sm font-medium'>
                                    <button 
                                    className='text-purple-600 hover:text-purple-900'
                                    onClick={()=>toggleModeratorStatus(member.followerUID)}>
                                        {club.moderatorIDs?.includes(member.followerUID)?"Remove Moderator":"Make Moderator"}
                                    </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            )}
            {activeTab === 'settings' &&(
                <div className='space-y-6'>
                    <div>
                        <h3 className='font-semibold mb-4'>External Integrations</h3>
                        <div className='space-y-4'>
                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div className='flex items-center gap-3'>
                                    <Slack className='text-gray-300' size={24}/>
                                    <div>
                                        <p className='font-medium'>Slack Integration</p>
                                        <p className='text-sm text-gray-300'>Connect your Slack workspace</p>
                                    </div>
                                </div>
                                <button className='border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50'>
                                    Configure
                                </button>
                            </div>

                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div className='flex items-center gap-3'>
                                    {/* <Discord className='text-gray-600' size={24}/> */}
                                    <div>
                                        <p className='font-medium'>Discord Integration</p>
                                        <p className='text-sm text-gray-300'>Connect your Discord workspace</p>
                                    </div>
                                </div>
                                <button className='border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50'>
                                    Configure
                                </button>
                            </div>

                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div className='flex items-center gap-3'>
                                    <Github className='text-gray-300' size={24}/>
                                    <div>
                                        <p className='font-medium'>Github Integration</p>
                                        <p className='text-sm text-gray-300'>Connect your Github workspace</p>
                                    </div>
                                </div>
                                <button className='border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50'>
                                    Configure
                                </button>
                            </div>

                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div className='flex items-center gap-3'>
                                    <Calendar className='text-gray-300' size={24}/>
                                    <div>
                                        <p className='font-medium'>Eventbrite Integration</p>
                                        <p className='text-sm text-gray-300'>Manage your event scheduling</p>
                                    </div>
                                </div>
                                <button className='border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50'>
                                    Configure
                                </button>
                            </div>

                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div className='flex items-center gap-3'>
                                    <Calendar className='text-gray-300' size={24}/>
                                    <div>
                                        <p className='font-medium'>Meetup Integration</p>
                                        <p className='text-sm text-gray-300'>Manage your event scheduling</p>
                                    </div>
                                </div>
                                <button className='border border-gray-300 rounded-lg px-4 py-2 hover:bg-gray-50'>
                                    Configure
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <h3 className='font-semibold mb-4'>Group Settings</h3>
                        <div className='space-y-4'>
                            <div className='flex items-center justify-between p-4 bg-gray-700 rounded-lg'>
                                <div>
                                    <p className='font-medium'>Status</p>
                                </div>
                                <label className='relative inline-flex items-center cursor-pointer'>
                                    {/* <input type="checkbox" className='sr-only-peer' defaultChecked/> */}
                                    {/* <div className="w-ll h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-purple-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-purple-600"></div> */}
                                <p className='font-semibold'>{club.isPublic?'Public':'Private'}</p>

                                </label>
                            </div>
                        </div>
                        
                        

                    </div>
                </div>
            )}

        </div>

        
    </div>
    </section>
    );

}


export default AdminDashboard;