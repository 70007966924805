import React, {useState,useEffect} from "react";
import {doc, setDoc, collection, DocumentSnapshot, getDocs, getCountFromServer, getFirestore, updateDoc} from 'firebase/firestore';
import {auth_, uploadProfilePic} from '../../firebase';
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useNavigate} from 'react-router-dom';
import Popup from "../../components/Popup/Popup";
import { Camera } from "lucide-react";

const GettingStartedUploadPhotoScreen = () =>{

    const [isErrorMessage, setErrorMessage] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const navigate = useNavigate();

    const onPrevButton = ()=>{
        navigate("/getstarted_experience");

    }

    const onFinishButton = ()=>{
        navigate("/discover");
        
    }

 

    const [user, setUser] = useState("");
    const [photo, setPhoto] = useState(null);
    const [loading, setLoading] = useState(false);
    const [photoURL, setPhotoURL] = useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png");

    const getUsers = async() =>{
        const auth = getAuth();
        onAuthStateChanged(auth, (user)=>{
            if(user){
                const userInfo = auth_.currentUser;
                // console.log("user:")
                // console.log(user.displayName);
                // const email =  user.email;
                // const displayName =  user.displayName;
                // const uid = user.uid;
                setUser(userInfo)
                // if(userInfo?.photoURL){
                //     setPhotoURL(userInfo.photoURL);
                // }
                
    
            }
        });
    }

    const handleProfilePicChange = (e)=>{
        if(e.target.files[0]){
            // setPhoto(e.target.files[0])
            setPhoto(e.target.files[0]);
           
            setPhotoURL(  URL.createObjectURL(e.target.files[0]));
        }

    }

    const handleUploadPicButton = ()=>{
        uploadProfilePic(photo, user, setLoading);
        successNotification();
        navigate("/discover");


    }

    const successNotification = () =>{
        setSuccess(!isSuccess);   
    }

    const errorNotification = () =>{
        setErrorMessage(!isErrorMessage);   
    }

    useEffect(()=>{
        getUsers();

        },[])

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div className="max-w-4xl mx-auto p-4 sm:p-6 lg:p-8 random-lg shadow-sm">

                 {/* if popup is open dont open */}
                 {isSuccess && 
                    <div>
                    <Popup
                    handleClose = {successNotification}
                    title = "Congratulations!"
                    content = "Your image was uploaded!"
                />
                </div>}


                {/* if popup is open dont open */}
                {isErrorMessage && 
                    <div>
                    <Popup
                    handleClose = {errorNotification}
                    title = "Error!"
                    content = "There was an issue uploading your image. Try again!"
                />
                </div>}

       
            <div className="flex gap-2 mb-2">
                <Camera className="w-24 h-24 text-blue-600"/>
                <div>
                    <h1 className="text-3xl font-bold text-gray-300">Getting Started!</h1>
                    <p>Upload A Profile Picture</p>
                </div>
            </div>

            <div className="bg-gray-700 rounded-lg p-4">

                <div class="w-32 h-32 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden mb-2">
                    <img src={photoURL} alt="ProfilePic" className="w-full h-full object-cover"/>
                </div>

                <input 
                type="file" 
                onChange={handleProfilePicChange} />

                
            <div className="flex justify-end gap-2">
                
                <button className="px-4 py-2 bg-white text-gray-900 rounded hover:bg-gray-600" onClick={onPrevButton}>Prev</button>
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" disabled ={loading || !photo} onClick={handleUploadPicButton}>Upload & Finish</button>
            {/* <button onClick={onFinishButton}>Finish</button> */}
            </div>
            </div>
        
        </div>
        </section>

    );
};
export default GettingStartedUploadPhotoScreen;