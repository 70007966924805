// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, OAuthProvider, updateProfile} from "firebase/auth";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider, ReCaptchaEnterpriseProvider } from "firebase/app-check";
import {getStorage, ref, uploadBytes, getDownloadURL, connectStorageEmulator} from "firebase/storage";
import { useNavigate} from 'react-router-dom';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRBD3CnTCZUrc6Qc4WX4PfBKk_9KVHdrw",
  authDomain: "entrepreneurshipbooksapp.com",
  projectId: "entrepreneurship-book-club-app",
  storageBucket: "entrepreneurship-book-club-app.appspot.com",
  messagingSenderId: "235508464978",
  appId: "1:235508464978:web:b0d6f058d402ae841d8e8b",
  measurementId: "G-EZC9MQHSJ9"
};

// Initialize Firebase
// window.FIREBASE_APPCHECK_DEBUG_TOKEN = false;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = '3eb1dfa4-f6e2-4afb-a34f-02cb61e18df2';
}



const app = initializeApp(firebaseConfig);

initializeAppCheck(app,{
  provider: new ReCaptchaV3Provider('6LcNp4EpAAAAAHfyF3rLUH5Uzvmc0X9QYk2LPNnb'),
  // isTokenAutoRefreshEnabled: true,
  });

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaEnterpriseProvider('6LdKyrAlAAAAAJfKodCr7UCNvUtBpHSvTNWyUylu'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: false
// });

const analytics = getAnalytics(app);
const storage = getStorage();


// Initialize Firebase Authentication and get a reference to the service
export const auth_ = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const oAuthProvider = new OAuthProvider("apple.com");
export const uploadProfilePic = async (file, currentUser,setLoading)=>{
  // files are stored in location named after user uid

  const fileRef = ref(storage, "ProfilePictures/"+currentUser.uid);
  setLoading(true);
  const snapshot  = await uploadBytes(fileRef, file);
  const photoURL_fromStorage = await getDownloadURL(fileRef);
  updateProfile(currentUser, {photoURL: photoURL_fromStorage});
  updateDoc(doc(db,"Users",currentUser.uid),{
    photoURL: photoURL_fromStorage
  });
  setLoading(false);
  alert("Uploaded File! Refresh Page.");



}



export const getProfilePic = async (reader_uid)=>{
  // files are stored in location named after user uid
try{
  const fileRef = ref(storage, "ProfilePictures/"+reader_uid);
  const photoURL_fromStorage = await getDownloadURL(fileRef);
  return photoURL_fromStorage;
}catch(error){
  return null;
}

}

const ROLES = {
  OWNER: 'owner',
  ADMIN: 'admin',
  EVENT_MANAGER: 'event_manager',
  CONTENT_MANAGER: 'content_moderator',
  MEMBER: 'member'

}
export {ROLES};
export const db = getFirestore(app);
export default app;